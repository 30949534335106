import ApiModel from "./ApiModel"

class Order extends ApiModel {
  folder = "/order"

  constructor() {
    super()

    const dadosUsuario = JSON.parse(localStorage.getItem("token"))

    if (dadosUsuario) {
      try {
        // User token
        const { token } = dadosUsuario;

        if (token) {
          // Set API user token
          this.api.defaults.headers.common["x-access-token"] = token;
        }
      } catch (err) {
        // Without token. Not authenticated
        this.api.defaults.headers.common["x-access-token"] = null;
      }
    } else {
      // Without token. Not authenticated
      this.api.defaults.headers.common["x-access-token"] = null;
    }


  }

  // Generic request 
  exec(method, endpoint, params) {
    // Add options
    const options = {
      url: endpoint,
      headers: {
        'Content-Type': 'application/json'
      },
      method: method.toString().toUpperCase(),
      data: params,
      params
    };
    // Execute request by method
    return this.api.request(options)
    //return this.api.get(this.apiURL + "/" + endpoint, params)
      .then(this.getData)
      .catch(this.onError);
  }

  orderPost(method, endpoint, params) {
    // Add options
    const options = {
      headers: {
        'Content-Type': 'application/json'
      },
      method: method.toString().toUpperCase(),
      data: params,
      params
    };
    // Execute request by method
    // this.api.request(options).then(this.getData);
    // Return
    return this.api.post(this.apiURL + "/" + endpoint, params)
      .then(this.getData)
      .catch(this.onError);
  }

  orderGet(method, endpoint, params) {

    // Add options
    const options = {
      headers: {
        'Content-Type': 'application/json'
      },
      method: method.toString().toUpperCase(),
      data: params,
      params
    };
    // Execute request by method
    // this.api.request(options).then(this.getData);
    // Return
    return this.api.get(this.apiURL + "/" + endpoint, params)
      .then(this.getData)
      .catch(this.onError);
  }
}



export default Order;