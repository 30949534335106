
// CSS
import { useContext } from "react";
import "./style.css";
import { Context } from "../../Context/ContextGlobal";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";

export default function OrderStatus( props ){

  const { business } = useContext(Context);
    
  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

  
  /**Status */ 
  /*
  [0]     =   "Novo";
  [1]     =   "Confirmado"
  [10]    =   "Em coleta";
  [20]    =   "Em entrega";
  [30]    =   "Cancelado";
  [40]    =   "Adiado";
  [50]    =   "Finalizado";
  [100]   =   "( internal ) Estimativa de preço";
  */  

  const arrStatus  =   [];
  arrStatus[0]     =   LNG.txt_new_order;
  arrStatus[1]     =   LNG.txt_confirmed_order;
  arrStatus[10]    =   LNG.txt_taking_order;
  arrStatus[20]    =   LNG.txt_on_delivery;
  arrStatus[30]    =   LNG.txt_canceled_order;
  arrStatus[40]    =   LNG.txt_postponed_order;
  arrStatus[50]    =   LNG.txt_finish_order;
  arrStatus[100]   =   LNG.txt_budget_order;
  arrStatus[1000]  =   LNG.txt_undefined_order;

  // Status
  const status = props?.status || 1000;

  // Status name
  const statusName = arrStatus[status] || LNG.txt_undefined_order;

  return(
    <div className={`status status-`+ status}>
      {statusName}
    </div>
  );
}