import React, { useEffect, useState, useContext} from 'react'
import {useNavigate } from "react-router";
import { DatePicker, Application } from 'react-rainbow-components';
import { Context } from "../../Context/ContextGlobal";

// CSS
import './style.css'
import "../Main/DateTimePicker.css";
import "../Main/react-datetime.css";

// Internal imports
import NavBar from "../../components/Navbar";
import ButtonDefault from "../../components/ButtonDefault";
import { theme, containerStyles } from "../../Helper/PickerSettings"
import iconCheck from "../../assets/main/icon-check.svg";
import calendar from "../../assets/main/calendar.svg";
import ButtonReturn from "../../components/ButtonReturn";

// assets
import logo from "../../assets/home/logo-ecarreto.svg";
import icontPrintWhite from "../../assets/global/iconPrint-white.svg"
import Select from "../../components/Select";
import { getLanguageByTimezone, getLocaleLanguage } from '../../lang/utils';


// TRANSLATIONS
// import LNG from "../../lang/en-us.json"
// import { withRouter } from "../../components/withRouter";


export default function Reports(props) {
  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

  // States
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  // function handleShopcartRemove(index) {
  //   // deliveryItems.splice(index, 1)
  //   // localStorage.setItem("items", JSON.stringify(deliveryItems));
  //   // setList(deliveryItems);
  // }

  // function _handleSaveDate() {
  //     // if the date is valide, it continues
  //     localStorage.setItem("dateStart", dateStart);
  //     // Save date
  //     localStorage.setItem("dateEnd", dateEnd);
  // }

  // function handleValidateData() {
    // // Validation
    // const validation = shopcartCheck();
    // if (!validation) {
    //   // Which field is empty

    //   // to review if data is only new Date it does not validate
    //   if (deliveryDate === "") {
    //     errorToaster("Por favor, defina uma data!");
    //   } else if (deliveryFrom === "") {
    //     errorToaster("Por favor, defina o local de coleta!");
    //   } else if (deliveryTo === "") {
    //     errorToaster("Por favor, defina o local de entrega!");
    //   } else if (!deliveryItems.length) {
    //     errorToaster("Por favor, selecione ao menos um produto para entrega!");
    //   }
    //   return;
    // }

    // Validation passed
    // history('/resumo-pedido', {replace: true});
  // }

  useEffect(() => {
    // /**Getting locations name from storage when they change */
    // if (localStorage.hasOwnProperty("addressFrom"))
    //   setDeliveryFrom(JSON.parse(localStorage.getItem("addressFrom")));

    // if (localStorage.hasOwnProperty("addressTo"))
    //   setDeliveryTo(JSON.parse(localStorage.getItem("addressTo")));

    // if (localStorage.hasOwnProperty("dte")) {

    //   let today = new Date()
    //   let storedDate = new Date((localStorage.getItem("dte")))

    //   // if date in storage is older than today, sets now date
    //   if (storedDate < today) {
    //     setDeliveryDate(today)
    //     _handleSaveDate()
    //     return
    //   }

    //   setDeliveryDate(new Date((localStorage.getItem("dte"))));
    // }

  }, [])
  const history = useNavigate()
  return (<>
    <div className="container-main-Reports ">
      <NavBar />
      <div className="container-Reports-central">
        <div className="container">
          <ButtonReturn onClick={() => history(-1, { replace: true })  } />
          <div className="container-header-Reports"></div>
          <h1 className="Reports-title">{LNG.title_reports}</h1>

          <div className="box-reports-header">
            <Select className={"box-reports-select"} options={
              [
                LNG.option_reports_payments,
                LNG.option_reports_deliveries
              ]
            } />

            <h1 className="box-reports-picker-legends">{LNG.txt_reports_from}</h1>
            <div>
              {/* <h2>Qual a data da coleta?</h2> */}
              <div className="box-reports-header-datepicker">
                {dateStart && dateStart !== "Invalid Date" ? (
                  <img className="checked" alt={LNG.alt_checked} src={iconCheck} />
                ) : (
                  <></>
                )}

                <Application className="react-datepicker-wrapper" theme={theme}>
                  <div style={{ containerStyles }}>
                    <DatePicker
                      placeholder=""
                      // formatStyle="small"
                      value={dateStart}
                      // minDate={new Date()}
                      onChange={setDateStart}
                      // onBlur={_handleSaveDate}
                      hideLabel={true}
                      icon={<></>}
                      locale={LNG.option_main_calendar}
                      okLabel={LNG.button_accept}
                      cancelLabel={LNG.button_cancel}
                      // hour24={true}
                      dateFormat={LNG.option_main_calendar_date_format}
                    // timeFormat={'HH:mm'}
                    />
                  </div>
                </Application>

                <img
                  alt={LNG.alt_calendar}
                  className="img-input"
                  src={calendar}
                />
              </div>
            </div>
            <h1 className="box-reports-picker-legends">{LNG.txt_reports_to}</h1>


            <div>
              {/* <h2>Qual a data da coleta?</h2> */}
              <div className="box-reports-header-datepicker">
                {dateEnd && dateEnd !== "Invalid Date" ? (
                  <img className="checked" alt={LNG.alt_checked} src={iconCheck} />
                ) : (
                  <></>

                )}

                <Application className="react-datepicker-wrapper" theme={theme}>
                  <div style={{ containerStyles }}>
                    <DatePicker
                      placeholder=""
                      value={dateEnd}
                      // minDate={new Date()}
                      onChange={setDateEnd}
                      // onBlur={_handleSaveDate}
                      hideLabel={true}
                      icon={<></>}
                      locale={LNG.option_main_calendar}
                      okLabel={LNG.button_accept}
                      cancelLabel={LNG.button_cancel}
                      // hour24={true}
                      dateFormat={LNG.option_main_calendar_date_format}
                    // timeFormat={'HH:mm'}
                    />
                  </div>
                </Application>

                <img
                   alt={LNG.alt_calendar}
                  className="img-input"
                  src={calendar}
                />
              </div>
            </div>

              <ButtonDefault className="box-reports-button-container" img={icontPrintWhite}>{LNG.button_print}</ButtonDefault>
          </div>

        </div>

      </div>
      <div className="logo-img-container">
        <img className="logo-img" alt={LNG.alt_logo} src={business?.logo_url} />
      </div>
    </div>

  </>)
}



