import { useState, useEffect } from "react";

// Local components
import api from "../../Api/Api";
import Auth from "../../Api/Auth";

function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();

  useEffect(() => {
    // User stored data
    const dadosUsuario = JSON.parse(localStorage.getItem("token"));

    if (dadosUsuario) {
      try {
        // User token
        const { token } = dadosUsuario;
        if (token) {
          // Set auth true in context
          setAuthenticated(true);
          // Set user data
          setUser(dadosUsuario);
        }
      } catch (err) {
      }
    }
    setLoading(false);
  }, []);
  

  async function handleLogin(data) {
    // Auth API
    const userAPI = new Auth();

    // Exectute login
    const response = await userAPI
      .login(data)
      .then((ret) => {
        if (ret && ret.error) {
          // Login error
          // alert(ret.message);

          // Erase stored user data
          handleLogout(false);

          // Return false
          return { success: false, message: ret.message };
        } else {
          // Login ok

          // Store login data
          localStorage.setItem("token", JSON.stringify(ret));

          // Return
          return { success: true, message: "" };
        }
      })
      .catch((error) => {
        // Erase stored user data
        handleLogout(false);
        try {
          return { success: false, message: error.response.data.message };
        } catch (err) {
          return { success: false, message: error };
        }
        // Return false
      });

    return response;
  }

  function handleLogout(withRedirect = true) {
    // Set auth false in context
    setAuthenticated(false);
    // Remove data salved
    localStorage.removeItem("token");
    // Remove default auth to axios
    api.defaults.headers.Authorization = undefined;

    if (withRedirect) {
      // Redir to home page
      window.location.href = "/";
    }
  }

  return { authenticated, handleLogin, handleLogout };
}
export default useAuth;
