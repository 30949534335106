export const getLocaleLanguage = {
  'pt-br': require('./pt-br.json'),
  'en-us': require('./en-us.json'),
  'fr': require('./fr.json'),
  'es': require('./es.json')
}

export const getLanguageByTimezone = (timezone) => {
  switch (timezone) {
    case "America/Sao_Paulo":
      return "pt-br";
    case "Etc/GMT+12":
    case "Etc/GMT+11":
    case "Pacific/Midway":
    case "Pacific/Honolulu":
      return "en-us";
    case "America/Juneau":
    case "America/Dawson":
    case "America/Boise":
    case "America/Chicago":
    case "America/Regina":
    case "America/Detroit":
    case "America/Indiana/Indianapolis":
    case "America/New_York":
    case "America/Halifax":
    case "America/St_Johns":
      return "en-us";
    case "America/Chihuahua":
    case "America/Mexico_City":
    case "America/Belize":
    case "America/Bogota":
    case "America/Caracas":
    case "America/Manaus":
    case "America/Santiago":
    case "America/Argentina/Buenos_Aires":
    case "America/Montevideo":
      return "es";
    case "America/Godthab":
      return "en-us";
    case "Atlantic/Azores":
      return "pt-br";
    case "Atlantic/Cape_Verde":
      return "pt-br";
    case "Europe/London":
    case "Etc/GMT":
    case "Africa/Lagos":
    case "Asia/Kolkata":
      return "en-us";
      case "Europe/Paris":
      return "fr";
    case "Europe/Warsaw":
    case "Europe/Zurich":
    case "Africa/Cairo":
    case "Asia/Jerusalem":
    case "Asia/Amman":
    case "Asia/Beirut":
    case "Asia/Damascus":
    case "Asia/Riyadh":
    case "Africa/Johannesburg":
    case "Europe/Kiev":
    case "Asia/Tehran":
    case "Europe/Minsk":
    case "Asia/Dubai":
    case "Asia/Yerevan":
    case "Asia/Baku":
    case "Asia/Tbilisi":
    case "Asia/Kabul":
    case "Asia/Yekaterinburg":
    case "Asia/Krasnoyarsk":
    case "Asia/Irkutsk":
    case "Asia/Yakutsk":
    case "Asia/Magadan":
    case "Asia/Kamchatka":
    case "Asia/Kuala_Lumpur":
    case "Asia/Taipei":
    case "Australia/Perth":
    case "Australia/Darwin":
    case "Australia/Adelaide":
    case "Australia/Sydney":
    case "Australia/Brisbane":
    case "Australia/Hobart":
    case "Pacific/Guam":
    case "Pacific/Port_Moresby":
    case "Pacific/Fiji":
    case "Pacific/Auckland":
    case "Pacific/Tongatapu":
    case "Pacific/Apia":
      return "en-us";
    default:
      return "pt-br";
  }
}
