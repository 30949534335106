import { useState, useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";
// Css
import "./style.css";

// Data analysis
import { MP } from "../../components/MixPanel";
// assets
import iconTransporte from "../../assets/deliveryProfile/icon-transporte-itens.svg";
import mudancaResidencial from "../../assets/deliveryProfile/home.svg";
import mudancaEmpresarial from "../../assets/deliveryProfile/mudanca-empresarial.svg";
import transpSegurado from "../../assets/deliveryProfile/transp-segurado.svg";
import avancar from "../../assets/home/seta-avancar.svg";
// components
import {ShepherdTourContext } from 'react-shepherd';
import ModalTour from "../../components/Tour/ModalTour";
import ButtonDefault from "../../components/ButtonDefault";
import links from "../../config/Links";
import NavBar from "../../components/Navbar";
import ButtonReturn from "../../components/ButtonReturn";

// TRANSLATION
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";
import { Context } from "../../Context/ContextGlobal";


export default function DeliveryProfile({setSteps, ...props}) {


  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]
  // MP
  MP("Delivery Profile");

  const tour = useContext(ShepherdTourContext);
  
  // Stored delivery type
  const deliveryTypeStored = localStorage.getItem("deliveryType") || "";

  let modalTourActivated  = !localStorage.hasOwnProperty("pagesTourSeen")
  if (localStorage.hasOwnProperty("pagesTourSeen")) {
    modalTourActivated = JSON.parse(localStorage.getItem("pagesTourSeen")).modal_show === true ? true  : false
  }
  
  // Delivery type state 
  const [deliveryType, setDeliveryType] = useState( parseInt(deliveryTypeStored) || 1);
  const [modalOpen, setModalOpen] = useState(modalTourActivated);
    
  // Navigation
  const history = useNavigate();

  // Save to localStorage
  async function handleSaveProfileDelivery(type) {

    if (localStorage.hasOwnProperty("pagesTourSeen")){
      const restProps = JSON.parse(localStorage.getItem('pagesTourSeen'))
      localStorage.setItem('pagesTourSeen', JSON.stringify({
        ...restProps,
        delivery_type: true,
      }))
    } else {
      localStorage.setItem('pagesTourSeen', JSON.stringify({
        delivery_type: true,
      }))
    }

    // Save to localStorage
    await localStorage.setItem("deliveryType", type);
  }
  // Button focus
  async function goTo( type ){
    // if(tour.isActive()) return
    if( !type ) type = deliveryType || 1;

    // Save to localStorage
    await handleSaveProfileDelivery(type);

    // Set type
    setDeliveryType(type);

    // Scroll down
    // document.getElementById("btGo").scrollIntoView({behavior: 'smooth'}, true);

    // Go to next page
    history({
      pathname: links.main,
      state: {
        deliveryType : type
      }    
    });    
  }

  useEffect(() => {

    if (!modalOpen) {
      if (localStorage.hasOwnProperty("pagesTourSeen")) {
        if (!JSON.parse(localStorage.getItem("pagesTourSeen")).delivery_type) {
          tour.start()
        }
      } else {
        tour.start()
      }
    }
      
  },[modalOpen])

  // Top page
  useEffect(() => {
    try { window.scrollTo(0, 0); } catch (err) {}
  }, []);  

  return (
    <div className="container-DeliveryProfile-principal">
      <NavBar />
      {modalOpen &&
        <ModalTour
          setOpenModal={setModalOpen}
          url={""}
        />}
        <div className="container">
      <div className="container-central-DeliveryProfile">
        
        <ButtonReturn to={links.dashboard} />
        <h1> {LNG.title_delivery_type}</h1>
          <div className="container-DeliveryProfile">
            <div className="container-tipo-carga">
              <div
                className={"cx-tipo-carga tour-description-delivery-items " + (deliveryType === 1 ? " on" : null)}
                onClick={() => {goTo(1)}}
              >
                <img alt={LNG.alt_items_trip} src={iconTransporte} />
                <span>{LNG.type_delivery_items}</span>
              </div>
              <div
                className={"cx-tipo-carga tour-description-delivery-home " + (deliveryType === 2 ? " on" : null)}
                onClick={() => goTo(2)}
              >
                <img alt={LNG.alt_residential_trip} src={mudancaResidencial} />
                <span>{LNG.type_delivery_residential}</span>
              </div>
              <div
                className={"cx-tipo-carga tour-description-delivery-enterprise " + (deliveryType === 3 ? " on" : null)}
                onClick={() => goTo(3)}
              >
                <img alt={LNG.alt_enterprise_trip} src={mudancaEmpresarial} />
                <span>{LNG.type_delivery_enterprise}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-inferior-cinza">
        <div className="container">
          <div className="container-DeliveryProfile">
            <div className="posicionamento">
              <div className="img-transpSegurado-deliveryProfile">
                <img alt={LNG.alt_secured_trip} src={transpSegurado} />
              </div>
              <div className="tour-delivery-foward-button">
                  <ButtonDefault id="btGo" img={avancar} onClick={ () => goTo(deliveryType) }>
                    {LNG.button_foward}
                  </ButtonDefault>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}