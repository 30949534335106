import ENV from "../../env";

import React, { useState, useRef, useContext, useEffect } from "react";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { Link } from "react-router-dom";
import { Context } from "../../Context/ContextGlobal";
import axios from "axios";

// Local components
import { useLoading } from "../../components/Loading";
// import ButtonFacebook from "../../components/ButtonFacebook";
// import ButtonApple from "../../components/ButtonApple";
// import ButtonGoogle from "../../components/ButtonGoogle";
// import ButtonCreateAccount from "../../components/ButtonCreateAccount";
import InputLogin from "../../components/InputLogin";
// import ButtonGoFoward from "../../components/ButtonGoFoward";
import Switch from "../../components/Switch";
import links from "../../config/Links";
import ButtonDefault from '../../components/ButtonDefault'

// CSS
import "./style.css";

// TRANSLATION

// Assets from Home folder
// import logoIcon from "../../assets/home/logo-ecarreto.svg";
// import transpSegurado from "../../assets/home/transporte segurado.svg";
import boxDelivery from "../../assets/home/box-delivery-agrupado.svg";
import returnIcon from "../../assets/global/icon-voltar-topo.svg";
import goFowardIcon from "../../assets/home/seta-avancar.svg";

// Toaster
import { errorToaster, successToaster, ToastContainer } from "../../components/Toasters";

// Api imports
import Auth from "../../Api/Auth";
// LNG import
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";
// Main function
function Home(props) {

  // Context applied to lang setup
  const { business } = useContext(Context);
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

    // Api Model Instance
    const DEBUG = true;
    const BASE_URL = ENV().ECARRETO_API_URL || "https://api.ecarreto.com.br"

  // MP
  // MP("Home");
    
  // Inactivate social login buttons
  const socialLogin = false;

  // Loading
  const { setLoading } = useLoading();

  // Form ref
  const formRef = useRef(null);

  // Login context handle
  const { handleLogin, handleLogout } = useContext(Context);

  // Switch - Salve login data
  const [selected, setSelected] = useState(false);
  const [isBeta, setIsBeta] = useState(false);
  const [dataSt, setDataSt] = useState(localStorage.hasOwnProperty("esln") ? JSON.parse(localStorage.getItem("esln")): {})
  const [companyData, setCompanyData] = useState({})
  const [loginAllowed, setLoginAllowed] = useState(false)
    
  async function handleSubmit(data, { reset }) {
      // checking if there is a value saved
      !selected ? localStorage.setItem("esln", JSON.stringify(data)) : localStorage.removeItem("esln", JSON.stringify({}));
  
    // Loading true
    try {
      // Remove all previous errors
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .email(LNG.yup_error_email_required)
          .required(LNG.yup_error_email_message),
          password: Yup.string().required(LNG.yup_error_password_required),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        
      setLoading(true);
      // Validation passed
      const loginResponse = await handleLogin({
        email: data.email,
        password: data.password,
      });

      // Login
      if (loginResponse.success) {
        
            // Auth API
        const auth = new Auth();

        // Get the user information
        auth.me()
        .then((response) => {
          if(response.business) {
            window.location.href = "/dashboard";
          }else {
            handleLogout(false)
            setLoading(false)
            errorToaster(LNG.toaster_error_auth)
          }
          
        })
        .catch((error) => {
          errorToaster(LNG.toaster_error_user_data_access)
          console.log("Error", error);
        });
        // MP
        // MP("Login", { "success":"true" , "email": data.email });

        // Loading false
        // setLoading(false);

        // Redir
      } else {
        // Login error
        // User message
        setLoading(false);

        errorToaster(LNG.toaster_error_auth)
        // MP
        // MP("Login", { "success":"false", "email": data.email });        

      }
    } catch (err) {
      /* const validationErrors = {}; */
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      
      // MP
      // MP("Login", { "error":"true", "email": data.email }); 

      // Loading false
      setLoading(false);
    }
  }

  // Handle navigator returns
  function getReturns(){ 
    // Result passed by location
    const result = props?.location?.state?.result;
    if( result ) {
      const { success, message_br } = result;
      if( success )
        successToaster(message_br);
      else
        errorToaster(message_br);
    }  
  }

  useEffect(() => {
    // console.log(dataSt)
    try{
      const mainURL = window.location.href; 
      // Set beta
      if( mainURL.includes("appbeta.") ) setIsBeta(true);
    } catch(er){}

    getReturns();    

    // validate the company URL
    const options = {
      method: 'GET',
      url: BASE_URL + '/business/',
    };
    axios.request(options).then(function (response) {
      if(DEBUG) console.log(response?.data);

      // when localhost or not found
      if(response?.data?.info?.URL.includes("localhost")) {
        setLoginAllowed(true)
      }
      if(response?.data?.error || !response?.data?.active) {
        // handleLogout(false)
        // setLoginAllowed(false)
      }
      if(response?.data?.active) {
        // Check if avatar image exist on the server 
        // var http = new XMLHttpRequest();
        // http.open('HEAD', response.avatar_url, false);
        // http.send();
    
        // if( http.status !== 404 ) {
        //   // True
        // } else {
        //   // False
        //   response.avatar_url = "https://bringfy.ca/site/img/banco_imagens/logotipo.png";
        // }
        // False

        // Replace user image
        response.avatar_url = "https://bringfy.ca/site/img/banco_imagens/logotipo.png";

        // Set data
        setCompanyData({
          ...response.data,
          avatar_url: response.avatar_url + "?nocache=" + (new Date()).getTime().toString()
        }
        )
        setLoginAllowed(true)
      }

    }).catch(function (error) {
      console.error(error);
      // when error and localhost
      if(error?.response?.data?.info?.URL.includes("localhost")) {
        setLoginAllowed(true)
      }
      if(error?.response.data.error || !error?.response?.data?.active) {
        // setLoginAllowed(false)
        // handleLogout(false)
        errorToaster(LNG.toaster_error_user_data_access || error?.response?.data?.message )
      }
    });


    // Top page
    try { window.scrollTo(0, 0); } catch (err) {} 
  },[])

  return (
    <>
      <div className="container-principal-Home">
      <ToastContainer />
        <div className="container">
          <div className="container-superior">
            <div className="cabecalho-home">
              <div className="logo-home">
              <div className="logo-home">
                <img className="logo-home-img-business" alt={LNG.alt_logo || "Bringfy - Platform"} src={"https://bringfy.ca/site/img/banco_imagens/logotipo.png"}/>
              </div>
                <div className="logo-home-title">
                  {LNG.title_home}
                </div>
              </div>
              {/* <div className="logo-home">
                <img className="logo-home-img" alt={LNG.alt_logo} src={logoIcon} />
                <h1 className="slogan">
                  {LNG.subtitle_home}
                </h1>
              </div> */}
              {/* <img className="selo-transporte-segurado" alt="transp-segurado" src={transpSegurado} /> */}
              <div className="mobile-arrow-down"><a href="#myform"><img alt={LNG.alt_go_bellow}title="Descer" className="arrow" src={returnIcon} /></a></div>
            </div>
          </div>
        </div>
        <div className="container-body">
          <div className="container-conteudo">
            <div className="imgBox">
              <img alt={LNG.alt_box_delivery} src={boxDelivery} />
            </div>
            <div>
              {/* <h4>Cadastre-se ou acesse sua conta</h4>
              <div>
                <Link className="link" to={links.accountScreen}>
                  <ButtonCreateAccount>
                    <span>Criar conta</span>
                  </ButtonCreateAccount>
                </Link>
              </div> */}
              {/* {socialLogin && (
                <div className="social-login">
                  <div>
                    <ButtonGoogle>
                      <span>Google</span>
                    </ButtonGoogle>
                  </div>
                  <div>
                    <ButtonFacebook>
                      <span>Facebook</span>
                    </ButtonFacebook>
                  </div>
                  <div>
                    <ButtonApple>
                      <span>Apple</span>
                    </ButtonApple>
                  </div>
                </div>
              )} */}
            </div>
          {loginAllowed && 
              <Form
                className="login"
                id="myform"
                ref={formRef}
                onSubmit={handleSubmit}
              >
                <h4 className="home-login-title">
                  {LNG.title_home_login}
                </h4>
                <div className="inputField">
                  <InputLogin
                    name="email"
                    type="email"
                    placeholder={LNG.placeholder_home_email}
                  />
                </div>
                <div className="inputField">
                  <InputLogin
                    name="password"
                    type="password"
                    placeholder={LNG.placeholder_home_password}
                  />
                </div>
                <div className="switch">
                  <Switch
                    selected={selected}
                    toggleSelected={() => setSelected(!selected)}
                  />
                  <p>{LNG.txt_home_login_save}</p>
                  <div className="esqueci-senha">
                    <Link to={links.lostPass}>
                      <p>{LNG.txt_home_login_forgot}</p>
                    </Link>
                  </div>
                </div>
                <div>
                  {/* mock-pagination */}

                  <ButtonDefault img={goFowardIcon} style={{ width: "284px" }} Form="myform" type="submit">
                    <span>
                      {LNG.button_foward}
                    </span>
                  </ButtonDefault>
                  {/* mock-pagination */}
                </div>
              </Form>
          }
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
