import React, { useContext } from "react";
import "./styles.css";
import { Context } from "../../Context/ContextGlobal";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";

function ModalPayment({ setOpenModal}, ...props) {


  const { business } = useContext(Context);
    
  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

  const onClick = () => {
    // Getting url from storage and and accessing it
    let url
    if (localStorage.hasOwnProperty("pLin")) {
      url = JSON.parse(localStorage.getItem("pLin"));
    }
    // Passing for the next window
    window.open(url)
  }

  return (
    <div className="backgroundModal-PaymentModal ">
      <div className="title-PaymentModal">
          <div style={{ marginBottom: "65px" }} className="conteudoModal">
            <h2>{LNG.title_modal_payment}</h2>
            <h3>{LNG.txt_modal_payment}
              <span onClick={onClick}>{LNG.link_click_here}</span>
            </h3>
            <button
              onClick={() => {
                setOpenModal(false);
              }}
              className="buttonModal"
            >
              {LNG.button_close}
            </button>
          </div>
      </div>
    </div>
  );
}

export default ModalPayment;
