import { useContext } from "react";
import nfeIcon from "../../assets/global/icon-loading-white.svg"

// CSS
import "./style.css";
import { Context } from "../../Context/ContextGlobal";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";

// TRANSLATION
export default function ButtonNFe( props ){


  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]
  return(
      <button className="btn-nfe-ajuda">
        <img alt={LNG.alt_nfe} src={nfeIcon} />
        <h4>{ props.label || LNG.button_nfe}</h4>
      </button>
  );
}