// import logo from './logo.svg';
// CSS
import { LoadingProvider } from "./components/Loading";
import "./global.css";
import RoutesComponent from "./routes";

function App() {
  return (
    <LoadingProvider>
      <RoutesComponent/>
    </LoadingProvider>
  );
}

export default App;
