import ApiModel from "./ApiModel";

class ClientsApi extends ApiModel {

  // Endpoint
  ENDPOINT = "business/user/";

  constructor() {
    super();

    // User stored data
    const dadosUsuario = JSON.parse(localStorage.getItem("token"));

    if (dadosUsuario) {
      try {
        // User token
        const { token } = dadosUsuario;

        if (token) {
          // Set API user token
          this.api.defaults.headers.common["x-access-token"] = token;
        }
      } catch (err) {
        // Without token. Not authenticated
        this.api.defaults.headers.common["x-access-token"] = null;
      }
    } else {
      // Without token. Not authenticated
      this.api.defaults.headers.common["x-access-token"] = null;
    }
  }

  // Get
  get(id) {

    // Accept only numbers in id
    id = parseInt(id);

    // Valid ID
    if( id === undefined || id === null || isNaN(id) ) id = 0;
    
    // Params
    const params = {
      id
    };

    // Return
    const URL = this.apiURL + this.ENDPOINT + "search";

    return this.api.post(URL, params)
      .then(this.getData)
      .catch(this.onError);
  }

  // List
  list(params) {
    // Return
    const URL = this.apiURL + this.ENDPOINT + "list";
    // console.log("Fnc:","list","Endpoint:", URL, "params");
    return this.api.post(URL, params)
      .then(this.getData)
      .catch(this.onError);
  }  

  // Add new client 
  add(params) {
    // Return
    const URL = this.apiURL + this.ENDPOINT + "add";
    // console.log("Fnc:","add","Endpoint:", URL, "params");
    return this.api.put(URL, params)
      .then(this.getData)
      .catch(this.onError);
  }

  // Update client
  update(params) {
    // Return
    const URL = this.apiURL + this.ENDPOINT + "update";
    // console.log("Fnc:","update","Endpoint:", URL, "params");
    return this.api.patch(URL, params)
      .then(this.getData)
      .catch(this.onError);
  }

}

export default ClientsApi;
