import React, { useContext} from 'react'
// Css
import "./style.css";
import "../../components/InputForm/style.css";
import 'react-credit-cards/es/styles-compiled.css';
import { Context } from "../../Context/ContextGlobal";

// Assets
import logo from "../../assets/home/logo-ecarreto.svg";
import iconNFe from "../../assets/payments/iconNFe.svg"

// Local components
import NavBar from "../../components/Navbar";
import ButtonReturn from "../../components/ButtonReturn";
// import ButtonViewOrder from "../../components/ButtonViewOrder";
// import links from "../../config/Links";
import {useNavigate } from "react-router-dom";
import { getLanguageByTimezone, getLocaleLanguage } from '../../lang/utils';

// TRANSLATION

export default function Payments() {

  // history
  const history = useNavigate()
  const { business } = useContext(Context);
  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

  return (
    <div className="container-main-Payments">
      <NavBar />
      <ButtonReturn onClick={() => history(-1, { replace: true })  } />

      <div className="container">
        <h1>{LNG.title_payments}</h1>
        <div className="icon-caixa-payments-table">

          <table
            className="table table-bordered"
            id="dataTable"
            width="100%"
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th align="center">{LNG.txt_dashboard_table_code}</th>
                <th align="center">{LNG.txt_payments_table_bill}</th>
                <th align="center">
                  {LNG.txt_payments_table_expiry}
                </th>
                <th align="center">
                  {LNG.txt_payments_table_payment}
                </th>
                <th align="center">
                  {LNG.txt_payments_table_value}
                </th>
                <th align="center">
                  {LNG.txt_payments_table_nfe}
                </th>
                <th align="center">
                  {LNG.txt_dashboard_table_status}
                </th>
                {/* <th>&nbsp;</th> */}

              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="center">#1230</td>
                <td>Julho/2022</td>
                <td>05/07/2022</td>
                <td>10/06/2022</td>
                <td>R$563,90</td>
                <td align="center">
                  {/* NFe icon button */}
                  <div className="payments-nfe-button">
                    <img alt={LNG.alt_icon_nfe}  src={iconNFe} />
                  </div>
                </td>
                <td align="center" className="status statusOrderview-1">{LNG.txt_paid_order}</td>
                {/* <td align="center">
                  <ButtonViewOrder text="Visualizar" />
                </td> */}
              </tr>
              <tr>
                <td align="center">#3242</td>
                <td>Julho/2022</td>
                <td>05/07/2022</td>
                <td>10/06/2022</td>
                <td>R$563,90</td>
                <td align="center">
                  {/* NFe icon button */}
                  <div className="payments-nfe-button">
                    <img alt={LNG.alt_icon_nfe} src={iconNFe} />
                  </div>
                </td>
                <td align="center" className="status statusOrderview-2">{LNG.txt_delayed_order}</td>

              </tr>

              <tr>
                <td align="center">#5464</td>
                <td>Julho/2022</td>
                <td>05/07/2022</td>
                <td>10/06/2022</td>
                <td>R$200,90</td>
                <td align="center">
                  {/* NFe icon button */}
                  <div className="payments-nfe-button">
                    <img alt={LNG.alt_icon_nfe}  src={iconNFe} />
                  </div>
                </td>
                <td align="center" className="status statusOrderview-1">{LNG.txt_paid_order}</td>

              </tr>

              <tr>
                <td align="center">#7896</td>
                <td>Maio/2022</td>
                <td>05/07/2022</td>
                <td>10/06/2022</td>
                <td>R$949,90</td>
                <td align="center">
                  {/* NFe icon button */}
                  <div className="payments-nfe-button">
                    <img alt={LNG.alt_icon_nfe}  src={iconNFe} />
                  </div>
                </td>
                <td align="center" className="status statusOrderview-1">{LNG.txt_paid_order}</td>

              </tr>


            </tbody>
          </table>
        </div>

        <div className="logo-img-container">
          <img className="logo-img" alt={LNG.alt_logo} src={business?.logo_url} />
        </div>
      </div>


    </div>
  );
}
