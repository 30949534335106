export const theme = {
    rainbow: {
      palette: {
        brand: '#ff5e00',
      },
    },
  };

export const containerStyles = {
    maxWidth: 400,
  };