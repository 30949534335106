import React, { createContext } from "react";

import useAuth from "./Hook/useAuth";
import useBusiness from "./Hook/useBusinessData";

const Context = createContext();

function AuthProvider({ children }) {
  const { authenticated, loading, handleLogin, handleLogout, user } = useAuth();
  const { business} = useBusiness();

  return (
    <Context.Provider
      value={{ loading, authenticated, handleLogin, handleLogout, user, business }}
    >
      {children}
    </Context.Provider>
  );
}

export { Context, AuthProvider };
