import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Tooltip from "rc-tooltip";
import './tooltip-white.css';

// Routes
import links from "../../config/Links";

// Css
import "./style.css";

// Local components
import NavBar from "../../components/Navbar";
import ButtonDefault from "../../components/ButtonDefault";
import MobileSwitch from "../../components/MobileSwitch";
import ButtonReturn from "../../components/ButtonReturn";
import HelpButton from "../../components/ButtonHelp";

// Data analysis
import { MP } from "../../components/MixPanel";

// Assets
import caminhao from "../../assets/orderResume/truck-delivery.svg";
import pgtoSeguro from "../../assets/orderResume/ambiente-seguro.svg";
import interrogacao from "../../assets/orderResume/interrogacao.svg";
import calendar from "../../assets/orderResume/calendar.svg";
import iconLocationEsq from "../../assets/orderResume/icon-location-esq.svg";
import iconLocationDir from "../../assets/orderResume/icon-location-dir.svg";

//Api call utils
import { orderEstimatePrice, shopcartCheck } from "../../Helper/Utils";
import { useLoading } from "../../components/Loading";
import { errorToaster, ToastContainer } from "../../components/Toasters";
import ButtonCheckbox from "../../components/ButtonCheckbox";
// import { ShepherdTourContext } from "react-shepherd";

import LNG from "../../lang/en-us.json"

export default function OrderResume() {
  // Tour context
  // const tour  = useContext(ShepherdTourContext)

  // MP
  MP("Order Resume");  

  // Navigation
  const history = useNavigate();

  const [decicatedSwitch, setDecicatedSwitch] = useState(true);
  const [list, setList] = useState([]);

  /**Date  */
  const [deliveryDate, setDeliveryDate] = useState("Por favor, defina uma data");
  const [deliveryFrom, setDeliveryFrom] = useState("");
  const [deliveryTo, setDeliveryTo] = useState("");

  //Api call Switch
  const [apiResponse, setApiResponse] = useState({ financial: "..." });
  // const [pricef, setPricef] = useState();
  const { setLoading } = useLoading();

  const [checkValue, setCheckValue] = useState(false)

  function handleFinishTour() {
      // Tour controller
      if (localStorage.hasOwnProperty("pagesTourSeen")){
        const restProps = JSON.parse(localStorage.getItem('pagesTourSeen'))
        localStorage.setItem('pagesTourSeen', JSON.stringify({
          ...restProps,
          order_resume: true,
        }))
      }else {
        localStorage.setItem('pagesTourSeen', JSON.stringify({
          order_resume: true,
        }))
      }
  }

  function handleOrderPayment() {
    handleFinishTour()
  
    // Validation
    const validation = shopcartCheck();
    if(!checkValue) {
      errorToaster(LNG.toaster_error_accept_terms)
      return
    }
    if (!validation) {
      // Wrong Navigation
      
      // MP
      MP("Order Resume", { "success": "false", "validation" : "false" });  
            
      // Send msg to user
      alert(LNG.toaster_error_location_options);
      // Return to main
      history(links.main);
      return;
    }
    if (!decicatedSwitch) {
      localStorage.setItem("dedicatedDelivery", JSON.stringify(true));
    } else {
      localStorage.setItem("dedicatedDelivery", JSON.stringify(false));
    }
    if (apiResponse) {
      localStorage.setItem("pricef", JSON.stringify(apiResponse.financial.pricef || ""));
    }

    // MP
    MP("Order Resume", { "success": "true" });  

    // Validation passed
    history(links.orderContact);
  }

  const handleOnSwitchChange = () => {

    orderEstimatePrice(setApiResponse,
      setLoading, errorToaster).then((response) => {
        setLoading(true)

        if (response) {
          setApiResponse(response)
          localStorage.setItem("pricef", JSON.stringify(apiResponse.financial.pricef || LNG.txt_default_price_number));
          // setPricef(apiResponse.financial.pricef)
          setLoading(false)
        }
      })
    setLoading(false)
  }

  useEffect(() => {
    
    // Tour controller
    // if (localStorage.hasOwnProperty("pagesTourSeen")){
    //   if(!JSON.parse(localStorage.getItem("pagesTourSeen")).order_resume) {
    //     tour.start()
    //   }
    // }else {
    //   tour.start()
    // }

    /** Getting items */
    let items = '';
    if (localStorage.hasOwnProperty("items")) {
      items = JSON.parse(localStorage.getItem("items"));
      setList(items)
    }
    /**Getting dedicated delivery */
    let dedic;
    if (localStorage.hasOwnProperty("dedicatedDelivery")) {
      dedic = JSON.parse(localStorage.getItem("dedicatedDelivery"));
      setDecicatedSwitch(!dedic)
    } else {
      localStorage.setItem("dedicatedDelivery", JSON.stringify(false));
    }

    /** Getting addressFrom*/
    let addressFrom = ''
    if (localStorage.hasOwnProperty("addressFrom")) {
      addressFrom = JSON.parse(localStorage.getItem("addressFrom"));
      setDeliveryFrom(addressFrom)

      // let plusInfo = addressFrom.slice(addressFrom.indexOf('of') + 2)
    }

    /**Getting addressTo */
    let addressTo = ''
    if (localStorage.hasOwnProperty("addressTo")) {
      addressTo = JSON.parse(localStorage.getItem("addressTo"));
      setDeliveryTo(addressTo)
    }

    /**Getting the date */
    if (localStorage.hasOwnProperty("dte")) {
      let dte = new Date((localStorage.getItem("dte")));
      let newdte = dte.toLocaleDateString("pt-BR", {
        hour: '2-digit',
        minute: '2-digit'
      })
      setDeliveryDate(newdte)
    }

    if (!shopcartCheck()) {
      errorToaster(LNG.toaster_error_options_validation)
      return
    }

    handleOnSwitchChange()
    setLoading(false)

  }, [setLoading]);

  // Top page
  useEffect(() => {
    try { window.scrollTo(0, 0); } catch (err) {}
  }, []);  

  return (
    <>
      <div className="container-principal-OrderResume">

        <NavBar />
          <ToastContainer/>
        <div className="container">
          <ButtonReturn onClick={handleFinishTour} to={links.main} />
          <div className="container-OrderResume">
            <div className="container-central-OrderResume ">
              <h1>{LNG.title_order_resume}</h1>
              <hr />
              <div className="container-resumo-transp ">
                <div className="tour-order-resume-date-route">
                  <h3>{LNG.subtitle_order_resume_box_1}</h3>
                  <div className="cx-info-transp">
                    <div className="opcoes-transp">
                      <img alt={LNG.alt_calendar} src={calendar} />
                      <input
                        style={{ color: "#303030" }}
                        value={deliveryDate}
                        readOnly
                      />
                    </div>
                    <div className="opcoes-transp">
                      <img alt={LNG.alt_left_arrow_pin} src={iconLocationEsq} />
                      <div>
                        <div className="local-trans">
                          {deliveryFrom}
                        </div>
                      </div>
                    </div>
                    <div className="opcoes-transp">
                      <img alt={LNG.alt_right_arrow_pin} src={iconLocationDir} />
                      <div>
                        <div className="local-trans">
                          {deliveryTo}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tour-order-resume-items">
                  <h3>{LNG.subtitle_order_resume_box_2}</h3>
                  <div className="cx-info-transp-central">
                    <ul style={{ listStyle: "none", marginTop: "30px", overflow: "auto" }}>
                      {list.map((item, index) => (
                        <li key={index}>
                          <div className="lista-item-OrderResume">
                            <div className="lista-item-OrderResume-input-number ">
                              {item.quantity || 1} <span>{LNG.x_symbol}</span>
                            </div>
                            <div className="lista-item-OrderResume-input-name" >{item.name}</div>
                          </div>
                          {item.observations ? (
                            <div className="lista-item-OrderResume-observations">
                              <span>{LNG.txt_order_resume_observations} {item.observations}</span>
                            </div>
                          ) : ""}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* <div>
                <h3>Selecione os opcionais desejados</h3>
                <div className="cx-info-transp">
                  <div>
                    <div className="opcoes-transp-opcionais">
                      <Switch
                        selected={antiguidades}
                        toggleSelected={() => {
                          setAntiguidades(!antiguidades);
                        }}
                      />
                      <h5>Antiguidades</h5>
                      <div className="img-interrogacao">
                        <img alt="interrogacao" src={interrogacao} />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>

              {/* Order Check Session */}

              {/* TODO: refactor the CSS class to fit into the mobile and then into desktop */}
              <div className="container-resumo-transp tour-order-resume-condition ">
                {/* <h3>Selecione os opcionais desejados</h3> */}
                <div className="cx-info-transp-checkbox">
                  <div>
                    <div className="opcoes-transp-content-checkbox">
                    { /* <input type="checkbox" name="package" value={item?.id} checked={ packageId === item?.id ? true : false } /> */ }
                      <ButtonCheckbox
                        checkValue={checkValue}
                        setCheckValue={setCheckValue}
                      />
                      <h4 onClick={ () => setCheckValue(!checkValue)}> {LNG.txt_order_weight_warning}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>          
          </div>
          <div className="button-chat tour-order-resume-help-button">
            <p>{LNG.title_did_you_find_it}</p>
            <HelpButton />
          </div>     
        </div>
        <div className="container-OrderResume-inferior">
          <div className="container">
            <div className="container-OrderResume">
              <div className="switch-OrderResume tour-order-resume-dedicated ">
                <MobileSwitch
                  selected={decicatedSwitch}
                  toggleSelected={() => {
                    setDecicatedSwitch(!decicatedSwitch);
                    localStorage.setItem("dedicatedDelivery", JSON.stringify(decicatedSwitch));
                    handleOnSwitchChange()
                  }}
                />
                <h4>{LNG.txt_order_resume_delivery}</h4>
               <div>
               <Tooltip placement="bottom" trigger={['hover', 'click']} overlay={LNG.txt_order_resume_tooltip} >
                <img className="container-OrderResume-inferior-tooltip" alt="interrogacao" src={interrogacao} />
                    </Tooltip>
               </div>
              </div>
              <div className="posicionamento-order-resume tour-order-resume-princing">
                <div className="container-itens-OrderResume">
                  {/* <div className="timer">
                  <img alt="interrogacao" src={timer} />
                  <h3>Tempo estimado total</h3>
                  <input
                    placeholder="timer"
                    value="5hs 30mins"
                    readOnly
                  ></input>
                </div> */}
                  <div className="total">
                    <h2>{LNG.txt_order_resume_total}</h2>
                    <span>{!apiResponse.financial.error ? apiResponse.financial.pricef : <span className="totalLoading">...</span>}</span>
                  </div>
                </div>
                <div className="tour-order-resume-foward-button">
                  <ButtonDefault img={caminhao} onClick={() => handleOrderPayment()}>{LNG.button_confirm}</ButtonDefault>
                  <div className="img-confirmar-OrderResume">
                      <img alt={LNG.alt_secure_payment} src={pgtoSeguro} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}