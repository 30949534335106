export const searchingDriver = [
  {
    id: 'tour-description-searching-driver-page',
    title: '<strong>Encontre um motorista próximo</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
      Esta é a etapa de Busca de motorista, aqui será possivel acompanhar
       o seu transporte até que ele seja direcionado para um de nossos parceiros.
      `
    ],

    attachTo: { element: 'tour-description-searching-driver-page', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },

  {
    id: 'tour-description-searching-driver-page',
    title: '<strong>Buscando...</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
      Ao visualizar o texto 'Buscando melhores transportadores' significa que estamos buscando
       o motorista ideal para o carreto.
      `
    ],

    attachTo: { element: '', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-description-searching-driver-page',
    title: '<strong>Quando aceito</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
      Ao visualizar a mensagem: 'Transporte agendado', significa que
       tudo está pronto para que o seu frete ocorra, será possível visualizar também,
        as informações do motorista.
      `
    ],

    attachTo: { element: '', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },

  {
    id: 'tour-description-searching-driver-page',
    title: '<strong>Precisa de ajuda?</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
        Caso visualize a mensagem: 'Solicitação cancelada' ou 'Ocorreu um erro em sua solicitação'
       o pedido foi cancelado, entre em contato conosco.
      `
    ],

    attachTo: { element: '', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'cancel',
        text: 'Entendi'
      }
    ]
  },
]