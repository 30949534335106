import "./style.css";

function Input(props) {
    const defaultProps = {
      ...props,
    };

    let container = " input " + (props.containe ? props.container : "");

    return (
      <div className={container}>
        <div style={{ width: "100%" }}>
          <input {...defaultProps} />
        </div>
        <div>
          <img  src={props.img} />
        </div>
      </div>
    );
}

export default Input;
