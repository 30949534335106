import React, { useState, useRef } from "react";

import "./style.css";

function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  /*  const [ setRotate,  setRotateState] = useState("accordion__icon"); */

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    /*  setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    ); */
  }

  /*   const defaultProps = {
    ...props,
    className: " btnPadrao " + (props.className ? props.className : ""),
  }; */

  return (
    <div className="accordion__section">
      <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
        {props.children}
        <div className="imgBtnPadrao">
          <img alt="" src={props.img} />
        </div>

        {/* <p className="accordion__title">{props.title}</p> */}
      </button>
      <div
        ref={content}
        style={{ height: `${setHeight}` }}
        className="accordion__content"
      >
        {props.content}
      </div>
    </div>
  );
}

export default Accordion;