import { useContext } from "react";
import "./style.css";
import { Context } from "../../Context/ContextGlobal";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";


function ButtonDefault(props) {

  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]
    const defaultProps = {
      ...props,
      className: " btnPadrao " + (props.className ? props.className : ""),
    };

    return (
      <button {...defaultProps}>
        {props.children}
        <div className="imgBtnPadrao">
          <img alt={props?.alt && LNG.alt_button_default} src={props?.img} />
        </div>
      </button>
    );
}

export default ButtonDefault;
