export const deliveryProfile = [
  {
    id: 'tour-description-delivery-type',
    title: '<strong>O que iremos transportar ?</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
      Esta é a etapa inicial de seu pedido de transporte. Aqui você escolhe o tipo de entrega que deseja solicitar. Clique em próximo para conhecer as opções disponíveis.
      `
    ],

    attachTo: { element: '', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'next',
        text: 'Próximo',
      }
    ]
  },
  {
    id: 'tour-description-delivery-items',
    title: '<strong>Transporte de itens</strong>',
    canClickTarget: false,
    scrollTo: true,
    text:
      'O transporte de itens é o modo mais flexível para pedir um carreto, você pode selecionar os itens que deseja que sejam transportados e a quantidade, e nós cuidamos do resto.',
    attachTo: { element: '.tour-description-delivery-items', on: 'bottom' },
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },      
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-description-delivery-home',
    title: '<strong>Mudança residencial</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `Está de mudança de casa ou apartamento ? Esta é a escolha ideal para você. Selecione esta opçaõ e escolha o pacote que melhor te atender.`
    ],
    attachTo: { element: '.tour-description-delivery-home', on: 'bottom' },
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },      
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-description-delivery-enterprise',
    title: '<strong>Mudança empresarial</strong>',
    canClickTarget: false,
    scrollTo: true,
    attachTo: {
      element: '.tour-description-delivery-enterprise',
      on: 'bottom'
    },
    // classes: 'custom-class-name-1 custom-class-name-2',
    text: [
      'Sua empresa vai para outro local e precisar transportar toda estrutura ? Esta é a opção ideal para você. Oferecemos pacotes adequados para todo o tipo e tamanho de empresa.'
    ],
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair',
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },      
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },
  {
    id: 'tour-delivery-foward-button',
    title: '<strong>Próxima etapa</strong>',    
    attachTo: {
      element: '.tour-delivery-foward-button',
      on: 'bottom'
    },
    canClickTarget: false,
    scrollTo: true,
    // classes: 'custom-class-name-1 custom-class-name-2',
    text: [
      `
      Escolha uma opção para avançarmos para a próxima etapa onde você poderá informar mais detalhes sobre seu pedido de transporte.
      `
    ],
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'cancel',
        text: 'Entendi!'
      }
    ],
    
  }
];
