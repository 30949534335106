
// CSS 
import "./style.css";

import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";
import { useContext } from "react";
import { Context } from "../../Context/ContextGlobal";

export default function NotFound() {

    const { business } = useContext(Context);

    // Context applied to lang setup
    let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]
    return (
        <div className="container-principal">
            <div className="margin-notfound">
                <div className="container-central-notfound">
                    <h1>{LNG.title_not_found}</h1>
                    <p>
                        {LNG.subtitle_not_found_1}
                        <br />
                        {LNG.subtitle_not_found_2}
                    </p>
                </div>
            </div>
        </div>
    );
}