import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../Context/ContextGlobal";
import Auth from "../../Api/Auth";

import { useLoading } from "../Loading";

// CSS
import "./style.css";

// Assets
import fechar from "../../assets/menu/fechar.svg";
import perfil from "../../assets/menu/perfil.svg";

// Routes
import Links from "../../config/Links";

// TRANSLATION
import { errorToaster, ToastContainer } from "../Toasters";
import MobileSwitch from "../MobileSwitch";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";

function ModalMenu({ setOpenModal, open }, ...props) {

  const { business } = useContext(Context);
    
  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]


  const { handleLogout, user } = useContext(Context);
  const [urlProfile, setUrlProfile] = useState("")
  const [userData, setUserData] = useState('')

  const { setLoading } = useLoading();
  let switchActivated = false
  if (localStorage.hasOwnProperty("pagesTourSeen")) 
    switchActivated = JSON.parse(localStorage.getItem("pagesTourSeen")).tour
  
  // console.log( "MODAL TOUR", switchActivated)
  const [isTourActive, setIsTourActive] = useState(!switchActivated)


  // User data
  let userName = "";
  try{
    userName = user.user.name;
    // avatarUrl = user.user.avatar_url

  }catch(err){
    // User not logged
    userName = "";
  }

  useEffect(() => {

    // Loading
    setLoading(true);

    // Auth API
    const auth = new Auth();

    // Exec request
    auth.me()
      .then((response) => {
        // Setter State
        if (response.avatar) {
          setUrlProfile(response.avatar_url + "?nocache=" + (new Date()).getTime().toString())
        }
        setUserData(response)
      })
      .catch((error) => {
        errorToaster(LNG.toaster_error_user_data_access)
        console.log("Error", error);
      });

    // Loading
    setLoading(false);

  }, [setLoading]);

  // Class menu modal
  const classModal = open ? "modalContainer on" : "modalContainer off";

  return (
    <>
    <ToastContainer/>
    <div className={classModal}>
      <div className="titleCloseBtn">
        <img
          alt="Fechar menu"
          onClick={() => setOpenModal(!open)}
          src={fechar}
        />
        </div>
        <div className="container-profile">
          <div className="perfil-menu-modal">
            <img className={`profile-image-menu-modal${urlProfile !== "" ? "" : "-default"}`} alt={LNG.alt_profile} src={urlProfile || perfil} />
          </div>
        </div>
      <div className="dados-menu-modal">
        <h2>{userData.adm ? LNG.title_modalmenu_admin :  LNG.title_modalmenu_user }</h2>
        <div className="border-info-modal">
          <div className="info-menu-modal">
            <Link to={Links.main}>{LNG.link_modalmenu_dashboard}</Link>
            <Link to={Links.userData}>{LNG.link_modalmenu_personal}</Link>
            <Link to={Links.reports}>{LNG.link_modalmenu_reports}</Link>
            <Link to={Links.myOrders}>{LNG.link_modalmenu_orders}</Link>
            <Link to={Links.clients}>{LNG.link_modalmenu_clients}</Link>
            <Link to={Links.payments}>{LNG.link_modalmenu_payments}</Link>
            <Link to={Links.settings}>{LNG.link_modalmenu_settings}</Link>
            <Link to={Links.legalInfoPage}>{LNG.link_modalmenu_legal}</Link>
            <Link to={Links.faq}>{LNG.link_modalmenu_help}</Link>
            <Link  onClick={handleLogout} >{LNG.link_modalmenu_exit}</Link>
            <div className="action-tour-modal-menu">
              <MobileSwitch
                selected={isTourActive}
                toggleSelected={() => {
                  console.log( "Dentro do switch", isTourActive)
                    setIsTourActive(!isTourActive);

                    localStorage.setItem("pagesTourSeen", JSON.stringify({
                      tour: isTourActive,
                      modal_show: isTourActive,
                      delivery_type: !isTourActive,
                      main: !isTourActive,
                      order_resume: !isTourActive,
                      order_contact: !isTourActive,
                      user_data: !isTourActive,
                      map: !isTourActive,
                      account_screen: !isTourActive,
                      searching_driver: !isTourActive,
                      lost_pass: !isTourActive,
                      product_add: !isTourActive,
                      tracking: !isTourActive,
                      tracking_map: !isTourActive
                    }));
                }}
              />
              <Link to={"#"}>{LNG.txt_switch_menumodal}</Link>
            </div>
          </div>
        </div>
        <div className="version">
          <p>{LNG.link_modalmenu_version}</p>
        </div>
      </div>
    </div> 
    </>
  );
}

export default ModalMenu;