import { useState, useContext} from "react";
import { Link } from "react-router-dom";
import { Context } from "../../Context/ContextGlobal";
// Routes
import links from "../../config/Links";

// Css
import "./style.css";

// Assets
// import logo from "../../assets/navbar/logo.svg";
import hamburguer from "../../assets/navbar/hamburguer.svg";
import ModalMenu from "../ModalMenu";

// Internal imports
import ButtonHelpNavBar from "../ButtonHelpNavBar";
import ButtonNFe from "../ButtonNFe";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";

// TRANSLATION

export default function NavBar() {
  const [modalOpen, setModalOpen] = useState(false);
  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

  // console.log("B INFO",business)

  return (
    <div className="container-navbar">
      <div className="components-nav">
        <div className="top-logo">
          <Link className="link-logo-navbar" to={links.dashboard}>
          {/* <h2>[{LNG.txt_company_logo}]</h2> */}
          <img src={business?.logo_url} alt={LNG.alt_logo} style={{width:"auto", height:"35px"}}/>
          </Link>
        </div>
        {/* <div className="container-navbar-center-buttons">
          <HelpButton/>
          <ButtonNFe label={"Conexão NF-e"}/>
        </div> */}
        <div className="container-navbar-center-buttons">
        <div className="container-navbar-center-buttons">
            <ButtonHelpNavBar />
            <ButtonNFe label={LNG.button_nfe} />
          <img
            alt={LNG.alt_menu}
            className="img-navBar"
            src={hamburguer}
            onClick={() => setModalOpen(true)}
          />
          </div>
          <ModalMenu open={modalOpen} setOpenModal={setModalOpen} />
      </div>
      </div>
    </div>
  );
}