
// Page routes global scope vars
const links = {
  home                  :   "/",
  dashboard             :   "/dashboard",
  // accountScreen         :   "./criar-conta",
  lostPass              :   "/senha-perdida",
  passRecovery          :   "/senha-recuperacao",
  reports               :   "/relatorios",
  main                  :   "/principal",
  clients               :   "/clientes",
  legalInfoPage         :   "/informacao-legal",
  deliveryProfile       :   "/tipo-entrega",
  whereTo               :   "/onde-vai",
  whereFrom             :   "/onde-vem",
  orderResume           :   "/resumo-pedido",
  productsAdd           :   "/adicionar-produto",
  payments              :   "/pagamento",
  orderContact          :   "/pedido-pagamento",
  userData              :   "/dados-usuario",
  searchingDriver       :   "/encontre-motorista",
  trackingMap           :   "/rastreio-pedido",
  orderView             :   "/pedido",
  myOrders              :   "/pedidos",
  settings              :   "/configuracoes",
  faq                   :   "/faq"
};

export default links;
