import React, { useContext, useEffect, useState } from "react";
import "./style.css";

import location from "../../assets/map/location-pin.svg";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import Input from "../Input";
import { extractAddress, preventLetterInput } from "../../Helper/Utils";

// TRANSLATION
import LNG from "../../lang/en-us.json"
import ButtonDefault from "../ButtonDefault";
import { warningToaster } from "../Toasters";
import { Context } from "../../Context/ContextGlobal";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";

export default function AutoComplete(props) {

  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]
  const DEBUG = false

  const [address, setAdress] = useState("");
  const [displayInputs, setDisplayInputs] = useState(false)

  const [formData, setFormData] = useState({
    street: "",
    number: "",
    city: "",
    hood: "",
    floor: "",
    country: "",
    district: "",
    complement: "",
    postal_code: "",
  })

  function handleChange(address) {
    setAdress(address);
    // props.onAutoComplete(address);
    setFormData(prevState => {
      return {
        ...prevState, street: "",
        number: "",
        city: "",
        hood: "",
        floor: "",
        country: "",
        district: "",
        complement: "",
        postal_code: "",
      }

    })
    /* console.log(address""); */
  }
  function handleSelect(address) {
    // let latLng
    let addressResults

    let streetTemp
    let cityTemp
    let districtTemp
    let numberTemp
    let hoodTemp
    let complementTemp
    let countryTemp
    let postalTemp
    // let floorTemp
    let payload

    handleChange(address);
    // Start map
    // console.log(isLoaded)

    geocodeByAddress(address)
      .then(async (results) => {
        addressResults = results[0].address_components;

        // Garantee that latLng value must be at the storage
        getLatLng(results[0]).then((latLng) => {
          if (DEBUG) console.log("LATITUDE E LONGITUDE DENTRO", latLng)
          // Saving global coords
          if (props.mapType === "From") {
            localStorage.setItem("latAndlongFrom", JSON.stringify(latLng));
          } else {
            localStorage.setItem("latAndlongTo", JSON.stringify(latLng));
          }
        })

        // console.log("LATITUDE E LONGITUDE", latLng)
        if (DEBUG) console.log("RESULT ON SELECT", results)
        if (DEBUG) console.log("RESULTS FILTERED", addressResults)

        // Making inputs visable
        setDisplayInputs(true)

        // Making the data extraction
        streetTemp = extractAddress(addressResults, 'route')
        numberTemp = extractAddress(addressResults, 'street_number')
        cityTemp = extractAddress(addressResults, 'administrative_area_level_2')
        hoodTemp = extractAddress(addressResults, 'political')
        districtTemp = extractAddress(addressResults, 'administrative_area_level_1')
        countryTemp = extractAddress(addressResults, 'country') === "Brazil" ? "Brasil" : extractAddress(addressResults, 'country')
        postalTemp = extractAddress(addressResults, 'postal_code')
        complementTemp = extractAddress(addressResults, 'subpremise')

        // payload base comes from API, if not onLoad completes it
        payload = {
          street: streetTemp,
          number: numberTemp,
          city: cityTemp,
          hood: hoodTemp,
          country: countryTemp,
          district: districtTemp,
          postal_code: postalTemp,
          complement: complementTemp,
          floor: formData.floor
        }

        // Setting states
        setFormData(prevState => {
          return {
            ...prevState,

            street: streetTemp,
            number: numberTemp,
            city: cityTemp,
            hood: hoodTemp,
            country: countryTemp,
            district: districtTemp,
            postal_code: postalTemp,
            complement: complementTemp,
            floor: formData.floor
          }

        })

        localStorage.setItem(`mapReady${props.mapType}`, JSON.stringify("true"));

      })
      .catch((error) => console.error("Error", error))
      .finally(() => {
        props.onFormData(payload)
      })
  }


  useEffect(() => {
    // Checking if there is information about the map, if so, display inputs:
    let payload;
    let propName = `complete${props.mapType}`;

    if (DEBUG) console.log("EFFECT PROP", propName)

    if (localStorage.hasOwnProperty(propName)) {
      payload = JSON.parse(localStorage.getItem(propName))

      // allowing the user to continue, if there is data
      localStorage.setItem(`mapReady${props.mapType}`, JSON.stringify("true"));

      // destructuring 
      // if there are 2 core address info do:
      if (payload.street || payload.number) {
        setDisplayInputs(true)
        setAdress()
        // Setting states
        setFormData(payload)
      }
    }
    // if not: does nothing
  }, []);

  return (
    <div style={{ width: "100%" }} className="canvas">
      <PlacesAutocomplete
        value={address || ""}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={`container-search-input-order-simulator`}>
            <Input
              selected
              style={{ fontSize: "20px" }}
              img={location}
              defaultValue={props.defaultValue || ""}
              {...getInputProps({
                placeholder: LNG.placeholder_type_desired_place,
                className: "location-search-input tour-map-search-bar",
              })}
            />

            <div className="autocomplete-dropdown-container">
              {loading && <div className="autocomplete-loading">{LNG.placeholder_searching_for_places}</div>}
              {!loading && suggestions.map((suggestion) => {
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#ff7e00", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };

                return (
                  <div
                    className="input-suggestion"
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                    key={suggestion.index}
                  >
                    <i className="material-icons">
                      <img
                        alt={LNG.alt_location}
                        style={{
                          width: "15px",
                          height: "15px",
                          marginLeft: "5px",
                          marginRight: "10px",
                          marginTop: "5px"
                        }}
                        src={location}
                      />
                    </i>
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
            {/* button order-simulator */}
            <ButtonDefault
              className={"autocomplete-button-clear "}
              onClick={() => {
                setFormData(prevState => {
                  return {
                    ...prevState, street: "",
                    number: "",
                    city: "",
                    hood: "",
                    floor: "",
                    country: "",
                    district: "",
                    complement: "",
                    postal_code: "",
                  }
                })

                // reseting storage
                if (props.mapType === "From") {
                  localStorage.setItem("completeFrom", JSON.stringify({}));
                  localStorage.setItem("latAndlongFrom", JSON.stringify({}));
                  warningToaster(LNG.toaster_success_pickup_point_deleted)
                } else {
                  localStorage.setItem("completeTo", JSON.stringify({}));
                  localStorage.setItem("latAndlongTo", JSON.stringify({}));
                  warningToaster(LNG.toaster_success_delivery_point_deleted)
                }
              }}
            //  img={iconDelete}
            >{LNG.x_symbol}</ButtonDefault>

          </div>
        )}
      </PlacesAutocomplete>
      { /* displayInputs && */
        <>
          {/* <Input
            style={{ fontSize: "20px" }}
            defaultValue={formData.postal_code}
            placeholder="CEP"
            // disabled={address === "" ? true : false}
            onChange={e => {
              setFormData(prevState => ({...prevState, postal_code: e.target.value }))
              // changing the payload
              props.onFormData({...formData, postal_code: e.target.value})
          }}
            value={formData.postal_code}
            
          /> */}

          {/* 1th container */}
          <div className="input-map-container">

            <div className="input-map-container-big">
              <Input
                className="map-page-street-field"
                style={{ fontSize: "20px" }}
                defaultValue={formData.street}
                value={formData.street}
                placeholder={LNG.placeholder_street}
                // disabled={address === "" ? true : false}
                onChange={e => {
                  setFormData(prevState => ({ ...prevState, street: e.target.value }))
                  // changing the payloadstair
                  props.onFormData({ ...formData, street: e.target.value })
                }}
              />
            </div>
            <div className="input-map-container-small">

              <Input
                className="map-page-number-field"
                style={{ fontSize: "20px" }}
                defaultValue={formData.number}
                value={formData.number}
                placeholder={LNG.placeholder_number}
                // disabled={address === "" ? true : false}
                onChange={e => {
                  setFormData(prevState => ({ ...prevState, number: e.target.value, stairs: 1}))
                  // changing the payload
                  props.onFormData({ ...formData, number: e.target.value })
                }}
              />
            </div>

          </div>
          {/* 2th container */}
          <div className="input-map-container">
            <div className={`input-map-container-${formData.floor.length && formData.floor !== "0" ? "small" : "big"}`}>
           

              <Input
                className="map-page-complement-field"
                style={{ fontSize: "20px" }}
                defaultValue={formData.complement}
                value={formData.complement}
                placeholder={LNG.placeholder_compliment}
                maxLength="40"
                // disabled={address === "" ? true : false}
                onChange={e => {
                  setFormData(prevState => ({ ...prevState, complement: e.target.value }))
                  // changing the payload
                  props.onFormData({ ...formData, complement: e.target.value })
                }}
              />
            </div>
            <div className={`input-map-container-${formData.floor.length && formData.floor !== "0" ? "small" : "small"}`}>
              <Input
                className="map-page-floor-field"
                style={{ fontSize: "20px" }}
                defaultValue={formData.floor}
                value={formData.floor}
                placeholder={LNG.placeholder_floor}

                // disabled={address === "" ? true : false}
                onKeyPress={(event) => {
                  preventLetterInput(event)
                }}
                type="number"
                onChange={e => {
                  
                  if(e.target.value === "") {
                    setFormData(prevState => ({ ...prevState, floor: e.target.value,  stairs: "0"}))
                    // changing the payload
                    props.onFormData({ ...formData, floor: e.target.value, stairs: "0"})
                  }else {
                    setFormData(prevState => ({ ...prevState, floor: e.target.value, stairs: "1"}))
                  // changing the payload
                     props.onFormData({ ...formData, floor: e.target.value, stairs: "1"})
                  }
                }}
              />
            </div>

            {formData.floor.length && formData.floor !== "0" ?
            <div className="input-map-container-small">

              <select
                className="autocomplete-select map-page-stairs-field"
                value={formData.stairs}
                onChange={e => {
                  setFormData(prevState => ({ ...prevState, stairs: e.target.value }))
                  // changing the payload
                  props.onFormData({ ...formData, stairs: e.target.value })
                }}
              >
                <option value="1">{LNG.select_option_stair}</option>
                <option value="2"> {LNG.select_option_elevator}</option>
                <option value="3">{LNG.select_option_concierge}</option>
              </select>
            </div> : <></>
            
          }
          </div>
          {/* 3th container */}
          <div className="input-map-container">
            <div className="input-map-container-medium">
              <Input
                className="map-page-district-field"
                style={{ fontSize: "20px" }}
                defaultValue={formData.hood}
                value={formData.hood}
                placeholder={LNG.placeholder_hood}
                
                // disabled={address === "" ? true : false}
                onChange={e => {
                  setFormData(prevState => ({ ...prevState, hood: e.target.value }))
                  // changing the payload
                  props.onFormData({ ...formData, hood: e.target.value })
                }}
              />
            </div>
            <div className="input-map-container-medium">
              <Input
                className="map-page-city-field"
                style={{ fontSize: "20px" }}
                defaultValue={formData.city}
                value={formData.city}
                placeholder={LNG.placeholder_city}
                // disabled={address === "" ? true : false}
                onChange={e => {
                  setFormData(prevState => ({ ...prevState, city: e.target.value }))
                  // changing the payload
                  props.onFormData({ ...formData, city: e.target.value })
                }}
              />
            </div>
          </div>
          {/* 4th container */}
          <div className="input-map-container">
            <div className="input-map-container-medium">
              <Input
                className="map-page-state-field"
                style={{ fontSize: "20px" }}
                value={formData.district}
                placeholder={LNG.placeholder_district}
                // disabled={address === "" ? true : false}
                onChange={e => {
                  setFormData(prevState => ({ ...prevState, district: e.target.value }))
                  // changing the payload
                  props.onFormData({ ...formData, district: e.target.value })
                }}
              />
            </div>
            <div className="input-map-container-medium">
              <Input
                className="map-page-country-field"
                style={{ fontSize: "20px" }}
                defaultValue={formData.country}
                value={formData.country}
                placeholder={LNG.placeholder_country}
                // disabled={address === "" ? true : false}
                onChange={e => {
                  setFormData(prevState => ({ ...prevState, country: e.target.value }))
                  // changing the payload
                  props.onFormData({ ...formData, country: e.target.value })
                }}
              />
            </div>
          </div>
        </>}
    </div>
  );
}