import { useState } from "react";

import Step from "../Step";
import Slider from "../Slider";

import "./style.css";

export default function ComponentTracking({logs}) {
  let len = logs.length
  const [currentIndex, setCurrentIndex] = useState(len);

  const _handleIndexChange = (index) => {
    setCurrentIndex(index);
  };

  /*  const _handleNext = (currentIndex) => {
    setCurrentIndex(currentIndex + 1);
  }; */

  /*   const _handleComplete = () => {}; */

  return (
    <div className="App">
      <div className="containerComponentTracking">
        <Slider onChange={_handleIndexChange} currentIndex={currentIndex} logs={logs}/>
      </div>

      <Step currentIndex={currentIndex} logs={logs} />
      {/*    <div className="form-container">
        <Form
          currentIndex={currentIndex}
          handleNext={_handleNext}
          handleComplete={_handleComplete}
        />
      </div> */}
    </div>
  );
}
