export const orderContact = [
  {
    id: 'tour-order-contact-presentation',
    title: '<strong>Contatos e Pagamento</strong>',
    text: [
      `
      Esta é a etapa final de sua solicitação de transporte. Aqui você poderá informar os contatos de coleta, entrega e realizar o pagamento.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
      buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-order-contact-switch-autocomplete',
    title: '<strong>Autocompletar informações</strong>',
    text: [
      `
      Marque esta opção se você mesmo estiver no local de coleta. Seus dados serão automaticamente preenchidos no campo de contato.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.tour-order-contact-switch-autocomplete', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
      buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-order-contact-from',
    title: '<strong>Quem estará no local de coleta ?</strong>',
    text: [
      `
      Aqui você poderá informar os dados de contato do responsável no local de coleta.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.tour-order-contact-from', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
      buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },

  {
    id: 'tour-order-contact-to',
    title: '<strong>Quem estará no local de entrega ?</strong>',
    text: [
      `
      Aqui você poderá informar os dados de contato do responsável no local de entrega.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.tour-order-contact-to', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
      buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-order-contact-price',
    title: '<strong>Valor total</strong>',
    text: [
      `
      Aqui é possível conferir novamente o valor de sua solicitação de transporte.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.tour-order-contact-price', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
      buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },

  {
    id: 'tour-order-contact-payment-type',
    title: '<strong>Forma de pagamento</strong>',
    text: [
      `
      Selecione a forma de pagamento desejada. Cartão de Crédito, Débito ou PIX são as opções aceitas.
      `
    ],
    attachTo: { element: '.tour-order-contact-payment-type', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    canClickTarget: false,
    scrollTo: true,
      buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-order-contact-payment-fields',
    title: '<strong>Informações para pagamento</strong>',
    text: [
      `
       As informações para pagamento serão exibidas aqui. Para Cartão de crédito e débito, dados serão solicitados. Para PIX será exibido o QRCode de pagamento e também 
       o PIX Copia e Cola. 
      `
    ],
    attachTo: { element: '.tour-order-contact-payment-fields', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    canClickTarget: false,
    scrollTo: true,
      buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-order-contact-payment-finish',
    title: '<strong>Realizar o pedido</strong>',
    text: [
      `
      Confirme as informações de contato e pagamento e finalize seu pedido.</br></br><b>Muito obrigado por utilizar a e-Carreto :)</b>
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.tour-order-contact-payment-finish', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
      buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'cancel',
        text: 'Entendi'
      }
    ]
  },
]