import React, { useContext } from "react";
import "./style.css";
/* import links from "../config/Links";
import { Link } from "react-router-dom"; */

import compartilhar from "../../assets/searchingDriver/icon-compartilhar-branco.svg";
import perfil from "../../assets/searchingDriver/foto-perfil-jose.svg";
import qrcode from "../../assets/searchingDriver/qrcode.svg";

import ButtonDefault from "../../components/ButtonDefault";
import { Context } from "../../Context/ContextGlobal";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";

function Modal({ setOpenModal }, ...props) {

  const { business } = useContext(Context);
    
  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

  return (
    <div className="backgroundModal">
      <div className="title">
        <h2
          style={{ padding: "5px", cursor: "pointer" }}
          onClick={() => {
            setOpenModal(false);
          }}
        >
          x
        </h2>

        <>
          <div className="perfilModal">
            <div style={{ alignItems: "center" }}>
              <img
                alt={LNG.alt_profile}
                style={{ width: "125px", height: "125px" }}
                src={perfil}
              />
            </div>
          </div>
          <div style={{ marginBottom: "65px" }} className="conteudoModal">
            <h2>{LNG.title_modal}</h2>
            <h3>José Carlos - Volvo FH540 </h3>
            <h3>{LNG.txt_modal_color} Vermelho - Placa final: ***-4554</h3>
            <img alt="qrcode" src={qrcode} />
            <h4>FG876-86JUY</h4>
          </div>
        </>
      </div>
      <div className="btn-Modal">
        <ButtonDefault
          img={compartilhar}
          style={{ height: "53px", alignItems: "center", padding: "10px" }}
        >
          {LNG.button_share}
        </ButtonDefault>
      </div>
    </div>
  );
}

export default Modal;
