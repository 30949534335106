import React, {useLayoutEffect, useState, useContext} from "react";
// import withRouter from "../../components/withRouter";
import NavBar from "../../components/Navbar";
import {useNavigate } from "react-router-dom";
import Tooltip from "rc-tooltip";
import { Context } from "../../Context/ContextGlobal";


// CSS
import '../OrderResume/tooltip-white.css';
import './style.css'
import '../../components/OrderStatus/style.css'

// Assets
import plusIcon from '../../assets/productsAdd/adiciona.svg'
import boxIcon from '../../assets/main/caixa.svg'
import logoIcon from "../../assets/home/logo-ecarreto.svg";
import timer from "../../assets/global/timer.svg";
import timerLate from "../../assets/global/timer-late.svg";


// Internal imports
import ButtonDefault from '../../components/ButtonDefault'
import links from "../../config/Links";
import MapContainer from "../../components/MapContainer";
import LineGraphic from "../../components/LineGraphic";
import Ellipsis from "../../components/Ellipsis";
import OrderViewStatus from "../../components/OrderViewStatus";


import Order from "../../Api/Order";
import { useLoading } from "../../components/Loading";
import { errorToaster, ToastContainer } from "../../components/Toasters";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";


export default function Dashboard() {

  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]


  const arrStatus  =   [];
  arrStatus[0]     =   LNG.txt_new_order;
  arrStatus[1]     =   LNG.txt_confirmed_order;
  arrStatus[10]    =   LNG.txt_taking_order;
  arrStatus[20]    =   LNG.txt_on_delivery;
  arrStatus[30]    =   LNG.txt_canceled_order;
  arrStatus[40]    =   LNG.txt_postponed_order;
  arrStatus[50]    =   LNG.txt_finish_order;
  arrStatus[100]   =   LNG.txt_budget_order;
  arrStatus[1000]  =   LNG.txt_undefined_order;
  const order = new Order();
  const DEBUG = false;
  // Navigation
  const history = useNavigate();
  const handleValidateData = () => {
    // Validation passed
    history(links.main, {replace: true});
  }
  const { setLoading } = useLoading();


  const [orders, setOrders] = useState([])

  // Order

  const handleOrdersSaveCallback = (data) => {
    setOrders(data);
    setLoading(false)
  }

  const _handleGetOrders = async () => {
    try {
      order.exec("POST", `order/list/`).then((response) => {
        if(DEBUG){
          console.log('data received', response)
        }
        
        const data = response.rows || [];

        handleOrdersSaveCallback(data)
  
      }).catch((err) => {
        setLoading(false)
        errorToaster(LNG.toaster_error_order_request)
      })

    }catch(err) {
      setLoading(false)
      errorToaster(LNG.toaster_error_order_request)
    }
  }

  useLayoutEffect(() => {
    setLoading(true)
    _handleGetOrders()
  }, [])
  

  
  return (
    <>
      <ToastContainer/>
      <div className="container-principal-Dashboard">
        <NavBar />
        <div className="container-dashboard-central">
          <div className="container">

            <div className="container-header-dashboard">
              <h1>{LNG.title_dashboard}</h1>
              <ButtonDefault
                img={plusIcon}
                to={links.main}
                onClick={() => { handleValidateData() }}
              >
                {LNG.button_make_order}
              </ButtonDefault>
            </div>

            {/* Container Map Section */}
            <div className="icon-caixa-dashboard-table">
              <div className="table-container-header-dashboard">
                <div>
                  <img alt={LNG.alt_box} className="img-box-dashboard-table" src={boxIcon} />
                </div>
                <h2>{LNG.subtitle_dashboard_delivery}</h2>
              </div>
              <div>
                <div>
                  <table
                    style={{overflow: "auto", maxHeight: "200px"}}
                    className="table table-bordered"
                    id="dataTable"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th align="center">{LNG.txt_dashboard_table_code}</th>
                        <th align="left">{LNG.txt_dashboard_table_client}</th>
                        <th align="left">
                          {LNG.txt_dashboard_table_worker}
                        </th>
                        <th align="center">
                          {LNG.txt_dashboard_table_status}
                        </th>
                        {/* <th>&nbsp;</th> */}

                      </tr>
                    </thead>
                    <tbody>

                      {orders.length ? orders.map((item, index) => {
                        const { order, user } = item
                        return (
                          <tr key={index} className="dashboard-order-item" onClick={() => history(links.orderView, {state: {item}})}>
                          <td align="center">{order.id}</td>
                          <td>{user.name}</td>
                          <td className="dashboard-table-timer-cell">
                            {item?.worker?.name || LNG.txt_not_distributed}
                            <Tooltip placement="top" trigger={['hover', 'click']} overlay="
                              08mins
                            " >
    
                              <img alt={LNG.alt_cronometer} src={timer} />
                            </Tooltip>
    
                          </td>
                          <td
                            align="center"
                            className={`status-${order?.status}`}
                          >
                            {arrStatus[order?.status]}
                          </td>
                          {/* <td align="center">
                            <ButtonViewOrder text="Visualizar" link={links.orderView} />
                          </td> */}
    
                        </tr>
                        )
                      }) : <></>}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Container Map Section */}
            <div className="icon-caixa-dashboard-map">
              <div className="map-container-header-dashboard">
                <img alt={LNG.alt_box} className="img-box-dashboard" src={boxIcon} />
                <h2>{LNG.subtitle_dashboard_map}</h2>
              </div>
              <div>
                <MapContainer
                  type="dashboard"
                  containerStyle={{
                    width: "100vw",
                    height: "430px"
                  }} />
              </div>
            </div>


            <div className="icon-caixa-dashboard">
              <div className="icon-caixa-graph-header">
                <img alt={LNG.alt_box} className="img-box-dashboard" src={boxIcon} />
                <h2>{LNG.subtitle_dashboard_graphic}</h2>
              </div>
              <div>
                <LineGraphic />
              </div>
            </div>

            <div className="logo-img-container">
              <img className="logo-img" alt={LNG.alt_logo} src={business?.logo_url} />
            </div>

          </div>

        </div>
      </div>
    </>
  )
}

