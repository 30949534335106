import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

import "./style.css";

export default function InputForm({ name, errorInput,  ...rest}) {

  // Trow error if not found name
  if (!name || name === "" || name === undefined) 
    throw new Error("Prop 'name' is required in InputForm component");
  

  try{
    const inputRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);
    useEffect(() => {
      registerField({
        name: fieldName,
        ref: inputRef,
        getValue: (ref) => {
          return ref.current.value;
        },
        setValue: (ref, value) => {
          ref.current.value = value;
        },
        clearValue: (ref) => {
          ref.current.value = "";
        },
      });
    }, [fieldName, registerField]);

    return (
      <div className={!errorInput ? "inputForm": "inputForm-error"}>
        {error && <span className="inputError">{error}</span>}
        <input  ref={inputRef} defaultValue={defaultValue} {...rest} />
      </div>
    );
  } catch (err) {
    throw new Error(err);
  }
}
