import { useContext } from "react";
import chat from "../../assets/main/icon-chat.svg";

// CSS
import "./style.css";
import { Context } from "../../Context/ContextGlobal";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";

// TRANSLATION

export default function HelpButton( props ){

  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]
  return(
      <button className="btn-ajuda" onClick={ () => window["chatSupport"]() }>
        <img alt={LNG.alt_chat} src={chat} />
        <h4>{ props.label ||  LNG.button_help}</h4>
      </button>
  );
}