import React, { useContext } from 'react'
import "./style.css";

// assets
import printIcon from '../../assets/global/iconPrint.svg'
import { getLanguageByTimezone, getLocaleLanguage } from '../../lang/utils';
import { Context } from '../../Context/ContextGlobal';

// TRANSLATION

function ButtonPrint(props) {

  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

    const defaultProps = {
      ...props,
      className: "btnPrint" + ( props.className || "" ),
    };

    return (
      <button {...defaultProps}>
        {props.children}
        <div>
          <img className="print-button-icon" alt={LNG.alt_print} src={printIcon} />
        </div>
      </button>
    );
}

export default ButtonPrint;