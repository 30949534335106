import mixpanel from 'mixpanel-browser';

export function MP( trackLabel = "", trackObjects = {} ){
  
  // Debug
  const DEBUG = false;
  
  if( DEBUG ) console.log("Mixpanel","start");
  try{
    // Key - Enabling the debug mode flag is useful during implementation,
    mixpanel.init('d613e4b9fedcc3b13a7114ff8b210a16', {debug: false});
    if( DEBUG ) console.log("Mixpanel","token set"); 
    
    // Track
    if( !trackLabel ) return;
    if( DEBUG ) console.log("Mixpanel","trackLabel",trackLabel || ""); 
    if( DEBUG ) console.log("Mixpanel","trackObjects",trackObjects || {}); 

    let beta = false; 
    try{
      beta = window.location.href.includes("appbeta.");
    } catch(er){}

    // Add track
    // mixpanel.track('Sign Up', {
    //   'source': "Pat's affiliate site",
    //   'Opted out of email': true,
    // });
    mixpanel.track( ( beta ? "Beta > " : "" ) + trackLabel, trackObjects || {});  
    if( DEBUG ) console.log("Mixpanel","track sended !");
  } catch( err ){
    // Error
    if( DEBUG ) console.log( "Mixpanel error:",err );
  }
  if( DEBUG ) console.log("Mixpanel","end");
}