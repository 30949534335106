import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";

// Data analysis
import { MP } from "../../components/MixPanel";
// Loading
import { useLoading } from "../../components/Loading";

// Css
import "./style.css";

// Assets
import loader from "../../assets/global/icon-loading-black.svg";

// Local components
import NavBar from "../../components/Navbar";
import ButtonReturn from "../../components/ButtonReturn";
import OrderStatus from "../../components/OrderStatus";

// Routes
import links from "../../config/Links";
import Order from "../../Api/Order";
import { handleNewDteFormat } from '../../Helper/Utils'
import { Context } from "../../Context/ContextGlobal";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";

// Tour context import
// import { ShepherdTourContext } from "react-shepherd";

// TRANSLATION
// import LNG from "../../lang/en-us.json"

export default function Tracking() {

  const { business } = useContext(Context);
  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

  // MP
  MP("Tracking");

  // const tour = useContext(ShepherdTourContext)

  const DEBUG = false

  // Loading start
  const { setLoading } = useLoading();
  // setLoading(true);

  // Infinite scroll  states
  const [items, setItems] = useState([]);
  const [hasMore, sethasMore] = useState(true);
  const [page, setpage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  // Order
  const order = new Order();

  useEffect(() => {

    //  // Tour controller
    //  if (localStorage.hasOwnProperty("pagesTourSeen")){
    //     if(!JSON.parse(localStorage.getItem("pagesTourSeen")).tracking) {
    //       const restProps = JSON.parse(localStorage.getItem('pagesTourSeen'))
    //       localStorage.setItem('pagesTourSeen', JSON.stringify({
    //       ...restProps,
    //       tracking: true,
    //       }))
    //       tour.start()
    //     }
    //   }else {
    //     tour.start()
    //   }

    setLoading(true);
    const handleGetOrders = async () => {
      localStorage.setItem("page", JSON.stringify(2));

      order.exec("POST", `order/list/?page=1`, {}).then((response) => {
        if (DEBUG) {
          console.log("REPOSTA ORDERS: ", response)
          console.log("REPOSTA ORDERS ROW: ", response.rows)
        }
        if (!response) {
          setItems(false);
          return;
        }

        // Set itens
        if (response.rows) setItems(response.rows);
        // Set total page
        setTotalPages(response.total_pages);
        // Loading end
        setLoading(false);
      });
    };

    handleGetOrders();

    // Top page
    try { window.scrollTo(0, 0); } catch (err) {}
        
  }, []);

  const fetchData = async (realPage) => {
    let page
    if (localStorage.hasOwnProperty("page")) {
     page = (JSON.parse(localStorage.getItem("page")));
    }

    // User 
    const dadosUsuario = JSON.parse(localStorage.getItem("token"));
    const { user : { id : userId } } = dadosUsuario;

    order.exec("POST", `order/list/?page=${page}`, {id_user : userId}).then((response) => {
      if(DEBUG){
        console.log('data received', response)
        console.log("page", page)
        console.log("total pages", totalPages)
      }
      
      const data = response.rows || [];
      setItems([...items, ...data]);

      setpage(page)
    }).then(() => {
      localStorage.setItem("page", JSON.stringify(page + 1));
    })
  };

  return (
    <div className="container-principal">
      <NavBar />
      <div className="container">
      <ButtonReturn to={links.dashboard} />
        <div className="container-Tracking">
          {/* {console.log(items)} */}
          <h1>{LNG.title_tracking}</h1>
          {
            (items.length && items !== undefined && items.empty !== true) ?
              (
                <InfiniteScroll
                  loadMore={fetchData}
                  datalength={items.length}
                  hasMore={page >= totalPages ? false : true}
                  loader={
                    <div className="tracking-loader" key={0}>
                      <div>
                        <img alt={LNG.alt_loading} src={loader} />
                      </div>
                    </div>
                  }
                >
                  {!items.length ? ""
                    : (
                      <>
                        {items.map((item, index) => {
                          // When order doesn't exist
                          if (item.order === undefined) {
                            return <div>{LNG.txt_tracking_not_found}</div>;
                          } else {
                            return item ? (
                              <Link key={index} to={{
                                pathname: links.orderView
                              }}
                                state={{ item: item }}
                                style={{ textDecoration: "none" }}>
                                <div className="tracking-transporte tour-tracking-item">
                                  {/* {console.log(item)} */}
                                  <div className="infoTracking">
                                    <div className="posicionamentoinfoTracking">
                                      <div className="dadosTracking tour-tracking-info">
                                        <h1>{item.order.serial}</h1>
                                        <h2>
                                          {handleNewDteFormat(item.order.dte_delivery)}
                                        </h2>
                                        {item.financial && <div>{LNG.txt_payments_table_value}: {item.financial.pricef}</div>}
                                        {item.worker &&
                                          <div>
                                            <div>{LNG.txt_tracking_map_worker} {item.worker.name}</div>
                                            {/* <div>{item.worker.vehicle_brand} - {item.worker.vehicle_model} - {item.worker.vehicle_color}</div> */}
                                            {/* <div>Placa: {item.worker.vehicle_id}</div> */}
                                          </div>
                                        }
                                      </div>
                                      <div className="perfilTracking">
                                        <div
                                          className="coletaTracking-img"
                                        >
                                          <div className="coletaTracking-status tour-tracking-status">
                                            <OrderStatus status={item?.order?.status} order={item?.order} />
                                          </div>
                                        </div>
                                        <div className="tour-tracking-worker-profile">
                                          {item.worker && item.worker.avatar_url ? (
                                            <img
                                              className="perfilTracking-img "
                                              alt={LNG.alt_profile}
                                              src={item.worker.avatar_url}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                        
                                        {/* {item.worker && (
                                        <div className="avaliacaoTracking">
                                          <div className="super-star-tracking">
                                            <img alt="superStar" src={superStar} />
                                          </div>
                                          <div className="nota-tracking">
                                            <img
                                              alt="estrela"
                                              className="estrela"
                                              src={estrela}
                                            />
                                            {item.worker ? (
                                              <span>
                                                {console.log(item)}
                                                {item.worker.review || 1}
                                              </span>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      )} */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            ) : (
                              //when there are no items return:
                              <></>
                            );
                          }
                        })}
                      </>
                    )}
                </InfiniteScroll>
              ) : (
                <div>
                  <h2>{LNG.txt_tracking_no_orders}</h2>
                </div>
              )
          }
        </div>
      </div>
    </div>
  );
}