import {
  Route,
  Routes,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";

// Shepherd imports
import "./shepherd.css";
import { tourOptions } from "./components/Tour/utils/options"
import { ShepherdTour, } from "react-shepherd";
// Pages imports
import Home from "./pages/Home";
import NotFound from "./pages/404"
import Dashboard from "./pages/Dashboard";
import Main from "./pages/Main";
import Clients from "./pages/Clients";
import Reports from "./pages/Reports";
import LegalInfoPage from "./pages/LegalInfoPage";
import OrderResume from "./pages/OrderResume";
import MapFrom from "./pages/Map/MapFrom";
import MapTo from "./pages/Map/MapTo";
import ProductsAdd from "./pages/ProductsAdd";
import Payments from "./pages/Payments";
import OrderContact from "./pages/OrderContact";
import UserData from "./pages/UserData";
import SearchingDriver from "./pages/SearchingDriver";
import TrackingMap from "./pages/TrackingMap";
// import AccountScreen from "./pages/AccountScreen";
import OrderView from "./pages/OrderView";
import Tracking from "./pages/Tracking";
import LostPass from "./pages/LostPass";
import Settings from "./pages/Settings";
import Faq from "./pages/Faq";
import RecoveryPass from "./pages/RecoveryPass";
import DeliveryProfile from "./pages/DeliveryType";
import OrderSimulator from "./pages/OrderSimulator";

// Steps imports
import { deliveryProfile } from "./components/Tour/Steps/DeliveryProfile";
import { main } from "./components/Tour/Steps/Main";
import { clients } from "./components/Tour/Steps/Clients";
import { orderResume } from "./components/Tour/Steps/OrderResume"
import { map } from "./components/Tour/Steps/Map"
import { productAdd } from "./components/Tour/Steps/ProductAdd";
import { userData } from "./components/Tour/Steps/UserData";
import { faq } from "./components/Tour/Steps/FAQ";
import { tracking } from "./components/Tour/Steps/Tracking";
import { searchingDriver } from "./components/Tour/Steps/SearchingDriver";
import { legalInfoPage } from "./components/Tour/Steps/LegaInfoPage";
import { trackingMap } from "./components/Tour/Steps/TrackingMap";
import { accountScreen } from "./components/Tour/Steps/AccountScreen";
import { lostPass } from "./components/Tour/Steps/LostPass";
import { orderContact } from "./components/Tour/Steps/OrderContact";

// Main
export default function RoutesComponent() {
  // Getting token
  // TODO: execute a logic to validate token
  const PrivateRoute = ({ children }) => {
    const authed = localStorage.getItem("token");
    return authed ? children : <Navigate to={"/"} />
  }

  // Mounting and disposing all steps data
  const arraySteps = {
    'tipo-entrega': deliveryProfile || [],
    'principal': main || [],
    'clientes': clients || [],
    'resumo-pedido': orderResume || [],
    'onde-vem': map || [],
    'onde-vai': map || [],
    'adicionar-produto': productAdd || [],
    'dados-usuario': userData || [],
    'faq': faq || [],
    'Tracking': tracking || [],
    'encontre-motorista': searchingDriver || [],
    'LegalInfoPage': legalInfoPage || [],
    'pedido': trackingMap || [],
    'senha-perdida': lostPass || [],
    'criar-conta': accountScreen || [],
    'pedido-pagamento': orderContact || []
  }

  return (
    <Router>
      <Routes>
        {/* <Route path="/criar-conta" element={AccountScreen} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/senha-recuperacao" element={<RecoveryPass />} />
        <Route path="/senha-recuperacao-transportador" element={<RecoveryPass />} />
        <Route path="/senha-perdida" element={<LostPass />} />
        <Route path="/dashboard" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["tipo-entrega"] || []} tourOptions={tourOptions || {}} >
              <Dashboard />
            </ShepherdTour>
          </PrivateRoute>
        } />
        <Route path="/tipo-entrega" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["tipo-entrega"] || []} tourOptions={tourOptions || {}} >
              <DeliveryProfile />
            </ShepherdTour>
          </PrivateRoute>
        } />
        <Route path="/principal" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["principal"] || []} tourOptions={tourOptions || {}} >
              <OrderSimulator/>
            </ShepherdTour>
          </PrivateRoute>
        } />
        <Route path="/relatorios" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["relatorios"] || []} tourOptions={tourOptions || {}} >
              <Reports />
            </ShepherdTour>
          </PrivateRoute>
        } />
        <Route path="/resumo-pedido" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["resumo-pedido"] || []} tourOptions={tourOptions || {}} >
              <OrderResume />
            </ShepherdTour>
          </PrivateRoute>
        } />
        <Route path="/clientes" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["clientes"] || []} tourOptions={tourOptions || {}} >
              <Clients />
            </ShepherdTour>
          </PrivateRoute>
        } />        
        <Route path="/pedido-pagamento" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["resumo-pedido"] || []} tourOptions={tourOptions || {}} >
              <OrderContact />
            </ShepherdTour>

          </PrivateRoute>
        } />
        <Route path="/informacao-legal" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["resumo-pedido"] || []} tourOptions={tourOptions || {}}>
              <LegalInfoPage />
            </ShepherdTour>
          </PrivateRoute>
        } />
        <Route path="/onde-vem" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["onde-vem"] || []} tourOptions={tourOptions || {}} >
              <MapFrom />
            </ShepherdTour>
          </PrivateRoute>
        } />
        <Route path="/onde-vai" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["onde-vai"] || []} tourOptions={tourOptions || {}} >
              <MapTo />
            </ShepherdTour>
          </PrivateRoute>
        } />
        <Route path="/adicionar-produto" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["adicionar-produto"] || []} tourOptions={tourOptions || {}} >
              <ProductsAdd />
            </ShepherdTour>
          </PrivateRoute>
        } />
        <Route path="/pagamento" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["onde-vem"] || []} tourOptions={tourOptions || {}} >
              <Payments />
            </ShepherdTour>
          </PrivateRoute>
        } />
        <Route path="/dados-usuario" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["dados-usuario"] || []} tourOptions={tourOptions || {}} >
              <UserData />
            </ShepherdTour>

          </PrivateRoute>
        } />
        <Route path="/encontre-motorista" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["encontre-motorista"] || []} tourOptions={tourOptions || {}} >
              <SearchingDriver />
            </ShepherdTour>

          </PrivateRoute>
        } />
        <Route path="/pedido" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["pedido"] || []} tourOptions={tourOptions || {}} >
              <OrderView />
            </ShepherdTour>
          </PrivateRoute>
        } />
        <Route path="/rastreio-pedido" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["restreio-pedido"] || []} tourOptions={tourOptions || {}} >
              <TrackingMap />
            </ShepherdTour>
          </PrivateRoute>
        } />
        <Route path="/pedidos" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["pedidos"] || []} tourOptions={tourOptions || {}} >
              <Tracking />
            </ShepherdTour>
          </PrivateRoute>
        } />
        <Route path="/pedido" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["pedido"] || []} tourOptions={tourOptions || {}} >
              <OrderView />
            </ShepherdTour>
          </PrivateRoute>
        } />
        <Route path="/configuracoes" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["configuracoes"] || []} tourOptions={tourOptions || {}} >
              <Settings />
            </ShepherdTour>
          </PrivateRoute>
        } />
        <Route path="/faq" element={
          <PrivateRoute >
            <ShepherdTour steps={arraySteps["faq"] || []} tourOptions={tourOptions || {}} >
              <Faq />
            </ShepherdTour>
          </PrivateRoute>
        } />
        {/* On error default path */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router >
  );
}
