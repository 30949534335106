import ApiModel from "./ApiModel";

class Auth extends ApiModel {

  // Endpoint
  folder = "/user";

  constructor() {
    super();

    // User stored data
    const dadosUsuario = JSON.parse(localStorage.getItem("token"));

    if (dadosUsuario) {
      try {
        // User token
        const { token } = dadosUsuario;

        if (token) {
          // Set API user token
          this.api.defaults.headers.common["x-access-token"] = token;
        }
      } catch (err) {
        // Without token. Not authenticated
        this.api.defaults.headers.common["x-access-token"] = null;
      }
    } else {
      // Without token. Not authenticated
      this.api.defaults.headers.common["x-access-token"] = null;
    }
  }

  update(obj) {
    const url = "/update";
    return this.api
      .patch(this.folder + url, obj)
      .then(this.getData)
      .catch(this.onError);
  }

  add(obj) {
    const url = "/add";
    return this.api
      .put(this.folder + url, obj)
      .then(this.getData)
      .catch(this.onError);
  }

  login(obj) {
    const url = "/login";
    return this.api
      .post(this.folder + url, obj)
      .then(this.getData)
      .catch(this.onError);
  }

  me(obj) {
    const url = "/me";
    return this.api
      .get(this.folder + url, obj)
      .then(this.getData)
      .catch(this.onError);
  }

  resetPassword(obj) {
    const url = "/reset-password";
    return this.api
      .post(this.folder + url, obj)
      .then(this.getData)
      .catch(this.onError);
  }

  lostPass(obj) {
    const url = "/lostpass";
    return this.api
      .post(this.folder + url, obj)
      .then(this.getData)
      .catch(this.onError);
  }

  updatePass(obj) {
    const url = "/recoverypass";
    return this.api
      .post(this.folder + url, obj)
      .then(this.getData)
      .catch(this.onError);
  }
  updatePassWorker(obj) {
    const url = "/recoverypass";
    return this.api
      .post("/worker" + url, obj)
      .then(this.getData)
      .catch(this.onError);
  }

  logout(obj) {
    const url = "/logout";
    return this.api
      .post(this.folder + url, obj)
      .then(this.getData)
      .catch(this.onError);
  }
}

export default Auth;
