import ApiModel from "./ApiModel";

class Product extends ApiModel {

  // Endpoint
  folder = "/product";

  constructor() {
    super();

    // User stored data
    const dadosUsuario = JSON.parse(localStorage.getItem("token"));

    if (dadosUsuario) {
      try {
        // User token
        const { token } = dadosUsuario;

        if (token) {
          // Set API user token
          this.api.defaults.headers.common["x-access-token"] = token;
        }
      } catch (err) {
        // Without token. Not authenticated
        this.api.defaults.headers.common["x-access-token"] = null;
      }
    } else {
      // Without token. Not authenticated
      this.api.defaults.headers.common["x-access-token"] = null;
    }
  }


  // exec(obj, url) {
  //   return this.api
  //     .post(this.folder + url, { params : {
  //       name: 'Geladeira',
  //       asin: '',
  //       keyword: '',
  //       brand: ''
  //     } })
  //     .then(this.getData)
  //     .catch(this.onError);
  // } 

}

export default Product;
