export const accountScreen = [
  {
    id: 'tour-description-account-page',
    title: '<strong>Criação da conta</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
        Esta é a etapa de Cadastro,
         aqui é possível criar uma conta ao inserir seus dados no formulário.
        `
    ],

    attachTo: { element: '.tour-description-account-page', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-account-fields',
    title: '<strong>Dados para criação de conta</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
        Será necessário inserir dados como nome completo, cpf, telefone, celular, 
        e-mail e uma senha válida, para prosseguir com a criação da conta.
        `
    ],

    attachTo: { element: '.tour-account-fields', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-account-finish',
    title: '<strong>Finalizar criação da conta/strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
      Ao clicar neste botão validaremos o seu cadastro imediatamente
       e liberando acesso a nossa plataforma.
      `
    ],

    attachTo: { element: '.tour-account-finish', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'cancel',
        text: 'Entendi'
      }
    ]
  },
]