import "./style.css";

export default function Select(props) {
  const defaultProps = {
    ...props,
    className: " select-component-input " + (props.className ? props.className : ""),
  };

  return (
    <select {...defaultProps} >
      {props.options.map((item) => {
        return (
          <option>{item}</option>
        )
      })}
    </select>
  );
}