export const trackingMap = [
  {
    id: 'tour-description-tracking-map-page',
    title: '<strong>Sobre os dados do Pedido</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
      Esta é a etapa de Visualização de pedido, aqui é possível conferir os dados do
       pedido selecionado na sessão anterior.
      `
    ],

    attachTo: { element: 'tour-description-tracking-page', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-tracking-map-item',
    title: '<strong>Informações importantes</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
       Nesta sessão é possível visualizar dados vitais do transporte como: informações de entrega,
        preço do carreto e informações do transportador
      `
    ],

    attachTo: { element: '.tour-tracking-map-item', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },

  {
    id: 'tour-tracking-map-actions',
    title: '<strong>Pagando ou refazendo pedido</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
        Campo destinado as ações de pedido, sendo elas: refazer um pedido, caso o mesmo já tenha sido finalizado
         e realizar o pagamento de um pedido, caso o mesmo tenha falhado anteriormente.
      `
    ],

    attachTo: { element: '.tour-tracking-map-actions', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-tracking-map-logs',
    title: '<strong>Histórico do pedido</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
       Ao clicar neste botão é possível visualizar todos os eventos relacionados a esse pedido,
        bem como a data e hora desse evento.
      `
    ],

    attachTo: { element: '.tour-tracking-map-logs', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'cancel',
        text: 'Entendi'
      }
    ]
  },
]