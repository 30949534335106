import React from "react";
import { handleNewDteFormat } from '../../../Helper/Utils'

const Step = ({ currentIndex, logs }) => {
  
  return (
    <div className="steps-container">
      {console.log(logs)}
      {logs.map((step, index) => {
        let color = index === logs.length -1 ? "#ff5e00" : "#838383";
        let border = index === logs.length -1 ? "#ff5e00" : "#838383";
        return (
          <div className="steps-item" key={index}>

            {/* {console.log("esse é o index", index)}
            {console.log("esses é o array", logs.length)} */}

            <h3
              style={{
                color: color,
                borderLeft: `5px solid ${border}`,
                paddingLeft: "10px"
              }}>
              <div className="steps-item-date">
                {handleNewDteFormat(step.createdAt)}
              </div>
              <div>
                {step.log_br}
              </div>
            </h3>
          </div>
        );
      })}
    </div>
  );
};

export default Step;
