import React from "react";
import "./style.css";


function ModalTour({ setOpenModal }, ...props) {

  const handleChangeAllTours = (param) => {

    // tour and modal must be inverted in order to set the switch correctly
    const tourPageProps = {
      tour: !param,
      modal_show: !param,
      delivery_type:param,
      main:param,
      order_resume:param,
      order_contact:param,
      user_data:param,
      map:param,
      account_screen: param,
      searching_driver: param,
      lost_pass: param,
      product_add: param,
      tracking: param,
      tracking_map: param
    }

    if (localStorage.hasOwnProperty("pagesTourSeen")){
      const restProps = JSON.parse(localStorage.getItem('pagesTourSeen'))
      localStorage.setItem('pagesTourSeen', JSON.stringify({
        ...restProps,
        ...tourPageProps
      }))
    } else {
      localStorage.setItem('pagesTourSeen', JSON.stringify({
        ...tourPageProps
      }))
    }
  }

  return (
    <div className="backgroundModal-TourModal ">
      <div className="title-TourModal">
        <div className="conteudoModal-Tour">
          <h2>Gostaria de ser instruído em cada etapa de sua solicitação de transporte ?</h2>
          <div className="actions-TourModal">
            <button
                className="cancel-button-TourModal"
              onClick={() => {
                setOpenModal(false);
                handleChangeAllTours(true)
              }}
              >
              Não, obrigado!
            </button>
            <button
              className="buttonModal-Tour"
              onClick={() => {
                setOpenModal(false);
                handleChangeAllTours(false)
              }}
            >
              Sim, claro!
            </button>
          </div>

        </div>
      </div>
    </div>
  );
}

export default ModalTour;
