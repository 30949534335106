const variables = {
  production: {
    GOOGLE_MAPS_APIKEY: "AIzaSyDd0YergrJ0gmS-QbNZ9P8edtFdPopdx1g",
    ECARRETO_API_URL  : "https://api.ecarreto.com.br", // "http://localhost:3000"
    VERCEL_FORCE_NO_BUILD_CACHE: 1
  },
};

const getEnvVariables = () => {
  return variables.production;
};

export default getEnvVariables;
