export const main = [
  {
    id: 'tour-main-presentation',
    title: '<strong>Detalhes do transporte</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
      Nesta etapa você irá selecionar informações sobre seu transporte como local de coleta, local de entrega e os itens a serem transportados.
      `
    ],
    attachTo: { element: '', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-main-return-button',
    title: '<strong>Voltar</strong>',
    canClickTarget: false,
    scrollTo: true,
    text:
        `
        A seta em destaque permite voltar para etapas anteriores a qualquer momento e está presente durante toda jornada.
        `,
    attachTo: { element: '.tour-main-return-button', on: 'bottom-start' },
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-main-delivery-date-map',
    title: '<strong>Data de coleta</strong>',
    canClickTarget: false,
    scrollTo: true,
    text:
        `
        Aqui você deve selecionar a Data e Hora de coleta dos itens de transporte.
        `,
    attachTo: { element: '.tour-main-delivery-date-map', on: 'bottom' },
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-main-address-from-map',
    title: '<strong>Local de coleta</strong>',
    text: [
      `
      Neste campo você deve informar o endereço completo de coleta dos itens de transporte.
       `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.tour-main-address-from-map', on: 'bottom' },
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-main-address-to-map',
    title: '<strong>Local de entrega</strong>',
    id: 'main-search-product',    
    attachTo: {
      element: '.tour-main-address-to-map',
      on: 'bottom'
    },
    // classes: 'custom-class-name-1 custom-class-name-2',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
      Da mesma forma, neste campo você deve informar o endereço completo de entrega dos itens de transporte.
       `
    ],
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },
  {

    id: 'tour-main-search-product',
    title: '<strong>Itens ou pacote de transporte</strong>',
    attachTo: {
      element: '.tour-main-search-product',
      on: 'top'
    },
    canClickTarget: false,
    scrollTo: true,
    // classes: 'custom-class-name-1 custom-class-name-2',
    text: [
      `
      Escolha aqui os itens ou pacote de mudança que iremos transportar.
      `
    ],
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },
  {
    id: 'tour-main-help-button',
    title: '<strong>Precisa de ajuda?</strong>',    
    attachTo: {
      element: '.tour-main-help-button',
      on: 'bottom'
    },
    canClickTarget: false,
    scrollTo: true,
    // classes: 'custom-class-name-1 custom-class-name-2',
    text: [
      `
      Está com dificuldades ou não encontrou o produto desejado ? Entre em contato a qualquer momento com nosso time de suporte. Será um prazer te ajudar.
      `
    ],
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },

  {
    id: 'tour-main-foward-button',
    title: '<strong>Próxima etapa</strong>',      
    attachTo: {
      element: '.tour-main-foward-button',
      on: 'bottom'
    },
    canClickTarget: false,
    scrollTo: true,
    // classes: 'custom-class-name-1 custom-class-name-2',
    text: [
      `
      Clique no botão Continuar para avançar para a próxima etapa onde você irá revisar sua solicitação de transporte.
      `
    ],
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'cancel',
        text: 'Entendi!'
      }
    ],
    
  }
];
