export const lostPass = [
  {
    id: 'tour-description-lostpass-page',
    title: '<strong>Sobre a recuperação de senha</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
        Esta é a etapa de recuperação de senha, aqui é possivel receber um e-mail com o
        link para redefinir a sua senha.
        `
    ],

    attachTo: { element: '.tour-description-userdata-page', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-lostpass-email',
    title: '<strong>Campo e-mail</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
        Neste campo é necessário digitar seu E-mail, 
        para prosseguir com a recuperação da conta.
        `
    ],

    attachTo: { element: '.tour-lostpass-email', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-lostpass-cpf',
    title: '<strong>Campo CPF</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
      Neste campo é necessário digitar o CPF
      para prosseguir com a recuperação da conta.
      `
    ],

    attachTo: { element: '.tour-lostpass-cpf', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-lostpass-finish',
    title: '<strong>Enviar e-mail</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
      Ao clicar neste botão enviaremos um e-mail para o titular da conta
       com o link de acesso para a troca de senha. Caso a dificuldade persista,
        contate nossa equipe.
      `
    ],

    attachTo: { element: '.tour-lostpass-finish', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'cancel',
        text: 'Entendi'
      }
    ]
  },
]