// Css
import "./style.css";

// Local components
import Navbar from "../../components/Navbar";
import ButtonReturn from "../../components/ButtonReturn";
import Webcontent from "../../components/WebContent";

// Routes
import {useNavigate } from "react-router-dom";

// TRANSLATION
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";
import { useContext } from "react";
import { Context } from "../../Context/ContextGlobal";

export default function Faq() {

  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

  const history = useNavigate()
  return (
    <div className="container-principal">
      <Navbar />
      <ButtonReturn onClick={() => {history(-1, { replace: true })  }}/>
      <div className="container">
        <div className="container-LegalInfoPage">
          <h1>{LNG.title_faq}</h1>
          <Webcontent url={LNG.link_faq} title={LNG.title_legal_information}/>
        </div>
      </div>
    </div>
  );
}
