import React, { useEffect, useState, useContext} from 'react'
import {useNavigate } from 'react-router-dom'
import { useLocation, useParams } from "react-router-dom";
import { Context } from "../../Context/ContextGlobal";


// CSS
import './print.css'
import './style.css'

// Internal imports
import ButtonReturn from '../../components/ButtonReturn'
import NavBar from '../../components/Navbar'
import ButtonFollow from '../../components/ButtonFollow'
import ButtonPrint from '../../components/ButtonPrint'
import OrderViewStatus from '../../components/OrderViewStatus'

// Assets
import qrcode from "../../assets/searchingDriver/qrcode.svg";
import logo from "../../assets/home/logo-ecarreto.svg";
import whatsappIcon from "../../assets/orderView/whatsapp.svg"
import phoneIcon from "../../assets/orderView/phone.svg"

// Links
import links from '../../config/Links'

// TRANSLATION
import LNG from "../../lang/en-us.json"
import { handleNewDteFormat } from '../../Helper/Utils';
import Order from '../../Api/Order';
import { useLoading } from '../../components/Loading';
import { errorToaster, ToastContainer } from "../../components/Toasters";
import { getLanguageByTimezone, getLocaleLanguage } from '../../lang/utils';


export default function OrderView(props) {
  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

  const DEBUG = false;
  const location = useLocation();
  let item = (location.state?.item) || {};
  if(DEBUG) console.log(item)
  const [itemsList, setItemsList] = useState([])

  const { setLoading } = useLoading();

  const history = useNavigate()

  function handldeGoToTrackingMap() {
    history(links.trackingMap, {state: {item: item}})
  }


  const handleGetProductsInformation = async () => {
    setLoading(true)
    const apiUtil = new Order();
    let completeProductsArray = [];

    let max = item?.order?.products?.length + 1
    for (let i = 0; max > i; i++) {
      // let id = response.data.order.products[i].id_product
      apiUtil.exec("GET", `product/${item?.order?.products[i]?.id_product}`, {})
        .then((responseItem) => {
          // pushes the complete item to the array
          if(DEBUG)console.log(completeProductsArray)

            setItemsList(prevState => [...prevState, {
                  id: responseItem.id,
                  name: responseItem.name,
                  // quantity: item?.order?.products[i].,
                  width: responseItem.width,
                  height: responseItem.height,
                  weight: responseItem.weight,
                  lenght: responseItem.lenght
                }])
          setLoading(false)
          
        }).catch(err => {
          console.log(err)
          errorToaster(err)
          setLoading(false)
        })
    }

  }

  useEffect(() => {
      handleGetProductsInformation()
  }, [setLoading])

  return (
    <>
      <div className="container-main-OrderView " id='printContainer'>
        <NavBar />
        <div className="container-OrderView-central">
          <div className="container">
            <ButtonReturn onClick={() => history(-1, { replace: true })  } />
            <div className="container-header-OrderView">

              <h1>{LNG.title_orderview} #{item?.order?.id || "..."}</h1>

              {/* Printer component */}
              <ButtonFollow
                onClick={handldeGoToTrackingMap}
              >
                {LNG.button_follow}
              </ButtonFollow>
              <div className="container-header-OrderView-buttons">


              <OrderViewStatus status={item?.order?.status} />
              <ButtonPrint onClick={() => window.print()}/>

        
              </div>
 

              <div className="qrcode-section-OrderView">
                <img width={150}  alt={LNG.alt_icon_qrcode} src={item?.qrcode?.qrcode || "..."} />
                <div>
                  <span>{item?.order?.serial}</span>
                </div>
              </div>
            </div>
            <div className="icon-box-OrderView">

              {/* more info comes here */}
              <div className="icon-box-client">
                <div>
                  <h2>{LNG.txt_orderview_section_1_client}</h2>
                </div>
                <h1>{item?.user?.name}</h1>
                <div className='icon-box-cliente-info'>
                  <h2>
                    {item?.order?.local_from_address}, {item?.order?.local_from_number} - {item?.order?.local_from_district},  {item?.order?.local_from_city} - {item?.order?.local_from_state}, {item?.order?.local_from_country}
                  </h2>
                </div>
              </div>

              <div className="icon-box-worker">
                <div>
                  <h2>{LNG.txt_orderview_section_1_worker}</h2>
                </div>
                <h1>{item?.worker?.name || LNG.txt_not_distributed}</h1>
                <div className='icon-box-worker-info'>
                  <img className='orderview-phone-icon' alt={LNG.alt_icon_whatsapp} src={phoneIcon} />
                  <img className='whatsapp-icon' alt={LNG.alt_icon_cellphone} src={whatsappIcon} />
                  <h2>
                    <div className='order-view-phone-number'>
                      <span>({item?.worker?.cellphone.substr(0, 2) ||  "..."})</span>
                      {item?.worker?.cellphone.substr(2) || "..."}
                    </div>

                  </h2>
                </div>

              </div>
              <div className="icon-box-price">
                <div className="icon-box-price-info">
                  {LNG.txt_orderview_section_1_value}
                  <div className='icon-box-transport-price-total'>
                    <span>{LNG.txt_default_currency}</span>{item?.financial?.price}
                  </div>
                </div>

              </div>
            </div>

            <div className="container-content-products-OrderView">
              <h1>{LNG.subtitle_orderview_section_2}</h1>
              <div className="icon-box-OrderView">
                <div className='icon-box-products'>
                  {itemsList.length > 0 ?
                    <ul style={{ listStyle: "none", marginTop: "30px", overflow: "auto" }}>
                      {itemsList.map((item, index) => (
                        <li key={index}>
                          <div className="lista-item-OrderResume">
                            <div className="lista-item-OrderResume-input-number ">
                              {item.quantity || 1} <span>x</span>
                            </div>
                            <div className="lista-item-OrderResume-input-name" >{item.name}</div>
                          </div>
                          {item.observations ? (
                            <div className="lista-item-OrderResume-observations">
                              <span>{LNG.txt_order_resume_observations} {item.observations}</span>
                            </div>
                          ) : ""}
                        </li>
                      ))}
                    </ul> : <></>
                  }

                </div>
                {/* <div className="icon-box-products-price">
                  <div className="icon-box-products-price-info">
                    {LNG.txt_orderview_section_2_value}
                    <div className='icon-box-transport-price-total'>
                      <span>{LNG.txt_default_currency}</span>{item?.financial?.price}
                    </div>
                  </div>


                </div> */}
              </div>


            </div>

            <div className="container-content-history-OrderView">

              <h1>{LNG.subtitle_orderview_section_3}</h1>
              <div className="icon-box-OrderView">

                <div className='icon-box-logs'>
                  {/* Mapping in reverse */}
                  {[...item?.order?.logs || []].reverse().map(({createdAt, log_br})  => {
                    return (
                      <div className="icon-box-logs-item">
                        <h2>{handleNewDteFormat(createdAt)} - {log_br}</h2>
                      </div>
                    )
                  })}
                </div>

              </div>
            </div>
            <div className="logo-img-container">
              <img className="logo-img" alt={LNG.alt_logo} src={business?.logo_url} />
            </div>
          </div>
        </div>
      </div>

    </>
  )
}