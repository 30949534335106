export const tracking = [
  {
    id: 'tour-description-tracking-page',
    title: '<strong>Sobre a listagem de pedidos</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
      Esta é a etapa de Listagem de pedidos, aqui é possível conferir todos os
       pedidos de transportes realizados em sua conta.
      `
    ],

    attachTo: { element: 'tour-description-tracking-page', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-tracking-item',
    title: '<strong>Informações importantes</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
       Representação de um pedido na plaforma, aqui é possível ter uma visão de geral
        dos dados do pedido. Ao clicar podemos conferir mais detalhes do pedido.
      `
    ],

    attachTo: { element: '.tour-tracking-item', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },


  {
    id: 'tour-tracking-info',
    title: '<strong>Sobre o pedido de transporte</strong>',
    text: [
      `
       Sessão com informações relevantes sobre o pedido, tais como:
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.tour-tracking-info', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },

  {
    id: 'tour-tracking-status',
    title: '<strong>Sobre o pedido de transporte</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
       Campo destinado a exibição do status atual do pedido, sendo possível 
       saber se o pedido foi pago ou precisa de revisão no pagamento, 
       se já foi entregue ou está em coleta.
      `
    ],

    attachTo: { element: '.tour-tracking-status', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-tracking-worker-profile',
    title: '<strong>Foto do transportador</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
       Campos destinado a exibição da foto do transportador,
        facilitando a identificação do mesmo em momentos de coleta e entrega.
      `
    ],

    attachTo: { element: '.tour-tracking-worker-profile', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
]