import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Error Toaster
export const errorToaster = string => toast.error(string, {
  toastId: String(new Date().toDateString()),
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
});

// Success Toaster
export const successToaster = string => toast.success(string, {
    toastId: String(new Date().toDateString()),
    position: "top-center",
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });

// Warning Toaster
export const warningToaster = string => toast.warn(string, {
  toastId: String(new Date().toDateString()),
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
});

export {ToastContainer}
