export const userData = [
  {
    id: 'tour-description-userdata-page',
    title: '<strong>Sobre os dados do usuário</strong>',
    text: [
      `
      Esta é a etapa para Dados do usuário, aqui é possível: alterar sua foto de perfil,
       atualizar dados como o nome, cpf, telefone e a senha.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: 'tour-description-userdata-page', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-userdata-page-profile-photo',
    title: 'Ferramenta foto de perfil',
    text: [
      `
        Essa é a área de seleção de imagem para o seu perfil,
         ao adicionar uma imagem é possível recorta-la da maneira que preferir.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.tour-userdata-page-profile-photo', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-userdata-page-profile-fields',
    title: 'Dados do usuário',
    text: [
      `
        Sessão destinada a edição dos dados dos usuário, dados como:
         nome, cpf, telefone, email e senha podem ser inseridos e substituirão 
         os dados anteriores.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.tour-userdata-page-profile-fields', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'our-userdata-page-finish',
    title: 'Salvar alterações',
    text: [
      `
        Ao clicar no botão salvar validaremos os dados serão
        realizadas as alterações solicitadas.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.tour-userdata-page-finish', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'cancel',
        text: 'Entendi'
      }
    ]
  },

]