import React from "react";
import "./style.css";

function ModalBase({ setOpenModal, customMessage, cancelMessage, confirmMessage, customFunction, type}, ...props) {
  return (
    <div className="backgroundModal-ModalBase ">
      <div className="title-ModalBase">
        <div className="conteudoModal-Base">
          <h2>{customMessage}</h2>
          <div className="actions-ModalBase">

            {type === 'order-simulator' && 
            <button
                className="cancel-button-ModalBase"
              onClick={() => {
                setOpenModal(false);
              }}
              >
              {cancelMessage}
            </button>
           
            }
            <button
              className="buttonModal-Base"
              onClick={() => {
                setOpenModal(false);
                customFunction()
              }}
            >
              {confirmMessage}
            </button>
          </div>

        </div>
      </div>
    </div>
  );
}

export default ModalBase;
