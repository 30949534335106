import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// Data analysis
import { MP } from "../../../components/MixPanel";

// CSS
import "../style.css";

// Local components
import NavBar from "../../../components/Navbar";
import ButtonDefault from "../../../components/ButtonDefault";
import MapContainer from "../../../components/MapContainer";
import AutoComplete from "../../../components/AutoComplete";

// Assets
// import iconVoltar from "../../assets/Global/icon-voltar-topo.svg";
import transporteSegurado from "../../../assets/map/transporteSegurado.svg";
import avancar from "../../../assets/home/seta-avancar.svg";
import links from "../../../config/Links";

import { errorToaster, ToastContainer } from "../../../components/Toasters";
// import ButtonReturnMap from "../../../components/ButtonReturnMap";
import ButtonReturn from "../../../components/ButtonReturn";

// Geocode
import ApiModel from "../../../Api/ApiModel";

// Loading component
import { useLoading } from "../../../components/Loading";
// Tour context import 
// import { ShepherdTourContext } from "react-shepherd";
import { handleFinishMapTour } from "../../../Helper/Utils";

// Language
import LNG from "../../../lang/en-us.json"
import { Context } from "../../../Context/ContextGlobal";
import { getLanguageByTimezone, getLocaleLanguage } from "../../../lang/utils";

export default function Map(/* { setShowMap }, */ props) {
  // Tour context
  // const tour = useContext(ShepherdTourContext)

  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

  const DEBUG = false
  // Navigation
  const history = useNavigate();
  // States
  const [autoComplete, setAutoComplete] = useState("");
  const [location, setLocation] = useState(localStorage.getItem("latAndlongFrom") || null);

  if (DEBUG) console.log("Local de coleta : ", location);

  // MP
  MP("Map", { "type":"from" });  

  // Api Model Instance
  const apiHandler = new ApiModel()

  // Loading Context
  const { setLoading } = useLoading();

  async function handleValidateAndSaveData() {
     // Tour control
    handleFinishMapTour()
    setLoading(true)
    let payloadAddress = {}
    let mapReady = "false" //starts blocked
    let floor
    let complement

    if (localStorage.hasOwnProperty("completeFrom"))
      payloadAddress = JSON.parse(localStorage.getItem("completeFrom"))
    // if(DEBUG) console.log('VALIDATION', payloadAddress)

    localStorage.setItem("completeFrom", JSON.stringify(payloadAddress));

     // Treating the floor presentation data
    floor = `, ${LNG.txt_floor} ${payloadAddress.floor}`
    if (payloadAddress.floor === undefined || payloadAddress.floor === "") {
      floor = ""
    }

    complement = `, ${payloadAddress.complement}`
    if (payloadAddress.complement === undefined || payloadAddress.complement === "") {
      complement = ""
    }
    // Precise geocode integration
    apiHandler.exec("POST",`track/geocode`, {
      local: {
        address       :  payloadAddress.street,
        number        :  payloadAddress.number,
        district      :  payloadAddress.hood,
        city          :  payloadAddress.city,
        state         :  payloadAddress.district,
        country       :  payloadAddress.country
      }
    }).then((response) => {
      // OK
      if(DEBUG)console.log("RESPOSTA ENDPOINT GEOCODE", response)

      // Saves into storage
      if(response.success) {
        // Formats the coordinates
        const coordinatesObject = {lat:response.result.latitude, lng: response.result.longitude}
        // console.log("MapFrom : coordinatesObject", coordinatesObject);

        // Check Lat x Long
        if(
              coordinatesObject.lat === undefined || 
              coordinatesObject.lng === undefined ||
              coordinatesObject.lat === null ||
              coordinatesObject.lng === null ||
              coordinatesObject.lat === "" ||
              coordinatesObject.lng === ""
        ) {
          errorToaster(LNG.toaster_error_location_not_found);
          setLoading(false)
          return
        }

        const hood = response.data[0].extra.neighborhood
        const {streetName, streetNumber, country, zipcode} =  response.data[0]
        const { level2long, level1long} =  response.data[0].administrativeLevels
        
        // console.log("RESULTADO SPLIT", arrayAddress)
        localStorage.setItem(`latAndlongFrom`, JSON.stringify(coordinatesObject));
        // Getting stairs information

        let stairs  = (JSON.parse(localStorage.getItem("completeFrom")).stairs)

        let definitivePayload = {
          street      :  !streetName ? payloadAddress?.street :  streetName,
          number      :  !streetNumber ?  payloadAddress?.number : streetNumber,
          city        :  !level2long ? payloadAddress?.city : level2long,
          hood        :  !hood ? payloadAddress?.hood : hood,
          country     :  !country ? payloadAddress?.country : country === "Brazil" && "Brasil" ,
          district    :  !level1long ? payloadAddress?.district : level1long,
          postal_code :  !zipcode ? payloadAddress?.postal_code : zipcode
        }

        // Final input validation
        if(payloadAddress.stairs === "0" && payloadAddress.floor.length) {
          errorToaster(LNG.toaster_error_stairs_needed)
          setLoading(false)
          return
        }
        else if (definitivePayload.street === "" || definitivePayload.street === undefined) {
          errorToaster(LNG.toaster_error_street_input)
          setLoading(false)
          return
        } else if (definitivePayload.number === "" || definitivePayload.number === undefined) {
          errorToaster(LNG.toaster_error_number_input)
          setLoading(false)
          return
        } else if (definitivePayload.city === "" || definitivePayload.city === undefined) {
          errorToaster(LNG.toaster_error_city_input)
          setLoading(false)
          return
        } else if (definitivePayload.hood === "" || definitivePayload.hood === undefined) {
          errorToaster(LNG.toaster_error_hood_input)
          setLoading(false)
          return
        } else if (definitivePayload.district === "" || definitivePayload.district === undefined) {
          errorToaster(LNG.toaster_error_district_input)
          setLoading(false)
          return
        } else if (definitivePayload.country === "" || definitivePayload.country === undefined) {
          errorToaster(LNG.toaster_error_country_input)
          setLoading(false)
          return
        }

        // only if it pass all the conditions it saves
        // Syncs the address data
        localStorage.setItem(`completeFrom`, JSON.stringify({...payloadAddress, ...definitivePayload}));

        localStorage.setItem("addressFrom", JSON.stringify(`
          ${definitivePayload.street},
          ${definitivePayload.number}${complement}${floor},
          ${definitivePayload.hood},
          ${definitivePayload.city},
          ${definitivePayload.district},
          ${definitivePayload.country}  
        `));
        // Capturing the endpoint result
        history(links.main)

      }
      // On error
      
    }).catch((error) =>{ 
      setLoading(false)
      console.log(error)
      console.log("Erro geocode", error?.response)
      // Custom message from endpoint 
      errorToaster( error?.response?.data?.message_br || LNG.toaster_erro_address_invalid)

    })
  }
  
  function handleGetFormData(payload) {
    if (DEBUG) console.log('PAYLOAD COMPLETO FROM', payload)
    localStorage.setItem("completeFrom", JSON.stringify(payload))
  }

  function handleAutoComplete(autoComplete) {
    setAutoComplete(autoComplete);
    // handleValidateAndSaveData();
  }

  useEffect(() => {

    // Tour controller
    // if (localStorage.hasOwnProperty("pagesTourSeen")) {
    //   if (!JSON.parse(localStorage.getItem("pagesTourSeen")).map) {
    //     tour.start()
    //   }
    // } else {
    //   tour.start()
    // }
  }, [])

  return (
    <>
      <ToastContainer />
      <NavBar />
      <div className="container-principal-map ">
        <MapContainer location={location} /> { /* origin={{lat: -20.9079105, lng: -46.9797417}} destination={{lat: -15.749997 , lng: -47.9499962 }} */}
        <div className="container-full-map-page">
          <ButtonReturn onClick={handleFinishMapTour} to={links.main} />
          <h1>{LNG.title_map_from}</h1>
          <div className="input-map">
            <AutoComplete
              defaultValue={autoComplete || ""}
              onFormData={handleGetFormData}
              mapType="From"
            />
          </div>
        </div>
        <div className="container-inferior-cinza-map">
          <div className="container">
            <div className="margin-Map">
              <div className="container-inferior-map">
                <div>
                  <img
                    alt={LNG.alt_secured_trip}
                    className="transpSegurado-map"
                    src={transporteSegurado}
                  />
                </div>
                <div className="map-page-finish">
                  {/* <Link to={links.main} style={{ textDecoration: "none" }}> */}
                  <ButtonDefault
                    onClick={() => { handleValidateAndSaveData() }}
                    img={avancar}
                  >
                    {LNG.button_select}
                  </ButtonDefault>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}