import React, { useEffect, useState, useContext} from "react";
import { useNavigate } from "react-router-dom";

// Css
import "./style.css";

// Local components
import NavBar from "../../components/Navbar";
import ButtonReturn from "../../components/ButtonReturn";
import { shopcartCheck } from "../../Helper/Utils"; 
import { errorToaster, ToastContainer } from "../../components/Toasters";

// Data analysis
import { MP } from "../../components/MixPanel";

// Assets
// import dimensoes from "../../assets/productsAdd/dimensoes.svg";
import ButtonDefault from "../../components/ButtonDefault";
import adicionar from "../../assets/productsAdd/adiciona.svg";
import seta from "../../assets/productsAdd/seta.svg";
import subtrai from "../../assets/productsAdd/subtrai.svg";

// Routes
import links from "../../config/Links";
// Context tour import
import { ShepherdTourContext } from "react-shepherd";
import { Context } from "../../Context/ContextGlobal";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";

export default function ProductsAdd(props) {

  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]


  // MP
  MP("Products Add");

  // Tour control
  // const tour = useContext(ShepherdTourContext)

  // history to control the page flow
  const history = useNavigate()

  const [quantity, setQuantity] = useState(1);
  const [observations, setObservations] = useState('');
  const [productValue, setproductValue] = useState('');

  const [item, setItem] = useState(undefined);
  const [depth, setDepth] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);

  const [errorInput, setErrorInput] = useState({
      height: false,
      width:false,
      depth: false,
      weight: false
    })

  function _handleSaveProducts( finish = false ) {
    // finishes the tour
    handleFinishTour()
    
    if(depth === "") {
      errorToaster(LNG.toaster_error_product_depth);
      setErrorInput({...errorInput, depth: true})
      return 
    }else if ( width === "") {
      errorToaster(LNG.toaster_error_product_width);
      setErrorInput({...errorInput, width: true})
      return
    } else if (height === "") {
      errorToaster(LNG.toaster_error_product_height);
      setErrorInput({...errorInput, height: true})
      return 
    }
    else if (weight === "") {
      errorToaster(LNG.toaster_error_product_weight);
      setErrorInput({...errorInput, weight: true})
      return 
    }
    setErrorInput({height: false, width: false, depth: false, weight: false})

    const novoItem = { ...item, observations, quantity, depth, width, height, weight }
    setItem(novoItem);

    let items = [];

    if (localStorage.hasOwnProperty("items")) {
      items = JSON.parse(localStorage.getItem("items"));
    }
    items.push(novoItem);
    // Save items - shopcart
    localStorage.setItem("items", JSON.stringify(items));

    if( finish ){ 

      // Validation
      const validation = shopcartCheck();

      if( !validation ) {
        // Go back to Main

        // MP
        MP("Products Add", { "success":"false", "validation":"false", items});

        // errorToaster(LNG.toaster_error_date);
        return
      } else {
        // Go to finish
        
        // MP
        MP("Products Add", { "success":"true", items} );
        
        history(links.main, {replace: true});
      }   
    } else {
      // Go back to Main
      history(links.main,  {replace: true});
    }
  }

  function _handleGetItensFromLocalStorage() {
    let itemString = localStorage.getItem(`lastItem`);
    let itemObj = JSON.parse(itemString) || {};

    setproductValue(itemObj.name);

    setItem(itemObj);
    setDepth(itemObj.lenght  === "0.00" || itemObj.lenght === "0" ? "" : itemObj.lenght);
    setWeight(itemObj.weight === "0.00" || itemObj.weight === "0" ? "" : itemObj.weight);
    setHeight(itemObj.height === "0.00" || itemObj.height === "0" ? "" : itemObj.height);
    setWidth(itemObj.width   === "0.00" || itemObj.width  === "0" ? "" : itemObj.width);
  }

  function _handleDecrease() {
    if (quantity === 1) {
      return 0;
    }
    setQuantity(quantity - 1);
  };

  function handleFinishTour() {
       // Tour controller
      //  if (localStorage.hasOwnProperty("pagesTourSeen")){
      //   const restProps = JSON.parse(localStorage.getItem('pagesTourSeen'))
      //   localStorage.setItem('pagesTourSeen', JSON.stringify({
      //     ...restProps,
      //     product_add: true,
      //   }))
      // }
  }

  useEffect(() => {
    // if (localStorage.hasOwnProperty("pagesTourSeen")){
    //   if(!JSON.parse(localStorage.getItem("pagesTourSeen")).product_add) {
    //     tour.start()
    //   }
    // }

    _handleGetItensFromLocalStorage()

  }, [productValue]);

  // Top page
  useEffect(() => {
    try { window.scrollTo(0, 0); } catch (err) {}
  }, []);    

  return (
    <div className="productAdd">
      <div className="container-principal-productAdd">
        <NavBar />
        <ToastContainer />
        <div className="container">
          <div /* className="container-productAdd" */>
        <ButtonReturn onClick={handleFinishTour} to={links.main} />
            <div className="margin-productAdd">
              <h1>{LNG.title_product_add}</h1>              
              <h3 className="productsAddName tour-product-add-title">{productValue}</h3>              
              <div className="container-central-dimensions">
              <div className="container-central-productAdd">
                { /*
                <div className="container-items-productAdd">
                  <div className="campo-dimensoes-productAdd">
                    <div
                      style={{
                        zIndex: "1",
                        position: "absolute",
                        marginTop: "115px",
                        width: "96px",
                      }}
                    >
                      <div>
                        <h5>Profundidade</h5>
                      </div>
                      <div className={errorInput.depth && depth === "" && depth < 1 ?
                         "input-dimensoes-error" :"input-dimensoes"}>
                        <input
                          value={depth}
                          onChange={(e) => setDepth(e.target.value)}

                        />
                        <input value="cm" readOnly required />
                      </div>
                    </div>
                    <div
                      style={{
                        zIndex: "1",
                        position: "absolute",
                        marginTop: "115px",
                        marginLeft: "257px",
                        width: "100px",
                      }}
                    >
                      <div>
                        <h5>Altura</h5>
                      </div>
                      <div className={errorInput.height && height === "" && height < 1 ?
                         "input-dimensoes-error" :"input-dimensoes"}>
                        <input
                          value={height}
                          onChange={(e) => setHeight(e.target.value)}
                        />
                        <input value="cm" readOnly  required/>
                      </div>
                    </div>
                  </div>
                  <div className="campo-dimensoes-productAdd">
                    <div
                      style={{
                        zIndex: "1",
                        position: "absolute",
                        width: "100px",
                        marginTop: "10px",
                        marginLeft: "130px",
                      }}
                    >
                      <div>
                        <h5>Largura</h5>
                      </div>
                      <div
                        className={errorInput.width && width === "" && width < 1?
                         "input-dimensoes-error" :"input-dimensoes"}
                      >
                        <input
                          value={width}
                          onChange={(e) => { setWidth(e.target.value) }}
                        />
                        <input
                          value="cm"
                          readOnly
                          required
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        zIndex: "1",
                        position: "absolute",
                        marginTop: "235px",
                        marginLeft: "130px",
                        width: "100px",
                      }}
                    >
                      <div>
                        <h5>Peso</h5>
                      </div>
                      <div className={errorInput.weight && weight === "" && weight < 1?
                         "input-dimensoes-error" :"input-dimensoes"}>
                        <input
                          value={weight}
                          onChange={(e) => { setWeight(e.target.value) }}
                        />
                        <input value="Kg" required readOnly />
                      </div>
                    </div>
                  </div>
                  <div className="imgDimensoes">
                    <img
                      alt="dimensoes"
                      style={{ marginRight: "11px" }}
                      src={dimensoes}
                    />
                  </div>
                </div>
                */ } 
                <div className="container-observacoes tour-product-add-observations">
                  <h1>{LNG.subtitle_product_add_observations}</h1>
                  <textarea
                    onChange={(e) => setObservations(e.target.value)}
                    value={observations}
                    placeholder={LNG.placeholder_product_add_observations}
                  />
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-inferior-cinza-productAdd">
          <div className="container">
            <div  className="container-controllers ">
              <div className="margin-productAdd">
                <div className="positionItems-ProductsAdd ">
                  <div className="btn-productAdd tour-product-add-quantity">
                    <button onClick={_handleDecrease}>
                      <img alt={LNG.alt_minus} src={subtrai} />
                    </button>
                    <input value={quantity} readOnly />
                    <button onClick={() => setQuantity(quantity + 1)}>
                      <img alt={LNG.alt_add} src={adicionar} />
                    </button>
                  </div>
                  {/* <ButtonDefault
                    className={'button-custom tour-product-add-more-items'}
                    onClick={ () => _handleSaveProducts(false)}
                    img={adicionar}
                  >
                    {LNG.button_add}
                  </ButtonDefault> */}
                  <ButtonDefault
                    className={'button-custom tour-product-add-go-resume'}
                    onClick={ () => _handleSaveProducts(false)}
                    img={seta}
                    >
                      Add item
                  </ButtonDefault>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}