export const productAdd  = [
  {
    id: 'tour-presentation-product-add',
    title: '<strong>Revisão de produto</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
        Esta é a etapa de Revisão de produto, nesta página é possível definir as
         preferências e observações no produto que será adicionado ao carrinho de compras.
      `
    ],
    attachTo: { element: '', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-product-add-title',
    title: '<strong>Nome do produto</strong>',
    canClickTarget: false,
    scrollTo: true,
    text:
        `
        Essa sessão contém o nome do produto selecionado na página anterior.
        `,
    attachTo: { element: '.tour-product-add-title', on: 'bottom' },
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-product-add-observations',

    title: '<strong>Observações</strong>',
    text: [
      `
        Aqui podemos deixar observações ao transportador sobre cada item que será adicionado ao carreto,
         é importante informar ao transportador qual é o estado de conservação do item, por exemplo.
       `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.tour-product-add-observations', on: 'bottom' },
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-product-add-quantity',

    title: '<strong>Condição dos itens de transporte</strong>',
    attachTo: {
      element: '.tour-product-add-quantity',
      on: 'bottom'
    },
    // classes: 'custom-class-name-1 custom-class-name-2',
    canClickTarget: false,
    scrollTo: true,
    text: [
      ` 
      Essa sessão é destinada ao controle da quantidade desse mesmo item selecionado, para utilizar é
       preciso utilizar os indicadores com o sinal de adição (+) para adicionar e o indicador
        com sinal de subtração (-).
       `
    ],
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },
  {
    id: 'tour-product-add-more-items',
    title: '<strong>Deseja adicionar mais produtos?</strong>',
    attachTo: {
      element: '.tour-product-add-more-items',
      on: 'bottom'
    },
    // classes: 'custom-class-name-1 custom-class-name-2',
    canClickTarget: false,
    scrollTo: true,
    text: [
      ` 
      Ao clicar neste botão, é possível retornar para a página inicial
       para buscar e adicionar mais itens ao carreto.
       `
    ],
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },
  {
    id: 'tour-product-add-go-resume',
    title: '<strong>Este é o último objeto?</strong>',
    attachTo: {
      element: '.tour-product-add-go-resume',
      on: 'bottom'
    },
    // classes: 'custom-class-name-1 custom-class-name-2',
    canClickTarget: false,
    scrollTo: true,
    text: [
      ` 
      Ao clicar neste botão, é possível ir diretamente para a tela de resumo,
       use essa opção quando este for o último à ser adicionado ao carreto.
       `
    ],
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Entendi'
      }
    ],
  },
  
 
];
