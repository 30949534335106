export const orderResume = [
  {
    id: 'tour-order-resume-presentation',
    title: '<strong>Revisão de solicitação de transporte</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
      Nesta etapa você terá uma visão geral de todos os dados de sua solicitação e o valor total do transporte.
      `
    ],
    attachTo: { element: '', on: 'bottom' },
    
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-order-resume-date-route',
    title: '<strong>Data, local de coleta e entrega</strong>',
    canClickTarget: false,
    scrollTo: true,
    text:
        `
        Confirme se a data, hora e local de coleta e entrega estão corretas e evite atrasos e cobranças adicionais.
        `,
    attachTo: { element: '.tour-order-resume-date-route', on: 'bottom' },
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-order-resume-items',

    title: '<strong>Itens de transporte</strong>',
    text: [
      `
        Revise os itens ou pacote de transporte e confirme se está tudo correto.
       `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.tour-order-resume-items', on: 'bottom' },
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-order-resume-condition',

    id: 'main-search-product',
    title: '<strong>Termos e condições</strong>',
    attachTo: {
      element: '.tour-order-resume-condition',
      on: 'top'
    },
    // classes: 'custom-class-name-1 custom-class-name-2',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `Antes de realizar seu pedido será necessário confirmar que os itens estão desmontados, que há passagem livre para o transporte e que não necessitam de içamento.
      Não se preocupe caso seja necessário um tipo especial de entrega. É possível solicitá-la pelo botão de atendimento.
       `
    ],
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },
  {
    id: 'tour-order-resume-help-button',
    title: '<strong>Ajuda ?</strong>',
    attachTo: {
      element: '.tour-order-resume-help-button',
      on: 'bottom'
    },
    canClickTarget: false,
    scrollTo: true,
    // classes: 'custom-class-name-1 custom-class-name-2',
    text: [
      `
      Surgiu alguma dúvida, não está seguro quanto a entrega ou precisa de ajuda para realizar o pedido? Clique no botão de atendimento e fale com um de nossos atendentes.
      `
    ],
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },
  {
    id: 'tour-order-resume-dedicated',

    title: '<strong>Entrega dedicada</strong>',
    attachTo: {
      element: '.tour-order-resume-dedicated',
      on: 'top'
    },
    // classes: 'custom-class-name-1 custom-class-name-2',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
       Deseja que seus itens sejam transportados de forma exclusiva e sem compartilhamento com outros transportes ? A Entrega dedicada é a opção ideal para você.
       `
    ],
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },
  {
    id: 'tour-order-resume-princing',

    title: '<strong>Valor do pedido</strong>',
    attachTo: {
      element: '.tour-order-resume-princing',
      on: 'bottom'
    },
    // classes: 'custom-class-name-1 custom-class-name-2',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
        Confira aqui o valor total de sua solicitação de transporte.
       `
    ],
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },
 
  {
    id: 'tour-order-resume-foward-button',
    title: '<strong>Próxima etapa</strong>',      
    attachTo: {
      element: '.tour-order-resume-foward-button',
      on: 'bottom'
    },
    canClickTarget: false,
    scrollTo: true,
    // classes: 'custom-class-name-1 custom-class-name-2',
    text: [
      `
      Clique no botão Confirmar para avançar para a próxima etapa onde você poderá realizar o pagamento em ambiente seguro e finalizar seu pedido.
      `
    ],
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'cancel',
        text: 'Entendi!'
      }
    ],
    
  }
];
