import React, { useContext } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {number_format} from './Utils'
import { Context } from '../../Context/ContextGlobal';
import { getLanguageByTimezone, getLocaleLanguage } from '../../lang/utils';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
    );
    
    // genarate array itens from index
    function generateRange(n){
        return Array.from({length: n}, (_, i) => i + 1);
    }
// Validate leap year 
function verifyLeapYear (y) {
  return (y % 400 == 0) || (y % 4 == 0) && (y % 100 != 0)
}
// Getting month days number script
Date.prototype.getTotalDays = function () {
  let days = [30, 31],
    m = this.getMonth();
  let yr = this.getFullYear();
  let totalDays = 30
  let arrayDays = []
 
  if (m == 1) {
    // Genarates based on leap year validation
    return verifyLeapYear(yr)? generateRange(29):generateRange(28)
  } else {
    totalDays = days[(m + (m < 7 ? 1 : 0)) % 2];
    for (let i = 1; i <= totalDays; i++) {
      arrayDays.push(i)
    }
    return arrayDays
  }
}


var myDate = new Date();



export const data = {
    labels: myDate.getTotalDays(),
    datasets: [
        {
            label: "example",
            lineTension: 0.3,
            backgroundColor: "rgba(78, 115, 223, 0.05)",
            borderColor: "rgba(78, 115, 223, 1)",
            pointRadius: 3,
            pointBackgroundColor: "rgba(78, 115, 223, 1)",
            pointBorderColor: "rgba(78, 115, 223, 1)",
            pointHoverRadius: 3,
            pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
            pointHoverBorderColor: "rgba(78, 115, 223, 1)",
            pointHitRadius: 10,
            pointBorderWidth: 2,
            data: [
                2534,	6476,	6452,	5717,	8651,
                6739,	6788,	6717,	3423,	8535,
                8518,	5577,	5255,	1470,	6540,
                2872,	1104,	1350,	2062,	5033,
                9613,	8468,	5888,	8390,	8958,
                4287,	4418,	5095,	4493,	4361,
                6217	
            ],
        },
        // {
        //     label: "Dados 2",
        //     lineTension: 0.3,
        //     backgroundColor: "rgba(78, 233, 223, 0.05)",
        //     borderColor: "rgba(78, 233, 223, 1)",
        //     pointRadius: 3,
        //     pointBackgroundColor: "rgba(78, 233, 223, 1)",
        //     pointBorderColor: "rgba(78, 233, 223, 1)",
        //     pointHoverRadius: 3,
        //     pointHoverBackgroundColor: "rgba(78, 233, 223, 1)",
        //     pointHoverBorderColor: "rgba(78, 233, 223, 1)",
        //     pointHitRadius: 10,
        //     pointBorderWidth: 2,
        //     data: [
        //         1013,	1051,	2232,	2264,	2882,
        //         2970,	3279,	3466,	4102,	4382,
        //         4582,	4626,	5264,	5688,	5833,
        //         5918,	5937,	6435,	6700,	6761,
        //         6956,	6970,	7194,	7350,	7523,
        //         7530,	8135,	8211,	9187,	9319,
        //         9454,	
        //     ],
        // },

    ],
}

export const options = {
    maintainAspectRatio: false,
    layout: {
        padding: {
            left: 10,
            right: 25,
            top: 25,
            bottom: 0,
        },
    },
    scales: {
        xAxes: [
            {
                time: {
                    unit: "date",
                },
                gridLines: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    maxTicksLimit: 7,
                },
            },
        ],
        yAxes: [
            {
                ticks: {
                    maxTicksLimit: 5,
                    padding: 10,
                    // Include $ sign
                    callback: function (value, index, values) {
                        return number_format(value);
                    },
                },
                gridLines: {
                    color: "rgb(234, 236, 244)",
                    zeroLineColor: "rgb(234, 236, 244)",
                    drawBorder: false,
                    borderDash: [2],
                    zeroLineBorderDash: [2],
                },
            },
        ],
    },

    plugins: {
        legend: {
            display: true,
            position: "bottom",
            labels: {
                color: 'black'
            },
            usePointStyle:false,
        }
    },

    tooltips: {
        backgroundColor: "rgb(255,255,255)",
        bodyFontColor: "#858796",
        titleMarginBottom: 10,
        titleFontColor: "#6e707e",
        titleFontSize: 14,
        borderColor: "#dddfeb",
        borderWidth: 1,
        xPadding: 15,
        yPadding: 15,
        displayColors: false,
        intersect: false,
        mode: "index",
        caretPadding: 10,
        callbacks: {
            label: function (tooltipItem, chart) {
                var datasetLabel =
                    chart.datasets[tooltipItem.datasetIndex].label || "";
                return datasetLabel + " " + number_format(tooltipItem.yLabel);
            },
        },
    }
}

export default function LineGraphic() {
    const { business } = useContext(Context);
    
    // Context applied to lang setup
    let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

    const { datasets } = data;
    datasets[0].label = LNG.txt_month_result;

    const newData = Object.assign({
        ...data,
        datasets
    })

    return (
        <div style={{height: "400px"}}>
            <Line options={options} data={newData} />
        </div>
    )
}