import "./style.css";
import truckIcon from "../../assets/searchingDriver/caminhao.svg";

// TRANSLATION
import LNG from "../../lang/en-us.json"
import { useContext } from "react";
import { Context } from "../../Context/ContextGlobal";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";

function ButtonFollow(props) {

  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

    const defaultProps = {
      ...props,
      className: "btnPadraoPreto btn-follow" + ( props.className || "" ),
    };

    return (
      <button {...defaultProps}>
        {props.children}
        <div className="imgBtn">
          <img alt={LNG.alt_follow} src={truckIcon} />
        </div>
      </button>
    );
}

export default ButtonFollow;