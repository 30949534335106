import {useNavigate } from "react-router-dom";

// Css
import "./style.css";

// Local components
import NavBar from "../../components/Navbar";
import ButtonReturn from "../../components/ButtonReturn";
import Webcontent from "../../components/WebContent";

// Routes
// import links from "../../config/Links";

// TRANSLATION
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";
import { useContext } from "react";
import { Context } from "../../Context/ContextGlobal";


export default function LegalInfoPage() {

  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

  // history hook
  const history = useNavigate()
  return (
    <div className="container-principal">
      <NavBar />
      <ButtonReturn onClick={() => history(-1, { replace: true })  } />
      <div className="container">
        <div className="container-LegalInfoPage">
          <h1>{LNG.title_legal_info}</h1>
          <Webcontent
            url={LNG.link_legal_page}
            title={LNG.title_legal_information}
          />
        </div>
      </div>
    </div>
  );
}
