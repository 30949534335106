import { Link } from "react-router-dom";

// CSS
import "./style.css";

// Assets
import returnIcon from "../../assets/global/icon-voltar-topo.svg";
import { useContext } from "react";
import { Context } from "../../Context/ContextGlobal";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";


// Main function
export default function ButtonReturn( props ){


    const { business } = useContext(Context);

    // Context applied to lang setup
    let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]
    // Props
    const { to, className, onClick } = props;
          
    // Class
    const classNameButton = `imgVoltar ${ className !== undefined ? className : "" }`;

    return (
        <div className={classNameButton}>
            <Link to={to} onClick={onClick}>
                <img alt={LNG.alt_go_back} title={LNG.alt_go_back} src={returnIcon} />
            </Link>       
        </div> 
    );
}