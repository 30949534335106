import React, { useState } from "react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import demoImage from "../../assets/userData/user.svg";

function ImageCropper(props) {
  const { timeToCrop, imageToCrop, onImageCropped, setResultValue, setUrlProfile, setIsProfilePhotoEdit } = props;

  const [cropConfig, setCropConfig] = useState(
    {
      unit: "%",
      x: 0,
      y: 0,
      width: 50,
      height: 50
    }
  )
  const DEBUG = false;

  const [imageRef, setImageRef] = useState();

  async function cropImage(crop) {
    if (imageRef && crop.width && crop.height) {

        const croppedImageBlobVersion = await getCroppedImage(
          imageRef,
          crop,
          'croppedImage.jpeg' // destination filename
        );
        if(DEBUG){console.log("IMAGE CROP CONTAINER RESULT", croppedImageBlobVersion)}
        // Processing image for visualization
        const urlImage = URL.createObjectURL(croppedImageBlobVersion)
        setUrlProfile(urlImage)
        // Processing image as file for the endpoint
        const imageFileConverted = new File([croppedImageBlobVersion], "image.jpeg", {
          type: croppedImageBlobVersion.type,
        });
        if(DEBUG){console.log("MY FILE", imageFileConverted)}
        setResultValue(imageFileConverted);
    
    }
  }


  function getCroppedImage(sourceImage, cropConfig, fileName) {
    // creating the cropped image from the source image
    const canvas = document.createElement('canvas');
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext('2d');
  
    // const pixelRatio = window.devicePixelRatio;
    // canvas.width = cropConfig.width * pixelRatio;
    // canvas.height = cropConfig.height * pixelRatio;
    // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    // ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height,
    );


    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }

          blob.name = fileName;

          resolve(blob);
        }, 'image/jpeg'
      );
    });
  }

  return (
    <ReactCrop
      src={imageToCrop || demoImage}
      crop={timeToCrop ? cropConfig : ''}
      ruleOfThirds
      onImageLoaded={(imageRef) => setImageRef(imageRef)}
      onComplete={(cropConfig) => cropImage(cropConfig)}
      onChange={(cropConfig) => setCropConfig(cropConfig)}
      crossorigin="anonymous"
    />
  );
}

ImageCropper.defaultProps = {
  onImageCropped: () => { }
}

export default ImageCropper;