import { createContext, useContext, useState } from "react";

// CSS
import "./style.css";

// Load icons
import iconLoadBlack from "../../assets/global/icon-loading-black.svg";
import { Context } from "../../Context/ContextGlobal";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";


// Loader context
const LoadingContext = createContext({
  loading: false,
  setLoading: null,
});

// Loader provider
export function LoadingProvider({ children }) {

  const { business } = useContext(Context);
    
  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

  const [loading, setLoading] = useState(false);
  const value = { loading, setLoading };

  // Add body class
  const bodyElement = document.getElementsByTagName("body")[0];
  bodyElement.className = "loading";

  return (
    <LoadingContext.Provider value={value}>
        {children}
        {loading &&
        <div className="Loading">
            <div className="loadingWrapper">
                <div className="loadingIcon">
                    <img src={iconLoadBlack} alt={LNG.alt_loading} />
                </div>
                <div className="loadingMsg">{LNG.txt_loading}</div>
            </div>
        </div>        
        }
    </LoadingContext.Provider>
  );
}

// Loader hook
export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
}
