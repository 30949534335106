import "./style.css";

// assets
import goFowardIcon from "../../../src/assets/home/seta-avancar.svg";
import { useContext } from "react";
import { Context } from "../../Context/ContextGlobal";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";

// TRANSLATION

function ButtonGoFoward(props) {

  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

    const defaultProps = {
      ...props,
      className: "btnPadraoPreto btnAvancar" + ( props.className || "" ),
    };

    return (
      <button {...defaultProps}>
        {props.children}
        <div className="imgBtn">
          <img alt={LNG.alt_foward} src={goFowardIcon} />
        </div>
      </button>
    );
}

export default ButtonGoFoward;
