export const faq = [
  {
    id: 'tour-description-faq-page',
    title: '<strong>Sobre a página de dúvidas frenquentes</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
      Esta é a etapa onde podemos visualizar dúvidas frequentes, aqui é possível: encontrar artigos que o ajudem
      a sanar problemas durante o processo de realização do pedido. Caso não encontre 
      o que procura é possível entrar em contato conosco.
      `
    ],

    attachTo: { element: 'tour-description-faq-page', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-faq-page-link',
    title: '<strong>Sobre os links de tópicos</strong>',
    canClickTarget: false,
    scrollTo: true,
    text: [
      `
      Este é o link de acesso ao tópico, onde é possivel visualizar um artigo sobre 
      uma dúvida em nossa plataforma.
      `
    ],

    attachTo: { element: 'tour-faq-page-link', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'cancel',
        text: 'Entendi'
      }
    ]
  },
]