import React from 'react'

// CSS
import './style.css'

export default function ButtonCheckbox({ name, value, label1, label2, checkValue, setCheckValue}) {
  
  return (
    <div className="button-checkbox-container">
      <div class="checkbox-container">
        <label for="checkbox-content">
          <input type="checkbox" id="checkbox-content" onClick={ () => setCheckValue(!checkValue)} checked={checkValue} name="checkbox" />
          <div class="custom-checkbox">
            <span></span>
          </div>
          <span>{label1}</span>
        </label>
      </div>
    </div>
  )
}