import { useContext, useRef, useState, useEffect } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
// Local components
import NavBar from "../../components/Navbar";
import links from "../../config/Links";
import InputForm from "../../components/InputForm";
import InputMask from "../../components/InputMask";
import ButtonGoFoward from "../../components/ButtonGoFoward";
import ButtonReturn from "../../components/ButtonReturn";
import { useLoading } from "../../components/Loading";
import ClientsApi from "../../Api/Clients";
// TRANSLATION
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";
// Css
import "./style.css";

//Toaster
import {
  errorToaster,
  successToaster,
  ToastContainer,
} from "../../components/Toasters";
import { Context } from "../../Context/ContextGlobal";
import { validateCPF } from "validations-br";

// Main function
export default function Clients(props) {
  const [showForm, setShowForm] = useState(false);
  const [edit, setEdit] = useState({});
  const [clients, setClients] = useState([]);

  // Business context
  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)];
  // Form ref
  const formRef = useRef(null);

  // Loading
  const { setLoading } = useLoading();

  // New client function
  async function newClient() {
    // Reset form data
    setEdit({});

    // Show form
    setShowForm(!showForm);
  }

  // Client list pattern to show
  function clientListPattern() {
    // If no clients
    if (clients.length) {
      // List row array and generate html
      return clients.map((client, index) => {
        return (
          <tr key={index}>
            <td className="client-list-name">{client?.name}</td>
            <td className="container-table-bts">
              <button
                className="btnPadrao btnPeq"
                onClick={() => getClient(client?.id)}
              >
                Editar
              </button>
            </td>
          </tr>
        );
      });
    } else {
      // No clients
      return (
        <tr>
          <td className="table-empty" colspan="2">
            {LNG.txt_clients_list_empty}
          </td>
        </tr>
      );
    }
  }

  // List clients
  async function listClients() {
    // Loading
    setLoading(true);

    // Client list
    new ClientsApi()
      .list()
      .then((response) => {
        // Set clients list
        // console.log("listClients:","RESPONSE", response);
        const { rows } = response;
        if (rows) setClients(rows);

        setLoading(false);
      })
      .catch((error) => {
        console.log("ERROR", error);
        setLoading(false);
        errorToaster(LNG.toaster_clients_list_error);
      });
  }

  // Get client
  async function getClient(id) {
    setLoading(true);

    new ClientsApi()
      .get(id)
      .then((response) => {
        // Set edit data
        // console.log("getClient:", "RESPONSE", response);

        setEdit(response?.rows[0] || {});
        // Show form
        setShowForm(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        errorToaster(LNG.toaster_clients_get_error);
      });
  }

  // Add client
  async function addAccount(data) {
    // Loading
    setLoading(true);

    new ClientsApi()
      .add(data)
      .then((response) => {
        // console.log("addAccount:","RESPONSE", response);

        if (response) {
          // console.log("response", response, "response ERROR", response.error)
          // Add user error
          errorToaster(response.data);
          // alert.info(ret.message_br, { title: "Cadastro", closeCopy: "Ok" });
          // Loading
          setLoading(false);
        } else {
          successToaster(response.data);
          // Add user sucess
        }
      })
      .catch((error) => {
        // console.log("ERROR", error);

        // Set error vars
        const { message: msg } = error?.response?.data;
        setLoading(false);
        errorToaster(LNG.toaster_clients_add_error + "(" + msg + ")");
      });
  }

  // Update client
  async function updateAccount(data) {
    // Loading
    setLoading(true);

    new ClientsApi()
      .update(data)
      .then((response) => {
        // console.log("updateAccount:", "RESPONSE", response);

        if (response) {
          // console.log("response", response, "response ERROR", response.error)
          // Add user error
          errorToaster(response.data);
          // alert.info(ret.message_br, { title: "Cadastro", closeCopy: "Ok" });
          // Loading
          setLoading(false);
        } else {
          successToaster(response.data);
          // Add user sucess
        }
      })
      .catch((error) => {
        // console.log("ERROR", error);

        // Set error vars
        const { message: msg } = error?.response?.data;
        setLoading(false);
        errorToaster(LNG.toaster_clients_add_error + "(" + msg + ")");
      });
  }

  // Submit form
  async function handleSubmit(data, { reset }) {
    try {
      // Remove all previous errors
      formRef.current.setErrors({});
      // Validations
      const schema = Yup.object().shape({
        email: Yup.string()
          .email(LNG.yup_error_email_required)
          .required(LNG.yup_error_email_message),
        name: Yup.string().required(LNG.yup_error_name_message),
        cpf: Yup.string()
          .test("is-cpf", LNG.yup_error_cpf_message, (value) =>
            validateCPF(value)
          )
          .required(LNG.yup_error_cpf_required),
        phone: Yup.string()
          .min(8, LNG.yup_error_cellphone_message)
          .required(LNG.yup_error_cellphone_required),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);

      // Validation passed
      if (!edit.id) {
        await addAccount({
          email: data.email,
          name: data.name,
          cpf: data.cpf,
          cellphone: data.phone,
        });
        // Success
        await listClients();
        setEdit({});
        setShowForm(false);
        setLoading(false);
        successToaster(LNG.toaster_clients_add_success);
      } else {
        await updateAccount({
          id: edit.id,
          email: data.email,
          name: data.name,
          cpf: data.cpf,
          cellphone: data.phone,
        });
        // Success
        await listClients();
        setEdit({});
        setShowForm(false);
        setLoading(false);
        successToaster(LNG.toaster_clients_update_success);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
        errorToaster(LNG.toaster_error_create_user_data);
      }
    }
  }

  // Use effect
  useEffect(() => {
    listClients();
  }, []);

  return (
    <div className="container-principal">
      <NavBar />
      <ToastContainer />
      <div className="container">
        <div className="container-clients-main">
          <div className="container-backButton">
            <ButtonReturn to={links.dashboard} />
          </div>
          <div className="container-form">
            <div className="row">
              <h1>{LNG.title_clients}</h1>
              <p className="container-description">
                {LNG.txt_clients_tit_description}
              </p>
              <br />

              <div className="container-clients-data">
                {showForm ? (
                  <div className="container-clients-form">
                    <h2>
                      {!edit.id
                        ? LNG.txt_clients_tit_new
                        : LNG.txt_clients_tit_update}
                    </h2>
                    <br />
                    <Form id="myform" ref={formRef} onSubmit={handleSubmit}>
                      <div className="inputField">
                        <InputForm
                          id="client-name"
                          name="name"
                          type="name"
                          placeholder={LNG.placeholder_order_contact_name}
                          defaultValue={edit?.name || ""}
                        />
                      </div>
                      <div className="inputField">
                        <InputMask
                          type="phone"
                          name="phone"
                          mask="(99)99999-9999"
                          maskChar=" "
                          placeholder={LNG.placeholder_cellphone}
                          defaultValue={edit?.cellphone || ""}
                        />
                      </div>
                      <div className="inputField">
                        <InputForm
                          name="email"
                          type="email"
                          placeholder={LNG.placeholder_email}
                          defaultValue={edit?.email || ""}
                        />
                      </div>
                      <div className="inputField">
                        <InputMask
                          name="cpf"
                          mask="999.999.999-99"
                          maskChar=" "
                          type="cpf"
                          placeholder={LNG.placeholder_cpf}
                          defaultValue={edit?.cpf || ""}
                        />
                      </div>
                      <div className="inputField">
                        <ButtonGoFoward Form="myform" type="submit">
                          {LNG.button_save}
                        </ButtonGoFoward>
                      </div>
                    </Form>
                  </div>
                ) : (
                  <div className="container-clients-list">
                    <h2>{LNG.txt_clients_tit_list}</h2>
                    <br />
                    <table className="container-table">
                      {clientListPattern()}
                    </table>
                  </div>
                )}
              </div>

              <div className="container-clients-actions">
                <button
                  className="btnPadrao btnPeq"
                  onClick={() => newClient()}
                >
                  {showForm ? LNG.button_cancel : LNG.txt_clients_tit_new}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
