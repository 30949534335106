import React from 'react'

// CSS
import './style.css'

export default function ButtonRadio({ name, value, label1, label2 }) {
  return (
    <div className="button-radio-container">
      <div class="radio-container">
        <label for="radio">
          <input type="radio" checked id="radio" name="radio" />
          <div class="custom-radio">
            <span></span>
          </div>
          <span>{label1}</span>
        </label>
      </div>
      <div class="radio-container">
        <label for="radio1">
          <input type="radio" id="radio1" name="radio" />
          <div class="custom-radio">
            <span></span>
          </div>
          <span>{label2}</span>
        </label>
      </div>
    </div>

  )
}