// Components
import { useContext, useRef } from "react";
import Auth from "../../Api/Auth";
import { Link } from "react-router-dom";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { errorToaster, successToaster, ToastContainer } from "../../components/Toasters";

// Data analysis
import { MP } from "../../components/MixPanel";

// Local components
import { useLoading } from "../../components/Loading";
import ButtonGoFoward from "../../components/ButtonGoFoward";
import InputForm from "../../components/InputForm";
import ButtonReturn from "../../components/ButtonReturn";
// Links
import links from "../../config/Links";
// CSS
import "./style.css";

// Images
import pageImage from "../../assets/lostPass/senha-recuperacao.svg";
import { Context } from "../../Context/ContextGlobal";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";

//
export default function RecoveryPass(props) {


  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]


  // MP
  MP("Recovery Pass");

  // Load control
  const formRef = useRef(null);
  

  // Loading
  const { setLoading } = useLoading();

  async function updateUser(data) {
    
    // Loader
    setLoading(true);
    
    // Auth API
    const auth = new Auth();

    // Exec request
    await auth.updatePass(data)
      .then((ret) => {
        if ( ret && ret.success ) {
          // Success
          
          // MP
          MP("Recovery Pass", { "success": "true" });

          // alert.success(ret.message_br, { title: "Alteração de senha", closeCopy: "Ok" });
          successToaster(ret.message_br);
          // Redir 
          props.history("/", { result: ret });
        } else {
          // Erro
          
          // MP
          MP("Recovery Pass", { "success": "false" });
                      
          // alert.info(ret.message_br, { title: "Alteração de senha", closeCopy: "Ok" });
          errorToaster(ret.message_br);
        }
      })
      .catch((error) => {
        
        // MP
        MP("Recovery Pass", { "error": "true" });
                
        if (error.response) {
          // Erro
          // console.log(error.response.data.message_br); // => the response payload 
          // alert.info(error.response.data.message_br, { title: "Alteração de senha", closeCopy: "Ok" });
          errorToaster(error.response.data.message_br);          
        } else {
          // Undefined error
          errorToaster(LNG.toaster_error_undefined);   
        }
      });

    // Loader
    setLoading(false);
  }

  async function updateWorker(data) {
    
    // Loader
    setLoading(true);
    
    // Auth API
    const auth = new Auth();

    // Exec request
    await auth.updatePassWorker(data)
      .then((ret) => {
        if ( ret && ret.success ) {
          // Success
          successToaster(LNG.toaster_success_password_has_changed);

          // Reset form
          formRef.current.reset();

          // successToaster(ret.message_br);
          // Redir 
          // props.history.push("/");
        } else {
            // Erro
            // alert.info(ret.message_br, { title: "Alteração de senha", closeCopy: "Ok" });
            errorToaster(ret.message_br);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Erro
          // console.log(error.response.data.message_br); // => the response payload 
          errorToaster(error.response.data.message_br, );
          // errorToaster(error.response.data.message_br);          
        } else {
          // Undefined error
          // alert.info("Ocorreu um erro não identificado. Tente novamente mais tarde.", { title: "Alteração de senha", closeCopy: "Ok" });
          errorToaster(LNG.toaster_error_undefined);  
        }
      });

    // Loader
    setLoading(false);
  }

  async function handleSubmit(data, { reset }) {

    // Get code
    const pathname = window.location.pathname;

    // Paths
    const pathUser    =   "/senha-recuperacao/";
    const pathWorker  =   "/senha-recuperacao-transportador/";

    // Identify if is user or worker by pathname
    const isUser      =   pathname.includes(pathUser);
    const isWorker    =   pathname.includes(pathWorker);

    // Replace pathname to get code
    const code        =   pathname.replace( ( isUser ? pathUser : pathWorker ),"");

    // Check data
    if ( !code ) {
      errorToaster(LNG.toaster_recuperation_link_incorrect);
      return;
    }

    // Check path
    if ( !isUser && !isWorker ) {
      errorToaster(LNG.toaster_recuperation_link_invalid);
      return;
    }    

    try {
      // Remove all previous errors
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        pass: Yup.string().required("Digite sua nova senha."),
        cpass: Yup.string()
          .test("is-ok", LNG.yup_error_password_not_equal_message, (value) => { return ( data.cpass === data.pass); })
          .required(LNG.yup_error_password_confirm_required),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      // Validation passed
      if( isUser){ 
        // Update user
        await updateUser({
          code : code,
          pass : data.pass,
        });
      } else if( isWorker ){
        // Update worker
        await updateWorker({
          code : code,
          pass : data.pass,
        });      
      }

      // Loading off
      setLoading(false);

    } catch (err) {
      /* const validationErrors = {}; */
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      // Loader off
      setLoading(false);
    }
  }

  return (
    <div className="container-principal-PassRecovery">
      <div className="container-PassRecovery">
        <div className="container-PassRecovery">
         <ToastContainer />
         <ButtonReturn to={links.home}/>
          <div className="container-form">            
            <div className="column-PassRecovery">
              <Form id="myform" ref={formRef} onSubmit={handleSubmit}>
                <Link to="/" className="bt-top-back"></Link>
                <h1>{LNG.title_lost_pass}</h1>
                <p className="container-description">
                {LNG.title_form_recovery_pass}
                </p>
                <div  className="inputField">
                  <InputForm name="pass" type="password" placeholder={LNG.placeholder_password} />
                </div>
                <div  className="inputField">
                  <InputForm name="cpass" type="password" placeholder={LNG.placeholder_new_password} />
                </div>                
                </Form>
              </div>
              <div className="column detail-PassRecovery">
                <img src={pageImage} alt={LNG.alt_change_password} />
              </div>
          </div>
        </div>
      </div>
      <div className="container-inferior-PassRecovery">
          <div className="container-AccountScreen">
            <form>
              <ButtonGoFoward className={"container-AccountScreen-button"} Form="myform" type="submit">
                {LNG.button_confirm}
              </ButtonGoFoward>
            </form>
          </div>
      </div>
    </div>
  );
}