import PropTypes from "prop-types";
import React from "react";

import "./style.css";

// refactor to hooks
export function Switch( { selected, toggleSelected })  {
    return (
      <div
        className={`toggle-container ${ selected ? "off" : "on"}`}
        onClick={toggleSelected}
      >
        <div className="dot"></div>
      </div>
    );
}

Switch.propTypes = {
  selected: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired,
};
export default Switch;
