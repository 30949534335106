import React, { useContext } from "react"
import {useNavigate } from "react-router-dom"

// Internal imports
import ButtonReturn from "../../components/ButtonReturn"
import NavBar from "../../components/Navbar"

// CSS
import "./style.css"
import { Context } from "../../Context/ContextGlobal"
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils"

// TRANSLATION
// import LNG from "../../lang/en-us.json"

export default function Settings() {

    const { business } = useContext(Context);
    // Context applied to lang setup
    let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

    const history = useNavigate()

    return (
        <>
            <NavBar />
            <div className="container">
                <ButtonReturn onClick={() => { history(-1, { replace: true })   }} />
                <h1>{LNG.title_settings}</h1>
            </div>
        </>
    )
}