import ENV from "../../env";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Legacy imports

import Tooltip from "rc-tooltip";
import "../OrderResume/tooltip-white.css";

// Routes
import links from "../../config/Links";

// Css
import "./style.css";

// Local components
import NavBar from "../../components/Navbar";
import ButtonDefault from "../../components/ButtonDefault";
import MobileSwitch from "../../components/MobileSwitch";
import ButtonReturn from "../../components/ButtonReturn";
import HelpButton from "../../components/ButtonHelp";

// Data analysis
import { MP } from "../../components/MixPanel";

// Assets
import caminhao from "../../assets/orderResume/truck-delivery.svg";
import pgtoSeguro from "../../assets/orderResume/ambiente-seguro.svg";
import interrogacao from "../../assets/orderResume/interrogacao.svg";
import excluir from "../../assets/orderResume/excluir.svg";
// import calendar from "../../assets/orderResume/calendar.svg";
// import iconLocationEsq from "../../assets/orderResume/icon-location-esq.svg";
// import iconLocationDir from "../../assets/orderResume/icon-location-dir.svg";
import iconAdd from "../../assets/productsAdd/adiciona.svg";
import iconDelete from "../../assets/productsAdd/subtrai.svg";
import iconBoxWhite from "../../assets/home/box-delivery-agrupado.svg";

// Delivery Profile
// import caixa from "../../assets/main/caixa.svg";
import iconTransporte from "../../assets/deliveryProfile/icon-transporte-itens.svg";
import mudancaResidencial from "../../assets/deliveryProfile/home.svg";
import mudancaEmpresarial from "../../assets/deliveryProfile/mudanca-empresarial.svg";

//Api call utils
import {
  orderEstimatePrice,
  shopcartCheck,
  sortArrayObj,
} from "../../Helper/Utils";
import { useLoading } from "../../components/Loading";
import {
  errorToaster,
  successToaster,
  ToastContainer,
} from "../../components/Toasters";
// import ButtonCheckbox from "../../components/ButtonCheckbox";
// import { ShepherdTourContext } from "react-shepherd";

import AutoComplete from "../../components/AutoComplete";
import InputAddProduct from "../../components/InputAddProduct";
import ModalBase from "../../components/ModalBase";
import { Context } from "../../Context/ContextGlobal";
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";

// Components internal

export default function OrderSimulator() {
  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)];
  // TABLE FOOTER VALUES

  // Sum of all products
  let prodWidth = 0,
    prodHeight = 0,
    prodLength = 0,
    prodWeight = 0,
    prodCube = 0;

  let prodsTotalCount = 0,
    prodsTotalQtde = 0,
    prodsTotalWeight = 0,
    prodsTotalHeight = 0,
    prodsTotalWidth = 0,
    prodsTotalLength = 0,
    prodsTotalCube = 0;

  // Cubage
  function cubage(length, width, height, factor = 300) {
    // Fix numbers
    length = parseFloat(length);
    width = parseFloat(width);
    height = parseFloat(height);

    return (length * width * height * factor).toFixed(2);
  }
  // MP
  MP("Order Simulator");

  // Navigation
  const history = useNavigate();

  const [decicatedSwitch, setDecicatedSwitch] = useState(true);
  // Delivery products
  let deliveryItems = JSON.parse(localStorage.getItem("items")) || [];
  const [list, setList] = useState(deliveryItems);

  //Api call Switch
  const [apiResponse, setApiResponse] = useState({ financial: "..." });

  const [checkValue, setCheckValue] = useState(false);

  const DEBUG = false;

  const dadosUsuario = JSON.parse(localStorage.getItem("token"));
  const { token } = dadosUsuario;
  const USER_TOKEN = token;

  // calling api url
  const API_URL = ENV().ECARRETO_API_URL || "https://api.ecarreto.com.br";

  // loading state
  // Paginator States
  const [page, setPage] = useState(1);

  // Category list

  const now = new Date();

  const [orderEstimatedPrice, setOrderEstimatedPrice] = useState(undefined);
  // const [addressFrom, setAddressFrom] = useState({})
  // const [addressTo, setAddressTo] = useState({})
  const [deliveryDate, setDeliveryDate] = useState(now);

  let deliveryDates = JSON.parse(localStorage.getItem("DDArray")) || [];
  const [arrayDates, setArrayDates] = useState(deliveryDates);

  let deliveryTypeFromStorage =
    JSON.parse(localStorage.getItem("deliveryType")) || 1;
  const [deliveryType, setDeliveryType] = useState(deliveryTypeFromStorage);

  const [autoCompleteTo, setAutoCompleteTo] = useState("");
  const [autoCompleteFrom, setAutoCompleteFrom] = useState("");
  const [showModal, setShowModal] = useState(false);
  // Loading call
  const { setLoading } = useLoading();

  const handleConfirmOrdersModal = () => {
    setShowModal(true);
  };

  // Functions
  // Simulates an order
  const handleEstimateOrderPrice = () => {
    setLoading(true);

    const productsInitial = JSON.parse(localStorage.getItem("items")) || [];

    if (!productsInitial.lenght < 0 || !productsInitial) {
      errorToaster(LNG.toater_error_no_products);
      setLoading(false);
      return;
    }

    // Geolocation validation

    const addressFrom = JSON.parse(localStorage.getItem("completeFrom")) || {};
    const addressTo = JSON.parse(localStorage.getItem("completeTo")) || {};

    if (
      !addressFrom.street ||
      addressFrom.street === "" ||
      !addressFrom.number ||
      addressFrom.number === "" ||
      !addressFrom.city ||
      addressFrom.city === "" ||
      !addressFrom.hood ||
      addressFrom.hood === "" ||
      !addressFrom.country ||
      addressFrom.country === "" ||
      !addressFrom.district ||
      addressFrom.district === ""
    ) {
      errorToaster(LNG.toater_error_no_pickup_location);
      setLoading(false);
      return;
    }

    if (
      !addressTo.street ||
      addressTo.street === "" ||
      !addressTo.number ||
      addressTo.number === "" ||
      !addressTo.city ||
      addressTo.city === "" ||
      !addressTo.hood ||
      addressTo.hood === "" ||
      !addressTo.country ||
      addressTo.country === "" ||
      !addressTo.district ||
      addressTo.district === ""
    ) {
      errorToaster(LNG.toater_error_no_delivery_location);
      setLoading(false);
      return;
    }

    const errorMessage = LNG.toaster_error_on_data_selection;
    // return values and validation
    const {
      latAndlongFrom,
      latAndlongTo,
      dedicatedDelivery,
      products,
      validation,
    } = handleValidateOrderPayload(errorMessage);

    // if validations fails
    if (!validation) return;

    if (!userId || userId === 0) {
      errorToaster(LNG.toaster_error_on_empty_user);
      setLoading(false);
      return;
    }
    // if (arrayDates.length == 0) {
    //   errorToaster(LNG.toaster_error_empty_array_dates)
    //   setLoading(false)
    //   return
    // }

    // Processing products
    const organizedProducts = handleOrganizeProducts(products);

    const payload = handleMountPayload(
      latAndlongFrom,
      latAndlongTo,
      dedicatedDelivery,
      organizedProducts
    );
    if (DEBUG) console.log("payload", payload);

    // tries to simulate the price
    try {
      const options = {
        method: "POST",
        url: `${API_URL}/order/estimated-price/`,
        headers: {
          "x-access-token": USER_TOKEN,
        },
        // params: { page: realpage, total: '1000' },
        data: payload,
      };
      axios
        .request(options)
        .then(function (response) {
          const formatedPrice =
            LNG.txt_default_currency + response.data.financial.price;
          setOrderEstimatedPrice(formatedPrice);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          errorToaster(LNG.toaster_error_on_order_estimation);
        });
    } catch (error) {
      setLoading(false);
      errorToaster(LNG.toaster_error_on_order_estimation);
    }
  };
  // Creates an order
  const handleCreateOrder = () => {
    setLoading(true);

    const addressFrom = JSON.parse(localStorage.getItem("completeFrom")) || {};
    const addressTo = JSON.parse(localStorage.getItem("completeTo")) || {};

    if (
      !addressFrom.street ||
      addressFrom.street === "" ||
      !addressFrom.number ||
      addressFrom.number === "" ||
      !addressFrom.city ||
      addressFrom.city === "" ||
      !addressFrom.hood ||
      addressFrom.hood === "" ||
      !addressFrom.country ||
      addressFrom.country === "" ||
      !addressFrom.district ||
      addressFrom.district === ""
    ) {
      errorToaster(LNG.toater_error_no_pickup_location);
      setLoading(false);
      return;
    }

    if (
      !addressTo.street ||
      addressTo.street === "" ||
      !addressTo.number ||
      addressTo.number === "" ||
      !addressTo.city ||
      addressTo.city === "" ||
      !addressTo.hood ||
      addressTo.hood === "" ||
      !addressTo.country ||
      addressTo.country === "" ||
      !addressTo.district ||
      addressTo.district === ""
    ) {
      errorToaster(LNG.toater_error_no_delivery_location);
      setLoading(false);
      return;
    }
    // Finds the user by id and catches the data
    const completeUser = customers.find((x) => x.id == userId);
    const dteArrValidation = handleDeliveryDatesValidation(arrayDates);

    if (!dteArrValidation) {
      errorToaster(LNG.toaster_error_on_delivery_dates);
      setLoading(false);
      return;
    }
    if (!completeUser) {
      errorToaster(LNG.toaster_error_on_empty_user);
      setLoading(false);
      return;
    }
    console.log("--->", arrayDates);
    if (arrayDates.length === 0) {
      errorToaster(LNG.toaster_error_empty_array_dates);
      setLoading(false);
      return;
    }

    const errorMessage = LNG.toaster_error_enter_all_order_data;
    const {
      latAndlongFrom,
      latAndlongTo,
      dedicatedDelivery,
      products,
      validation,
    } = handleValidateOrderPayload(errorMessage);

    if (!validation) {
      return;
    }
    if (arrayDates.length) {
      // if validations fails
      // Mounting payload
      const organizedProducts = handleOrganizeProducts(products);

      let arrayRequests = [];

      // Mapping request array
      arrayDates.map((itemDateParam, index) => {
        const payload = handleMountPayload(
          latAndlongFrom,
          latAndlongTo,
          dedicatedDelivery,
          organizedProducts,
          "pix",
          completeUser,
          itemDateParam
        );
        // tries to create an order
        const options = {
          method: "POST",
          url: `${API_URL}/order/add`,
          headers: {
            "x-access-token": USER_TOKEN,
          },
          // params: { page: realpage, total: '1000' },
          data: payload,
        };
        arrayRequests.push(
          axios
            .request(options)
            .then(function (response) {
              // successToaster('Pedido criado com sucesso!')
              // setLoading(false)
            })
            .catch(() => {
              errorToaster(LNG.toaster_error_order_create);
            })
        );
      });

      setLoading(true);
      // Resolving promises
      Promise.all(arrayRequests)
        .then((response) => {
          // errorToaster('Pedido criado!')
        })
        .catch((err) => {
          errorToaster(LNG.toaster_error_order_create + " " + err);
        })
        .finally(() => {
          setLoading(false);
          successToaster(LNG.toaster_success_on_order_create);
        });
    }
    setLoading(false);

    return;
  };

  const handleDeleteDate = (itemProduct) => {
    const itemsToBeRemoved = [itemProduct];
    let filteredArray = arrayDates.filter(
      (item) => !itemsToBeRemoved.includes(item)
    );
    localStorage.setItem("DDArray", JSON.stringify(filteredArray));
    setArrayDates(filteredArray);
  };

  const handleDeliveryDatesValidation = (dteArr) => {
    let validation = true;

    const currentDte = new Date();

    for (const dteString of dteArr) {
      const dte = new Date(dteString);
      if (dte.getTime() < currentDte.getTime()) {
        validation = false;
      }
    }

    return validation;
  };

  // Utils functions
  const handleOrganizeProducts = (products) => {
    let orderProducts = [];
    products.map(({ id, quantity, weight, width, height, depth }) => {
      orderProducts.push({
        id_product: id,
        quantity: quantity,
        weight: weight,
        width: width,
        height: height,
        length: depth,
      });
    });

    return orderProducts;
  };
  const handleValidateOrderPayload = (errorMessage) => {
    let validation = false;
    const dedicatedDelivery = !decicatedSwitch ? true : false;
    const latAndlongFrom = JSON.parse(localStorage.getItem("latAndlongFrom"));
    const latAndlongTo = JSON.parse(localStorage.getItem("latAndlongTo"));

    let products = JSON.parse(localStorage.getItem("items")) || [];

    if (
      products === [] ||
      products === null ||
      latAndlongFrom === "" ||
      latAndlongTo === "" ||
      !products.length < 0
    ) {
      errorToaster(errorMessage);
      setLoading(false);
      return { latAndlongFrom, latAndlongTo, dedicatedDelivery, validation };
    }
    // return values to be reused on payload
    validation = true;
    return {
      latAndlongFrom,
      latAndlongTo,
      dedicatedDelivery,
      products,
      validation,
    };
  };

  // setting user info

  const handleMountPayload = (
    latAndlongFrom,
    latAndlongTo,
    dedicatedDelivery,
    organizedProducts,
    paymentType = undefined,
    completeUser,
    deliveryDateParam
  ) => {
    // const addressFrom = (JSON.parse(localStorage.getItem("lateAddressInfoFrom")))
    // const lateInfoTo = (JSON.parse(localStorage.getItem("lateAddressInfoTo")))

    const addressFrom = JSON.parse(localStorage.getItem("completeFrom"));
    const addressTo = JSON.parse(localStorage.getItem("completeTo"));

    if (DEBUG) {
      console.log("COMPLETE ADDRESS INFO FROM ", addressFrom);
      console.log("COMPLETE ADDRESS INFO TO ", addressTo);
    }
    let finalDte;
    // defining date to use in payload
    if (deliveryDateParam) {
      finalDte = deliveryDateParam;
    } else {
      finalDte = deliveryDate;
    }
    if (DEBUG) console.log("data final", finalDte);
    try {
      // Tries to mount the payload, based on params
      let payload = {
        type: parseInt(deliveryType),
        dedicated: dedicatedDelivery,
        dte_delivery: finalDte,

        // if there is complete user
        ...(completeUser && {
          local_from_name: completeUser.name,
          local_from_email: completeUser.email,
          local_from_cpf: completeUser.cpf,
          local_from_cellphone: completeUser.cellphone,
          local_to_name: completeUser.name,
          local_to_email: completeUser.email,
          local_to_cpf: completeUser.cpf,
          local_to_cellphone: completeUser.cellphone,

          local_from_cep: addressFrom.postal_code,
          local_from_address: addressFrom.street.trim(),
          local_from_number: addressFrom.number.trim(),
          local_from_district: addressFrom.hood.trim(),
          local_from_city: addressFrom.city.trim(),
          local_from_state: addressFrom.district.trim(),
          local_from_country: addressFrom.country.trim(),
          local_from_complement: addressFrom.complement
            ? addressFrom.complement.trim()
            : "",

          local_to_cep: addressTo.postal_code,
          local_to_address: addressTo.street.trim(),
          local_to_number: addressTo.number.trim(),
          local_to_district: addressTo.hood.trim(),
          local_to_city: addressTo.city.trim(),
          local_to_state: addressTo.district.trim(),
          local_to_country: addressTo.country.trim(),
          local_to_complement: addressTo.complement
            ? addressTo.complement.trim()
            : "",
        }),
        local_from_floor:
          addressFrom.floor === "" ? 0 : parseInt(addressFrom.floor),
        local_to_floor: addressTo.floor === "" ? 0 : parseInt(addressTo.floor),

        local_from_elevator: addressFrom.stairs === "2" ? 1 : 0,
        local_from_entrance: addressFrom.stairs === "3" ? 1 : 0,
        local_from_stairs: addressFrom.stairs === "1" ? 1 : 0,

        local_to_entrance: addressTo.stairs === "3" ? 1 : 0,
        local_to_stairs: addressTo.stairs === "1" ? 1 : 0,
        local_to_elevator: addressTo.stairs === "2" ? 1 : 0,

        local_from_lat: latAndlongFrom.lat,
        local_from_lon: latAndlongFrom.lng,
        local_to_lat: latAndlongTo.lat,
        local_to_lon: latAndlongTo.lng,
        optionals: [],
        products: organizedProducts,
        // conditional args
        ...(userId && { id_user: userId }),
        ...(paymentType && { payment: { type: paymentType } }),
      };
      return payload;
    } catch (error) {
      errorToaster(error);
      console.log(error);
    }
  };

  // Setting date defore mounting
  function suggestDateAddHours(deliveryDate, hours) {
    const dte = new Date(deliveryDate);
    let hour = dte.getHours() - hours;
    let minutes = dte.getMinutes();

    dte.setHours(hour, minutes, 0, 0);
    return dte;
  }

  function suggestDateRemoveHours(deliveryDate, hours) {
    const dte = new Date(deliveryDate);
    let hour = dte.getHours() - hours;
    let minutes = dte.getMinutes();

    dte.setHours(hour, minutes, 0, 0);
    return dte;
  }

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  // --------------------------------------------------------------------------------------------
  // Search User Select
  // --------------------------------------------------------------------------------------------

  const [customers, setCustomers] = useState([]);
  const userIdFromStorage = JSON.parse(localStorage.getItem("Ud")) || 0;
  const [userId, setUserId] = useState(userIdFromStorage);

  const _handleGetAllUsers = async (realpage) => {
    setLoading(true);
    // setLoading(true)

    if (!token) return;
    if (page === 0) return;

    const options = {
      method: "POST",
      url: `${API_URL}/business/user/list`,
      headers: {
        "x-access-token": token,
      },
      params: { page: realpage, total: "1000" },
      // data: payload
    };

    // handle request error or success scenario
    axios
      .request(options)
      .then(function (response) {
        const { page, page_itens, rows, total_itens, total_pages } =
          response.data;

        let result = sortArrayObj(rows);
        setCustomers(result);
        setLoading(false);
      })
      .catch(function (error) {
        errorToaster(LNG.toaster_error_fetching_clients);
        console.error(error?.response?.data || "Error on loading user list");
        setLoading(false);
      });
  };
  // --------------------------------------------------------------------------------------------

  // REMOVE IT FROM HERE
  const handleOnSwitchChange = () => {
    orderEstimatePrice(setApiResponse, setLoading, errorToaster).then(
      (response) => {
        setLoading(true);

        if (response) {
          setApiResponse(response);
          localStorage.setItem(
            "pricef",
            JSON.stringify(
              apiResponse.financial.pricef || LNG.txt_default_price_number
            )
          );
          // setPricef(apiResponse.financial.pricef)
          setLoading(false);
        }
      }
    );
    setLoading(false);
  };

  function handleOrderPayment() {
    handleFinishTour();

    // Validation
    const validation = shopcartCheck();
    if (!checkValue) {
      errorToaster(LNG.toaster_error_accept_terms);
      return;
    }
    if (!validation) {
      // Wrong Navigation

      // MP
      // MP("Order Resume", { "success": "false", "validation" : "false" });

      // Send msg to user
      errorToaster(LNG.toaster_error_location_options);
      // Return to main
      history(links.main);
      return;
    }
    if (!decicatedSwitch) {
      localStorage.setItem("dedicatedDelivery", JSON.stringify(true));
    } else {
      localStorage.setItem("dedicatedDelivery", JSON.stringify(false));
    }
    if (apiResponse) {
      localStorage.setItem(
        "pricef",
        JSON.stringify(apiResponse.financial.pricef || "")
      );
    }

    // MP
    MP("Order Resume", { success: "true" });

    // Validation passed
    history(links.orderContact);
  }

  function handleFinishTour() {
    // Tour controller
    if (localStorage.hasOwnProperty("pagesTourSeen")) {
      const restProps = JSON.parse(localStorage.getItem("pagesTourSeen"));
      localStorage.setItem(
        "pagesTourSeen",
        JSON.stringify({
          ...restProps,
          order_simulator: true,
        })
      );
    } else {
      localStorage.setItem(
        "pagesTourSeen",
        JSON.stringify({
          order_simulator: true,
        })
      );
    }
  }
  // MAP functions

  function handleGetFormData(payload, type) {
    if (DEBUG) console.log("PAYLOAD COMPLETO TO", payload);
    localStorage.setItem(`complete${type}`, JSON.stringify(payload));
  }
  function handleAutoComplete(autoComplete, choosenState) {
    choosenState(autoComplete);
    // handleValidateAndSaveData()
  }

  // PRODUCT SELECTION

  function handleShopcartRemove(index) {
    deliveryItems.splice(index, 1);
    localStorage.setItem("items", JSON.stringify(deliveryItems));
    setList(deliveryItems);
  }

  useEffect(() => {
    _handleGetAllUsers();
  }, []);

  return (
    <>
      {showModal && (
        <ModalBase
          customMessage={LNG.modal_order_warning}
          confirmMessage={LNG.button_place_order}
          cancelMessage={LNG.button_cancel}
          setOpenModal={setShowModal}
          customFunction={handleCreateOrder}
          type="order-simulator"
        />
      )}
      <div className="container-principal-OrderResume">
        <NavBar />
        {/* <ToastContainer /> */}
        <div className="container">
          <ButtonReturn to={links.dashboard} />
          <div className="container-OrderResume">
            <div className="container-central-OrderResume ">
              <h1>{LNG.title_order_resume}</h1>
              <hr />

              <br />
              <h2>{LNG.title_delivery_type}</h2>
              <br />
              <div className="input-group m-1">
                <select
                  className="order-simulator-input"
                  value={deliveryType}
                  onChange={(e) => {
                    setDeliveryType(e.target.value);
                    localStorage.setItem(
                      "deliveryType",
                      JSON.stringify(e.target.value)
                    );
                  }}
                >
                  <option value={1}>{LNG.type_delivery_items}</option>
                  <option value={2}>{LNG.type_delivery_residential}</option>
                  <option value={3}>{LNG.type_delivery_enterprise}</option>
                </select>
              </div>

              <br />
              <h2>{LNG.txt_search_client}</h2>
              <br />

              <div className="input-group m-1">
                {/* <input
              type="text"
              className="form-control bg-light small"
              placeholder="Digite o nome do cliente"
              id="product-name"

            /> */}
                <select
                  className="order-simulator-input"
                  // onClick={() => _handleGetAllUsers()}
                  value={userId}
                  onChange={(e) => {
                    setUserId(e.target.value);
                    localStorage.setItem("Ud", JSON.stringify(e.target.value));
                  }}
                >
                  <option value={""}>{LNG.placeholder_select_client}</option>
                  {customers.map((customer, index) => {
                    return (
                      <option key={customer?.id} value={customer?.id}>
                        {customer?.name}
                      </option>
                    );
                  })}
                  {/* mapping users goes here */}
                </select>
              </div>

              <br />
              <h2>{LNG.subtitle_main_date}</h2>
              <br />
              <div className="order-simulator-date-add">
                <input
                  id="input-datepicker-simulator"
                  className="order-simulator-input"
                  value={
                    !isValidDate(suggestDateRemoveHours(deliveryDate, 3))
                      ? suggestDateRemoveHours(new Date(), 3)
                          .toISOString()
                          .slice(0, 16)
                      : suggestDateRemoveHours(deliveryDate, 3)
                          .toISOString()
                          .slice(0, 16)
                  }
                  type="datetime-local"
                  pattern={"[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"}
                  min={suggestDateRemoveHours(new Date(), 3)
                    .toISOString()
                    .slice(0, 16)}
                  step="1"
                  onChange={(e) => {
                    setDeliveryDate(new Date(e.target.value));
                  }}
                />
                <ButtonDefault
                  onClick={() => {
                    const testDuplicatedDate = arrayDates.find(
                      (dateItem) => dateItem === deliveryDate
                    );
                    // if there is no duplicated date, adds:
                    const now = new Date();
                    if (deliveryDate < now) {
                      errorToaster(LNG.toaster_error_on_delivery_dates);
                      return;
                    }
                    console.log("Test date", testDuplicatedDate);
                    if (!testDuplicatedDate) {
                      setArrayDates([...arrayDates, deliveryDate]);
                      if (DEBUG) console.log([...arrayDates, deliveryDate]);
                      let DDarrayFromStorage =
                        JSON.parse(localStorage.getItem("DDArray")) || [];
                      localStorage.setItem(
                        "DDArray",
                        JSON.stringify([...DDarrayFromStorage, deliveryDate])
                      );
                      return;
                    }
                    // if it's duplicated, errorToasters:
                    errorToaster(LNG.toaster_error_no_repeated_date);
                  }}
                  img={iconAdd}
                >
                  {LNG.placeholder_main_date}
                </ButtonDefault>
              </div>

              {arrayDates.length > 0 && (
                <>
                  <br />
                  <h1>{LNG.txt_selected_delivery_dates}</h1>{" "}
                </>
              )}
              {arrayDates.length > 0 &&
                arrayDates.map((item, index) => {
                  return (
                    <div key={item} className="order-simulator-date-add ">
                      <input
                        className="order-simulator-input"
                        value={
                          !isValidDate(suggestDateRemoveHours(item, 3))
                            ? suggestDateRemoveHours(new Date(), 3)
                                .toISOString()
                                .slice(0, 16)
                            : suggestDateRemoveHours(item, 3)
                                .toISOString()
                                .slice(0, 16)
                        }
                        type="datetime-local"
                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
                        step="1"
                        disabled
                      />
                      <div className="input-group-prepend">
                        <ButtonDefault
                          className="order-simulator-button-return"
                          onClick={() => handleDeleteDate(item)}
                          img={iconDelete}
                        >
                          {LNG.button_delete}
                        </ButtonDefault>
                      </div>
                    </div>
                  );
                })}

              <div className="switch-OrderSimulator tour-order-resume-dedicated ">
                <MobileSwitch
                  selected={decicatedSwitch}
                  toggleSelected={() => {
                    setDecicatedSwitch(!decicatedSwitch);
                    localStorage.setItem(
                      "dedicatedDelivery",
                      JSON.stringify(decicatedSwitch)
                    );
                    // handleOnSwitchChange()
                  }}
                />
                <h4>{LNG.txt_order_resume_delivery}</h4>
                <div>
                  <Tooltip
                    placement="bottom"
                    trigger={["hover", "click"]}
                    overlay={LNG.txt_order_resume_tooltip}
                  >
                    <img
                      className="container-OrderResume-inferior-tooltip"
                      alt="interrogacao"
                      src={interrogacao}
                    />
                  </Tooltip>
                </div>
              </div>

              <br />
              <h1>{LNG.subtitle_main_from}</h1>

              {/* TODO: create the states to control the location fields */}
              <AutoComplete
                onAutoComplete={handleAutoComplete}
                defaultValue={autoCompleteFrom || ""}
                onFormData={(payload) => handleGetFormData(payload, "From")}
                mapType="From"
              />

              <br />
              <h1>{LNG.subtitle_main_to}</h1>

              <AutoComplete
                onAutoComplete={handleAutoComplete}
                defaultValue={autoCompleteTo || ""}
                onFormData={(payload) => handleGetFormData(payload, "To")}
                mapType="To"
              />
              <div className="icon-caixa">
                <div>
                  <img
                    alt="caixa"
                    className="img-box-main"
                    src={
                      deliveryType === 2
                        ? mudancaResidencial
                        : deliveryType === 3
                        ? mudancaEmpresarial
                        : iconTransporte
                    }
                  />
                </div>
                <h2>
                  {deliveryType === 2
                    ? LNG.subtitle_main_residential
                    : deliveryType === 3
                    ? LNG.subtitle_main_company
                    : LNG.subtitle_main_items}
                </h2>
              </div>
              <div className="search-input-main-container">
                <div className="campo-busca-main-central tour-main-search-product">
                  <div className="delivery-type-1 ">
                    <div className="">
                      <InputAddProduct type="order-simulator" />
                    </div>
                  </div>
                </div>
              </div>

              {/* Container Map Section */}
              <div className="icon-caixa-dashboard-table">
                <div className="table-container-header-dashboard">
                  <h2>{LNG.subtitle_order_resume_box_2}</h2>
                </div>
                <div>
                  <div>
                    <table
                      style={{ overflow: "auto", maxHeight: "200px" }}
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellSpacing="0"
                    >
                      <thead>
                        <tr>
                          <th align="center">Code</th>
                          <th align="left">Quantity</th>
                          <th align="left">Product</th>
                          <th align="center">height (cm)</th>

                          <th align="center">Width (cm)</th>
                          <th align="center">Length (cm)</th>

                          <th align="center">Weight (g)</th>

                          <th align="center">Cubage Weight (kg)</th>

                          <th align="center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* calculating totals */}
                        {list.length ? (
                          list.map((productItem, index) => {
                            // Data
                            // Data
                            const {
                              id,
                              name,
                              quantity,
                              weight,
                              width,
                              lenght,
                              height,
                              deleted,
                            } = productItem;
                            // Sum

                            prodsTotalCount++;

                            if (!deleted) {
                              // Prod
                              prodWidth =
                                Number(quantity) *
                                (parseFloat(width) / 100).toFixed(2);
                              prodHeight =
                                Number(quantity) *
                                (parseFloat(height) / 100).toFixed(2);
                              prodLength =
                                Number(quantity) *
                                (parseFloat(lenght) / 100).toFixed(2);
                              prodWeight =
                                Number(quantity) *
                                (parseFloat(weight) / 100).toFixed(2);
                              prodCube = cubage(
                                prodLength,
                                prodWidth,
                                prodHeight
                              );

                              // Totals
                              prodsTotalQtde += Number(quantity);
                              prodsTotalWeight += parseFloat(prodWeight);
                              prodsTotalHeight += parseFloat(prodHeight);
                              prodsTotalWidth += parseFloat(prodWidth);
                              prodsTotalLength += parseFloat(prodLength);
                              prodsTotalCube += parseFloat(prodCube);
                            }

                            return (
                              <>
                                <tr key={id}>
                                  <td align="center">{id}</td>
                                  <td align="center">{quantity}</td>
                                  <td className="dashboard-table-timer-cell">
                                    {name}
                                  </td>
                                  <td align="center">{height}</td>
                                  <td align="center">{width}</td>
                                  <td align="center">{lenght}</td>
                                  <td align="center">{weight}</td>
                                  <td align="center">{prodCube}</td>
                                  <td align="center">
                                    <img
                                      alt={LNG.alt_delete}
                                      onClick={() =>
                                        handleShopcartRemove(index)
                                      }
                                      src={excluir}
                                      className="btShopCartRemove"
                                    />
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td align="center" valign="middle">
                              &nbsp;
                            </td>
                            <td align="center" valign="middle">
                              &nbsp;
                            </td>

                            <td colSpan="4" align="center">
                              {LNG.txt_no_registers}
                            </td>

                            <td align="center" valign="middle">
                              &nbsp;
                            </td>
                            <td align="center" valign="middle">
                              &nbsp;
                            </td>
                          </tr>
                        )}
                      </tbody>
                      <>
                        <tr className="orderProdsTotals">
                          <td align="center" valign="middle">
                            Totais
                          </td>
                          <td align="center" valign="middle">
                            {prodsTotalQtde}
                          </td>
                          <td align="center" valign="middle">
                            &nbsp;
                          </td>
                          <td align="center" valign="middle">
                            {(prodsTotalHeight * 100).toFixed(2)}
                          </td>
                          <td align="center" valign="middle">
                            {(prodsTotalWidth * 100).toFixed(2)}
                          </td>
                          <td align="center" valign="middle">
                            {(prodsTotalLength * 100).toFixed(2)}
                          </td>
                          <td align="center" valign="middle">
                            {(prodsTotalWeight * 100).toFixed(2)}
                          </td>
                          <td align="center" valign="middle">
                            {prodsTotalCube.toFixed(2)}
                          </td>
                          <td align="center" valign="middle">
                            &nbsp;
                          </td>
                        </tr>
                      </>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button-chat tour-order-resume-help-button">
            <p>{LNG.title_did_you_find_it}</p>
            <HelpButton />
          </div>
        </div>
        <div className="container-OrderResume-inferior">
          <div className="container">
            <div className="container-OrderResume">
              <div className="posicionamento-order-resume tour-order-resume-princing">
                <div className="container-itens-OrderResume">
                  {/* <div className="timer">
                <img alt="interrogacao" src={timer} />
                <h3>Tempo estimado total</h3>
                <input
                  placeholder="timer"
                  value="5hs 30mins"
                  readOnly
                ></input>
              </div> */}
                  <div className="total">
                    <h2>{LNG.txt_order_resume_total}</h2>
                    <span>
                      {orderEstimatePrice !== undefined ? (
                        orderEstimatedPrice
                      ) : (
                        <span className="totalLoading">...</span>
                      )}
                    </span>
                  </div>
                </div>
                <div className="tour-order-resume-foward-button">
                  <ButtonDefault
                    className="order-estimative-button"
                    img={iconBoxWhite}
                    onClick={() => handleEstimateOrderPrice()}
                  >
                    {LNG.button_estimate_order}
                  </ButtonDefault>
                  <ButtonDefault
                    img={caminhao}
                    onClick={() => handleConfirmOrdersModal()}
                  >
                    {LNG.button_place_order}
                  </ButtonDefault>
                  <div className="img-confirmar-OrderResume">
                    <img alt={LNG.alt_secure_payment} src={pgtoSeguro} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
