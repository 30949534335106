import React, { useEffect, useState, useMemo, useCallback } from "react";
import { GoogleMap, Marker, useJsApiLoader,  DirectionsService, DirectionsRenderer  } from "@react-google-maps/api";
import ENV from "../../env";
// import Local from "../Api/Local";

import "./style.css";

const containerStyle = {
  width: "100vw",
  height: "100vh",
};

// Initial location
const initialLocation = {
  lat: -23.5489,
  lng: -46.6388,
};

function MapContainer(props) {

  // Initial
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: ENV().GOOGLE_MAPS_APIKEY,
  });


  // Helper
  // String to Google Location
  function strToLocation(loc){

    // Set to object
    try{
      // Try parse - if not ignore
      if(typeof loc === 'string') loc = JSON.parse(loc);
    } catch(e){
      // Log error
      console.warn("Could not parse location", "Type:", typeof loc, "Data:", loc);
    }

    if( !loc?.lat || !loc?.lng ) return null;

    // Lat Long
    const lat = parseFloat(loc.lat);
    const lng = parseFloat(loc.lng);

    if( !isNaN(lat) && !isNaN(lng) )  {
      const ret = { lat, lng };
      return ret;
    } else                              
      return false;
  }

  // Initial location
  const location = strToLocation(props.location) || initialLocation;
  console.log("Map location :", location, props);

  // Direction
  const [origin, setOrigin]           =   useState(null);
  const [destination, setDestination] =   useState(null);
  const [response, setResponse]       =   useState(null);

  // Direction service options
  const directionsServiceOptions = useMemo(() => {
    return {
      origin,
      destination,
      travelMode: "DRIVING",
    };
  }, [origin, destination]);

  // Direction callback
  const directionsCallback = useCallback((res) => {
    if (res !== null && res.status === "OK") {
      setResponse(res);
    } else {
      console.log("directionsCallback error", res);
    }
  }, []);  

  // Direction render options
  const directionsRendererOptions = useMemo(() => {
    return {
      directions: response,
    };
  }, [response]);    


  // Set the initial center of the map
  // async function setLocal(position) {
  //   try {

  //     // Geo
  //     const lat = position.coords.latitude;
  //     const lon = position.coords.longitude;
  //     if (!lat || !lon) return false;
      
  //     // Set local 
  //     setLocation( strToLocation({ lat: lat, lng: lon }) );

  //     /*
  //     // Disable fill address

  //     // Get local
  //     const arrLocalGet = await new Local().exec("GET",`address/${lat}/${lon}`);

  //     // Check if local is valid
  //     if ( arrLocalGet?.success ) {
  //       // Set values
  //       const arrLocal = arrLocalGet.result;
  //       if (!arrLocal) return false;
  //       // Set location
  //       const neighborhood = arrLocal?.neighborhood || "";
  //       const street =
  //         (arrLocal?.street || "") +
  //         (arrLocal?.number ? " " + arrLocal?.number : "");
  //       const city = arrLocal?.city || "";
  //       const state = arrLocal?.state || "";
  //       const country = arrLocal?.country || "";
  //       const CEP = arrLocal?.postal_code.replace("-", "") || "";

  //       // Complete local string
  //       const completeAddress = `${street} - ${neighborhood}, ${city} - ${state}, ${CEP}, ${country}`;
  //       // console.log("completeAddress", completeAddress); 
        
  //       // Update location
  //       // handleLocationChange(location, completeAddress);
  //     }    
  //     */

  //   } catch (e) {
  //     // Error finding local
  //     console.log("Error finding local:", e);
  //   }
  // }


  useEffect(() => {

    // User location
    // Check location status ( granted or not )
    // const locationStatus = "geolocation" in navigator;
    // if ( locationStatus ) navigator.geolocation.getCurrentPosition(setLocal);

    // Direction
    if( props.origin && props.destination ){

      // Convert lat lng from string to float(double)
      const originFormatted         =   strToLocation(props.origin);
      const destinationFormatted    =   strToLocation(props.destination);
    
      // Save to state
      setOrigin(originFormatted);
      setDestination(destinationFormatted);
  
      console.log("##############################################");
      console.log("####      ROTA - COLETA ---> ENTREGA      ####");
      console.log("##############################################");
      console.log(" Coleta :", originFormatted);
      console.log(" Entrega :", destinationFormatted);
      console.log("##############################################");
    }    
  },[props.destination, props.origin])

  return isLoaded ? (
    <div className={props?.type === "dashboard" ? 'container-map-dashboard': 'container-map'}>
      <GoogleMap
        mapContainerStyle={props.containerStyle || containerStyle }
        center={location || initialLocation }
        zoom={15}
      >
        { location && (<Marker position={location} />) }
        {origin && destination && (
            <DirectionsService
              options={directionsServiceOptions}
              callback={directionsCallback}
            />
        )}
        {response && directionsRendererOptions && (
          <DirectionsRenderer options={directionsRendererOptions} />
        )}        
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}

export default (MapContainer);
