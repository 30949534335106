// Components
import { useContext, useRef } from "react";
import Auth from "../../Api/Auth";
import { Link } from "react-router-dom";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { validateCPF } from "validations-br";
// Data analysis
import { MP } from "../../components/MixPanel";

// Local components
import { useLoading } from "../../components/Loading";
import links from "../../config/Links";

import InputForm from "../../components/InputForm";
import InputMask from "../../components/InputMask";
import ButtonReturn from "../../components/ButtonReturn";
import ButtonGoFoward from "../../components/ButtonGoFoward";

// CSS
import "./style.css";

// LNG
import { getLanguageByTimezone, getLocaleLanguage } from "../../lang/utils";

// Images
import pageImage from "../../assets/lostPass/senha-recuperacao.svg";
import { errorToaster, successToaster, ToastContainer } from "../../components/Toasters";
import { Context } from "../../Context/ContextGlobal";

export default function LostPass(props) {

  const { business } = useContext(Context);

  // Context applied to lang setup
  let LNG = getLocaleLanguage[getLanguageByTimezone(business?.timezone)]

  // Load control
  const formRef = useRef(null);

  // Loading
  const { setLoading } = useLoading();

  async function recovery(data) {
    
    // Loader
    setLoading(true);
    
    // Auth API
    const auth = new Auth();

    // Exec request
    await auth.lostPass(data)
        .then((ret) => {
          if ( ret && ret.success ) {
            // Success
            // alert.success(ret.message_br, { title: "Recuperação de senha", closeCopy: "Ok" });
            successToaster(ret.message_br);

            // MP
            MP("Lost Pass", { "success":"true", "email": data.email, "cpf": data.cpf });
                        
            // Redir 
            props.history("/", { replace: true, result : ret });
          } else {
              // MP
              MP("Lost Pass", { "success":"false", "email": data.email, "cpf": data.cpf });
                            
              // E-mail and  CPF not found
              // alert.info(ret.message_br, { title: "Recuperação de senha", closeCopy: "Ok" });
              errorToaster(ret.message_br);
          }
          setLoading(false)
        })
        .catch((error) => {

          // MP
          MP("Lost Pass", { "error":"true", "email": data.email, "cpf": data.cpf });
                    
          if (error.response) {
            // Erro
            // console.log(error.response.data.message_br); // => the response payload 
            // alert.info(error.response.data.message_br, { title: "Recuperação de senha", closeCopy: "Ok" });
            errorToaster(error.response.data.message_br);          
          } else {
            // Undefined error
            errorToaster(LNG.toaster_error_undefined);  
          }
        });

        setLoading(false)
  }
  async function handleSubmit(data, { reset }) {
    // handleFinishTour()
    setLoading(true)
    
    try {
      // Remove all previous errors
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .email(LNG.yup_error_email_message)
          .required(LNG.yup_error_email_required),
        cpf: Yup.string()
          .test("is-cpf", LNG.yup_error_cpf_message, (value) => validateCPF(value))
          .required(LNG.yup_error_cpf_required),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      
      // Validation passed
      await recovery({
        email: data.email,
        cpf: data.cpf,
      });

      // Loading off
      setLoading(false);

    } catch (err) {
      /* const validationErrors = {}; */
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      // Loader off
      setLoading(false);
    }
  }

  return (
    <div className="container-principal-LostPass">
      <div className="container-LostPass">
        <div className="container-LostPass">
          <ToastContainer />
         {/* <ButtonReturn onClick={handleFinishTour} to={links.home}/> */}
         <ButtonReturn to={links.home}/>
          <div className="container-form">            
            <div className="column-LostPass">
              <Form id="myform" ref={formRef} onSubmit={handleSubmit}>
                <Link to="/" className="bt-top-back"></Link>
                <h1>{LNG.title_lost_pass}</h1>
                <p className="container-description">
                  {LNG.subtitle_lost_pass}
                </p>
                <div className="inputField">
                  <InputForm
                    name="email"
                    type="email"
                    placeholder={LNG.placeholder_email}
                  />
                </div>
                <div  className="inputField tour-lostpass-cpf">
                  <InputMask
                    name="cpf"
                    mask="999.999.999-99"
                    maskChar=" "
                    type="cpf"
                    placeholder={LNG.placeholder_cpf}
                  />
                </div>
              </Form>
            </div>
            <div className="column detail-LostPass">
                <img src={pageImage} alt="Recuperação de senha" />
              </div>
          </div>
        </div>
      </div>
      <div className="container-inferior-LostPass ">
          <div className="container-AccountScreen tour-lostpass-finish">
          <form>
            <ButtonGoFoward className={"container-AccountScreen-button"} Form="myform" type="submit">
              {LNG.button_lost_pass_send}
            </ButtonGoFoward>
          </form>
        </div>
      </div>
    </div>
  );
}