import api from "../Api/Api";

class ApiModel {
  apiURL = "";
  api = null;

  constructor() {
    this.api = api;
  }

  list(params) {
    return this.api.get(this.apiURL, params).then(this.getData);
  }

  get(endpoint, params) {
    return this.api.get(this.apiURL + "/" + endpoint, params).then(this.getData);
  }

  post(obj, params) {
    return this.api.post(this.apiURL, obj).then(this.getData);
  }

  put(endpoint, params) {
    return this.api.put(this.apiURL + "/" + endpoint, params).then(this.getData);
  }
  
  getData(ret) {
    return ret.data;
  }
  
  delete(endpoint, params) {
    return this.api.delete(this.apiURL + "/" + endpoint, params).then(this.getData);
  }
  // user uptate
  patch(endpoint, params) {
    return this.api.patch(this.apiURL + "/" + endpoint, params).then(this.getData)
  }

  // Generic request 
  exec(method, endpoint, params) {
    // Add options
    const options = {
      headers: {
        'Content-Type': 'application/json'
      },
      method: method.toString().toUpperCase(),
      data: params, 
      params
    };
    // Execute request by method
    // this.api.request(options).then(this.getData);
    // Return
    return this.api.post(this.apiURL + "/" + endpoint, params)
    .then(this.getData)
    .catch(this.onError);
  }  
}
export default ApiModel;
