export const map = [
  {
    id: 'tour-description-map-page',
    title: 'Bem-vindos a página de mapas',
    scrollTo: true,
    canClickTarget: false,
    text: [
      `
      Essa é a etapa de Busca de Endereço,
       onde será possivel inserir todas as informações de endereço.
      `
    ],

    attachTo: { element: '.tour-description-map-page', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ]
  },
  {
    id: 'tour-map-search-bar',
    title: 'Entenda a barra de busca de endereços',
    text: [
      `
        Neste campo é possivel encontrar o seu endereço completo,
         caso alguma informação esteja em branco, é possivel complementar usando os campos abaixo.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.tour-map-search-bar', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },
  {
    id: 'map-page-number-field',
    title: 'Inserindo a rua',
    text: [
      `
       Campo destinado ao nome da rua.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.map-page-street-field', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },
  {
    id: 'map-page-number-field',
    title: 'Inserindo o número da rua',
    text: [
      `
       Campo destinado ao número da rua.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.map-page-number-field', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },

  {
    id: 'map-page-complement-field',
    title: 'Complemento',
    text: [
      `
       Campo destinado ao complemento do endereço. Este é um campo opcional.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.map-page-complement-field', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },
  {
    id: 'map-page-floor-field',
    title: 'Informação sobre o andar',
    text: [
      `
       Campo destinado ao número do andar do endereço. Este é um campo opcional.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.map-page-floor-field', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },
  {
    id: 'map-page-district-field',
    title: 'Bairro',
    text: [
      `
       Campo destinado ao bairro.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.map-page-district-field', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },
  {
    id: 'map-page-city-field',
    title: 'Cidade',
    text: [
      `
       Campo destinado a cidade.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.map-page-city-field', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },
  {
    id: 'map-page-state-field',
    title: 'Estado',
    text: [
      `
       Campo destinado ao estado.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.map-page-state-field', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },

  {
    id: 'map-page-country-field',
    title: 'País',
    text: [
      `
       Campo destinado ao país.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.map-page-country-field', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'cancel',
        classes: 'shepherd-button-secondary',
        text: 'Sair'
      },
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'next',
        text: 'Próximo'
      }
    ],
  },

  {
    id: 'map-page-finish',
    title: 'Confirmando os dados',
    text: [
      `
       Ao clicar no botão 'Selecionar' validaremos todos os campos de endereço inseridos, caso haja algum problema,
        notificamos em tempo real. Possibilitando a correção diretamente nesta sessão.
      `
    ],
    canClickTarget: false,
    scrollTo: true,
    attachTo: { element: '.map-page-finish', on: 'bottom' },
    // classes: 'shepherd shepherd-welcome',
    buttons: [
      {
        type: 'back',
        classes: 'shepherd-button-secondary',
        text: 'Voltar'
      },
      {
        type: 'cancel',
        text: 'Entendi'
      }
    ],
  },
]