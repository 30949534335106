import ENV from "../../env";
import { useState, useEffect } from "react";
import axios from "axios";
// Local components

function useBusiness() {
  
  const [business, setBusiness] = useState({});
  const DEBUG = false;
  const BASE_URL = ENV().ECARRETO_API_URL || "https://api.ecarreto.com.br"

  useEffect(() => {
    // console.log(dataSt)
    // validate the company URL
    const options = {
      method: 'GET',
      url: BASE_URL + '/business/',
    };
    axios.request(options).then(function (response) {
      if(DEBUG) console.log(response?.data);
      if(response?.data?.active) {
        // Set company data, if
        setBusiness({
          ...response.data,
          logo_url: response?.data?.logo_url + "?nocache=" + (new Date).getTime().toString()
        }
        )
      }else {
        return {business: false, active: false, error: true}
      }

    }).catch(function (error) {
      // console.error('COMPANY DATA', error)
      setBusiness(error?.response?.data)
      
    });

  },[])

  return { business };
}
export default useBusiness;
