import PropTypes from "prop-types";
import React from "react";

import "./style.css";

function MobileSwitch({selected, toggleSelected }) {
    return (
      <div
        className={`toggle-container-mobile ${ selected ? "off" : "on"}`}
        onClick={toggleSelected}
      >
        <div className="dot-mobile"></div>
      </div>
    );
}

MobileSwitch.propTypes = {
  selected: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired,
};
export default MobileSwitch;
